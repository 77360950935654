import axios from 'axios';
import {_HostnamePre} from '../../Const'

export const AddSkillToServer = (guid) => {
  return function (dispatch) {
    let url = _HostnamePre + 'index.php?controller=Resume&method=AddSkill';
    const formData = new FormData();
    formData.append('guid', guid);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    }
    axios.post(url, formData, config)
    .then(function ({ data }) {
      dispatch({
        type: 'LOAD_RESUME',
        data: data
      });
    })
  };
}

export function updateSkill(newList) {
  return (dispatch) => {
    dispatch({ 
      type: 'UPDATE_SKILL',
      newList: newList
    });
};
}

export function updateSkillOnServer(guid, id, skill, level) {
  return function (dispatch) {
    const formData = new FormData();
    formData.append('guid', guid);
    formData.append('id', id);
    formData.append('skill', skill);
    formData.append('level', level);
    axios.post(_HostnamePre + "index.php?controller=Resume&method=UpdateSkill", formData, {
      headers: {
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    })
  };
}

import React, { Component } from 'react';
import { connect } from 'react-redux'
import Helmet from 'react-helmet';
import { Route, HashRouter, Switch, Redirect } from 'react-router-dom'
import Editor from './components/Editor'
import Main from './components/Main'
import Login from './components/Login'
import Modal from './components/Modal'
import "react-sliding-pane/dist/react-sliding-pane.css";
import { isLoggedIn } from './actions/auth/authActions';
import { isLocalhost } from './Const';
import CoverLettersEditor from './components/CoverLettersEditor';

class App extends Component {
  constructor(props) {
    const localStorageIsDarkMode = localStorage.getItem("isDarkMode");
    super(props);
    this.state = {
      redirect: null,
      bodyBackground: localStorageIsDarkMode === "true" ? "background-color : #303030" : this.props.auth.isLoggedIn ? "background-color : #f4f4f4" : "background-color : #fff",
      localStorage: localStorageIsDarkMode
    }
  }
  componentWillMount() {
    if (window.location.href.indexOf("token=") > -1) {
      var token = window.location.href.split('token=')[1];
      if (token.indexOf("&") > -1) {
        token = token.split('&')[0];
      }
      localStorage.setItem('token', token)

      this.props.isLoggedIn(token);
    } else {
      this.props.isLoggedIn();
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.isDarkMode !== prevProps.isDarkMode) {
      const localStorageIsDarkMode = localStorage.getItem("isDarkMode");
      this.setState({
        bodyBackground: localStorageIsDarkMode === "true" ? "background-color : #303030" : this.props.auth.isLoggedIn ? "background-color : #f4f4f4" : "background-color : #fff",
      });
    }
  }
  render() {
    let template = null;
    if (window.location.href.indexOf("template=") > -1) {
      template = window.location.href.split('template=')[1];
      if (template.indexOf("&") > -1) {
        template = template.split('&')[0];
      }
    }
    let type = null;
    if (window.location.href.indexOf("type=") > -1) {
      type = window.location.href.split('type=')[1];
      if (type.indexOf("&") > -1) {
        type = type.split('&')[0];
      }
    }
    const { auth, isDarkMode } = this.props;
    //const bodyBackground = (isDarkMode) ? "background-color : #303030" : "background-color : #fff";
    return (
      this.props.didMount &&
      <HashRouter>
        <div className={(isDarkMode) ? "App dark_mode" : "App"}>
          <Helmet bodyAttributes={{ style: this.state.bodyBackground, class: (isDarkMode) ? "dark_mode" : "" }} />
          <Modal />
          <Switch>
            <Route exact path="/">
              {auth.isLoggedIn ? <Redirect to={template !== null ? type === 'coverLetter' ? '/main?newCoverLetter=' + template :   '/main?newResume=' + template : '/main/resumes'} /> : isLocalhost ? <Login /> : window.location.replace("https://resumejournal.com")}
            </Route>
            <Route exact path='/main' component={Main} />
            <Route exact path='/main/resumes' component={Main} />
            <Route exact path='/main/cover-letters' component={Main} />
            <Route exact path='/main/resumes/:resume_id/editor' component={Editor} />
            <Route exact path='/main/cover-letters/:resume_id/editor' component={CoverLettersEditor} />
          </Switch>
        </div>
      </HashRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.viewModel.auth,
    isDarkMode: state.viewModel.auth.isDarkMode,
    didMount: state.didMount
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    isLoggedIn: (token, callback) => dispatch(isLoggedIn(token, callback)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
import axios from 'axios';
import {_HostnamePre} from '../../Const'

export const AddJobToServer = (guid) => {
  return function (dispatch) {
    let url = _HostnamePre + 'index.php?controller=Resume&method=AddJob';
    const formData = new FormData();
    formData.append('guid', guid);
    formData.append('jobTitle', "");
    formData.append('employer', "");
    formData.append('startDate', "");
    formData.append('endDate', "");
    formData.append('city', "");
    formData.append('description', "");
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    }
    axios.post(url, formData, config)
    .then(function ({ data }) {
      dispatch({
        type: 'LOAD_RESUME',
        data: data
      });
    })
  };
}

export function updateJob(newList) {
  return (dispatch) => {
    dispatch({ 
      type: 'UPDATE_JOB',
      newList: newList
    });
};
}

export function updateJobOnServer(guid, id,  jobTitle, employer, startDate, endDate, stringStartDate, stringEndDate, city, description, sort) {
  return function (dispatch) {
    const formData = new FormData();
    formData.append('guid', guid);
    formData.append('id', id);
    formData.append('jobTitle', jobTitle);
    formData.append('employer', employer);
    formData.append('startDate', startDate);
    formData.append('endDate', endDate);
    formData.append('stringStartDate', stringStartDate);
    formData.append('stringEndDate', stringEndDate);   
    formData.append('city', city);
    formData.append('description', description);
    formData.append('sort', sort);
    axios.post(_HostnamePre + "index.php?controller=Resume&method=UpdateJob", formData, {
      headers: {
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    })
  };
}

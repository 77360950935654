import React, { Component } from 'react'
import { connect } from 'react-redux'
import { showSpinner, getDateObject } from '../actions/rootActions'
import { updateInternship, updateInternshipOnServer } from '../actions/resume/internshipActions'
import { debounce } from "lodash";
import 'react-dropzone-uploader/dist/styles.css'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { Handle } from './ListWrapper/Handle'

class Internship extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.item.id,
            jobTitle: props.item.jobTitle,
            employer: this.props.item.employer,
            startDate: parseInt(props.item.startDate, 0),
            endDate: parseInt(props.item.endDate, 0),
            city: props.item.city,
            description: props.item.description,
            sort: props.item.sort,
            isDataLoaded: false
        }
    }
    componentDidUpdate(prevProps) {
        if (!this.state.isDataLoaded) {
            if (this.props.item !== prevProps.item) {
                this.setState({
                    id: this.props.item.id,
                    jobTitle: this.props.item.jobTitle,
                    employer: this.props.item.employer,
                    startDate: parseInt(this.props.item.startDate, 0),
                    endDate: parseInt(this.props.item.endDate, 0),
                    city: this.props.item.city,
                    description: this.props.item.description,
                    sort: this.props.item.sort,
                    isDataLoaded: true
                });
            }
        }
    }
    handleChangeStartDate = date => {
        const timestamp =Date.parse(date);
        this.setState({
            startDate: timestamp
        });
        const { id, jobTitle, employer, endDate, city, description, sort } = this.state;
        this.updateInternship(id, jobTitle, employer, timestamp, endDate, city, description, sort);
    };
    handleChangeEndDate = date => {
        const timestamp =Date.parse(date);
        this.setState({
            endDate: timestamp
        });
        const { id, jobTitle, employer, startDate, city, description, sort } = this.state;
        this.updateInternship(id, jobTitle, employer, startDate, timestamp, city, description, sort);
    };
    handleChange = (e) => {
        const { index } = this.props;
        switch (e.target.id) {
            case "internship_jobtitle_" + index:
                this.setState({
                    jobTitle: e.target.value
                });
                var { id, employer, startDate, endDate, city, description, sort } = this.state;
                this.updateInternship(id, e.target.value, employer, startDate, endDate, city, description, sort);
                break;
            case "internship_employer_" + index:
                this.setState({
                    employer: e.target.value
                });
                var { id, jobTitle, startDate, endDate, city, description, sort } = this.state;
                this.updateInternship(id, jobTitle, e.target.value, startDate, endDate, city, description, sort);
                break;
            case "internship_city_" + index:
                this.setState({
                    city: e.target.value
                });
                var { id, jobTitle, employer, startDate, endDate, description, sort } = this.state;
                this.updateInternship(id, jobTitle, employer, startDate, endDate, e.target.value, description, sort);
                break;
        }
    }
    handleChangeDescription = (description) => {
        this.setState({
            description: description
        });
        const { id, jobTitle, employer, startDate, endDate, city, sort } = this.state;
        const { index } = this.props;
        this.updateInternship(id, jobTitle, employer, startDate, endDate, city, description, sort);
    }

    updateInternship = (id, jobTitle, employer, startDate, endDate, city, description, sort) => {
        const elementsIndex = this.props.currentResume.internships.findIndex(job => job.id === id);
        let newList = [...this.props.currentResume.internships];
        newList[elementsIndex] = {id, jobTitle, employer, startDate, endDate, city, description, sort, type: "Internship"};

        this.props.updateInternship(newList);

        this.raiseUpdatepersonalDetailsWhenUserStoppedTyping();
    }

    raiseUpdatepersonalDetailsWhenUserStoppedTyping = debounce(() => {
        const { id, jobTitle, employer, startDate, endDate, city, description, sort } = this.state;

        var dateObject = this.props.getDateObject(startDate, endDate);
        var stringStartDate = (dateObject.startMonthName && dateObject.startYear) ? (dateObject.startMonthName + " " + dateObject.startYear) : "";
        var stringEndDate = (dateObject.endMonthName && dateObject.endYear) ? (dateObject.endMonthName + " " + dateObject.endYear) : this.props.currentResume.translation['present'];
        this.props.updateInternshipOnServer(this.props.currentResume.guid, id, jobTitle, employer, startDate, endDate, stringStartDate, stringEndDate, city, description, sort);
        if (!this.state.isShowSpinner) {
            this.props.showSpinner(true);
        }
    }, 1500);
    modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'], [{ 'list': 'ordered' }, { 'list': 'bullet' }], ['link'], ['clean']
        ],
        clipboard: {
            matchVisual: false
        }
    }

    formats = [
        'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'link'
    ]
    render() {
        const { jobTitle, employer, startDate, endDate, city, description } = this.state;
        const { currentResume, isDarkMode, index, item, parentIndex, isDisabled, shouldUseDragHandle, bodyTabIndex, parentName } = this.props;
        const monthNames = currentResume.translation['longMonthNames'];
        var startDate0 = new Date(startDate);
        const startMonthName = monthNames[startDate0.getMonth()];
        const startYear = startDate0.getFullYear();
        var endDate0 = new Date(endDate);
        const endMonthName = monthNames[endDate0.getMonth()];
        const endYear = endDate0.getFullYear();

        
        const itemStyle = {
            textAlign: this.props.currentResume.language === 'ar' ? 'right' : 'unset',
            direction: this.props.currentResume.language === 'ar' ? 'rtl' : 'unset'
        }
        return (
            <div className="item" style={itemStyle}>
                <div
                    className="stylizedItem"
                    tabIndex={bodyTabIndex}
                    data-index={index}
                    onClick={() => this.props.innerWidth > 1019 && this.props.ToggleItemDisplay("inner", index + "" + parentIndex)}
                    style={{cursor: this.props.innerWidth > 1019 ? 'pointer' : 'default'}}
                >
                    {shouldUseDragHandle &&
                        <React.Fragment>
                            <div className="wrapper">
                                <Handle tabIndex={bodyTabIndex} />
                                <div className="middle">
                                    <div className="item-title-edit">{jobTitle || currentResume.translation['not_specified']}{(employer) ? ", " + employer : ""}{(city) ? ", " + city : ""}</div>
                                    <div className="item-date-edit">{startMonthName && startYear && (startMonthName + " " + startYear + " - ")} {endDate && (endMonthName + " " + endYear) || currentResume.translation['present']}</div>
                                </div>
                            </div>
                            <div className="flex-no-wrape">
                                <button className="trash-icon delete margin-r10" onClick={() => this.props.showConfirmDeleteMessage(parentName, item.id)}>
                                    <div style={{ display: "inline-block" }}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </div>
                                </button>
                                <button className="toggle margin-r10" onClick={() => this.props.innerWidth <= 1019 && this.props.ToggleItemDisplay("inner", index + "" + parentIndex)}>
                                    <div id={"AngleDown" + index + "" + parentIndex} className="ItemAngleDown" style={{ display: "inline-block" }}>
                                        <FontAwesomeIcon icon={faAngleDown} />
                                    </div>
                                    <div id={"AngleUp" + index + parentIndex} className="ItemAngleUp" style={{ display: "none" }}>
                                        <FontAwesomeIcon icon={faAngleUp} />
                                    </div>
                                </button>
                            </div>
                        </React.Fragment>
                    }
                </div>
                <div className="item-inner" id={"inner" + index + parentIndex} style={{ display: "none" }}>
                    <div className="personal-details-field">
                        <div>
                            <div>
                                <div>
                                    <label className="personal-details-title">
                                        <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['job_title']}</div>
                                    </label>
                                    <div>
                                        <input type="text" autoComplete="off" value={jobTitle} id={"internship_jobtitle_" + index} onChange={this.handleChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="personal-details-field">
                        <div>
                            <div>
                                <div>
                                    <label className="personal-details-title">
                                        <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['employer']}</div>
                                    </label>
                                    <div>
                                        <input type="text" autoComplete="off" value={employer} id={"internship_employer_" + index} onChange={this.handleChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="personal-details-field">
                        <div>
                            <div className="start-end-date">

                                <div className="date-item startDate">
                                    <label className="personal-details-title">
                                        <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['start_date']}</div>
                                    </label>
                                    <div>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={date => this.handleChangeStartDate(date)}
                                            selectsStart
                                            startDate={startDate}
                                            endDate={endDate}
                                            dateFormat="MM/yyyy"
                                            showMonthYearPicker
                                        />


                                    </div>
                                </div>

                                <div className="date-item endDate">
                                    <label className="personal-details-title">
                                        <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['end_date']}</div>
                                    </label>
                                    <div>
                                        <DatePicker
                                            selected={endDate}
                                            onChange={date => this.handleChangeEndDate(date)}
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}
                                            dateFormat="MM/yyyy"
                                            showMonthYearPicker
                                            placeholderText={currentResume.translation['present']}
                                            clearButtonTitle={currentResume.translation['present']}
                                            isClearable={endDate ? true : false}
                                        />

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="personal-details-field">
                        <div>
                            <div>
                                <div>
                                    <label className="personal-details-title">
                                        <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['city']}</div>
                                    </label>
                                    <div>
                                        <input type="text" autoComplete="off" value={city} id={"internship_city_" + index} onChange={this.handleChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="personal-details-field">
                        <label className="personal-details-title">
                            <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['description']}</div>
                        </label>
                    </div>
                    <ReactQuill value={description}
                        theme="snow"
                        modules={this.modules}
                        formats={this.formats}
                        onChange={this.handleChangeDescription} />
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentResume: state.viewModel.currentResume,
        isDarkMode: state.viewModel.auth.isDarkMode,
        innerWidth: state.innerWidth
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateInternship: (newList) =>
            dispatch(updateInternship(newList)),
            updateInternshipOnServer: (guid, id,  jobTitle, employer, startDate, endDate, stringStartDate, stringEndDate, city, description) =>
            dispatch(updateInternshipOnServer(guid, id,  jobTitle, employer, startDate, endDate, stringStartDate, stringEndDate, city, description)),
        showSpinner: (isShowSpinner) =>
            dispatch(showSpinner(isShowSpinner)),
        getDateObject: (startDate, endDate) => getDateObject(startDate, endDate)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Internship)
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { onLoadSuccess, updateProfilePicture, uploadCroppedProfilePicture, uploadCroppedProfilePictureOnServer } from '../actions/resume/personalDetailsActions'
import { hideModal } from '../actions/rootActions'
import { showSpinner } from '../actions/rootActions'
import AvatarEditor from 'react-avatar-editor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faUpload, faTimesCircle, faCrop, faArrowRightRotate, faArrowLeftRotate } from '@fortawesome/free-solid-svg-icons'
import { DeleteProfilePicture, DeleteProfilePictureOnServer } from '../actions/resume/personalDetailsActions'
import { setShowLoading } from '../actions/rootActions'
import Resizer from 'react-image-file-resizer';
import { _HostnamePre } from '../Const'

class ProfilePictureEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profilePicture: props.currentResume.personalDetails.image,
            isShowSpinner: props.preview.isShowSpinner,
            editor: null,
            scale: 1,
            rotate: 0,
            positionX: 0.5,
            positionY: 0.5,
            showDeleteMessage: false,
            deleteLoading: false,
            tempImageSrc: props.tempImageSrc,
            isSelectFile: false,
            pictureHasChanged: false,
            scaleHasChanged: false,
            rotateHasChanged: false,
            positionHasChanged: false,
            position_x: 0,
            position_y: 0
        }
    }
    componentWillMount() {
        const croppedProfilePicture = this.props.currentResume.personalDetails.croppedProfilePicture;
        croppedProfilePicture &&
        this.setState({
            scale: croppedProfilePicture.scale,
            rotate: croppedProfilePicture.rotate,
            positionX: croppedProfilePicture.positionX,
            positionY: croppedProfilePicture.positionY,
            position_x: croppedProfilePicture.position_x,
            position_y: croppedProfilePicture.position_y,
            tempImageSrc: this.props.tempImageSrc
        });
        if(this.props.pictureHasChanged === true){
            this.setState({
                scale: 1,
                rotate: 0,
                positionX: 0.5,
                positionY: 0.5
            });
        }
    }
    componentDidUpdate(prevProps) {
        if (!this.props.isDataLoaded) {
            if (this.props.currentResume.personalDetails !== prevProps.currentResume.personalDetails) {
                this.setState({
                    profilePicture: this.props.currentResume.personalDetails.image,
                    deleteLoading: false,
                    showDeleteMessage: false,
                    scale: this.props.currentResume.personalDetails.croppedProfilePicture.scale,
                    rotate: this.props.currentResume.personalDetails.croppedProfilePicture.rotate,
                    positionX: this.props.currentResume.personalDetails.croppedProfilePicture.positionX,
                    positionY: this.props.currentResume.personalDetails.croppedProfilePicture.positionY,
                    position_x: this.props.currentResume.personalDetails.croppedProfilePicture.position_x,
                    position_y: this.props.currentResume.personalDetails.croppedProfilePicture.position_y,
                });
            }
        }
        if (this.props.tempImageSrc !== prevProps.tempImageSrc) {
            this.setState({
                tempImageSrc: this.props.tempImageSrc
            });
        }
        if (this.props.isSelectFile !== prevProps.isSelectFile) {
            this.setState({
                isSelectFile: this.props.isSelectFile
            });
        }        
    }
    onPositionChange = (position) => {
        this.setState({
            positionX: position.x,
            positionY: position.y,
            position_x: position.x,
            position_y: position.y
        });
        const croppedProfilePicture = this.props.currentResume.personalDetails.croppedProfilePicture;
        if(croppedProfilePicture && Math.abs(parseFloat(position.x) === parseFloat(this.props.currentResume.personalDetails.croppedProfilePicture.position_x)) &&
        croppedProfilePicture && Math.abs(parseFloat(position.y) === parseFloat(this.props.currentResume.personalDetails.croppedProfilePicture.position_y))){
            this.setState({ 
                positionHasChanged: false
            });
        }else{
            this.setState({ 
                positionHasChanged:  true
            });
        }
    }
    showDeleteMessage = () => {
        this.setState({
            showDeleteMessage: true
        });
    }
    hideDeleteMessage = () => {
        this.setState({
            showDeleteMessage: false
        });
    }
    deletePicture = () => {
        /*this.setState({
            deleteLoading: true
        });*/
        this.props.DeleteProfilePicture(this.props.currentResume.guid);        
        this.props.DeleteProfilePictureOnServer(this.props.currentResume.guid);
    }


    setEditorRef = editor => this.setState({ editor });

    fileChangedHandler = (event) => {
        var fileInput = false
        if(event.target.files[0]) {
            fileInput = true
        }
        if(fileInput) {
            Resizer.imageFileResizer(
                event.target.files[0],
                1000,
                1000,
                'JPEG',
                80,
                0,
                uri => {
                    this.setState({
                        tempImageSrc: uri,
                        isSelectFile: true,
                        pictureHasChanged: true,
                        scale: 1,
                        rotate: 0,
                        positionX: 0.5,
                        positionY: 0.5,
                    });
                },
                'base64'
            );
        }
    }

    onScaleChange = (scaleChangeEvent) => {
        const scale = parseFloat(scaleChangeEvent.target.value);
        //console.log(this.props.currentResume.personalDetails)
        if(scale === this.props.currentResume.personalDetails.croppedProfilePicture.scale){
            this.setState({ 
                scale: scale,
                scaleHasChanged: false
            });
        }else{
            this.setState({ 
                scale: scale,
                scaleHasChanged:  true
            });
        }
    };
    setRotateChange(rotate){
        let rotateEqual = false;
        //console.log(this.props.currentResume.personalDetails)
        if(rotate === this.props.currentResume.personalDetails.croppedProfilePicture.rotate+360 || 
            rotate === this.props.currentResume.personalDetails.croppedProfilePicture.rotate-360 ||
            rotate === this.props.currentResume.personalDetails.croppedProfilePicture.rotate){
                rotateEqual= true;
        }
        if(rotateEqual){
            this.setState({ 
                rotate: rotate,
                rotateHasChanged: false
            });
        }else{
            this.setState({ 
                rotate: rotate,
                rotateHasChanged:  true
            });
        }    
    }
    onRotateChange = (e) => {
        const rotate = parseInt(e.target.value, 0);
        this.setRotateChange(rotate);
    };
    rotateRight = (e) => {
        let newRotate = parseInt(this.state.rotate, 0) + 90;
        if (newRotate > 360) {
            newRotate = newRotate - 360;
        }
        this.setRotateChange(newRotate);
    };
    rotateLeft = (e) => {
        let newRotate = parseInt(this.state.rotate, 0) - 90;
        if (newRotate < 0) {
            newRotate = 360 + newRotate;
        }
        this.setRotateChange(newRotate);
    };

    onCrop = () => {        
        this.props.setShowLoading("SHOW_LOADING_CROP_PICTURE", true);
        const { editor, scale, rotate, tempImageSrc, profilePicture, isSelectFile, position_x, position_y } = this.state;
        //console.log(tempImageSrc)
        const { guid } = this.props.currentResume;
        if (editor !== null) {
            const CroppingRect = editor.getCroppingRect();
            const positionX = (CroppingRect.x + CroppingRect.width) / 2 + (CroppingRect.x / 2);
            const positionY = (CroppingRect.y + CroppingRect.height) / 2 + (CroppingRect.y / 2);
            const url = editor.getImageScaledToCanvas().toDataURL();
            const file = this.DataURLtoFile(url, guid);
            if (!this.state.isShowSpinner) {
                this.props.showSpinner(true);
            }
            this.props.updateProfilePicture(url);
            let uploadOrginal = false;
            let orginalFile = "";
            if ((tempImageSrc !== "" && profilePicture !== tempImageSrc) || isSelectFile) {
                uploadOrginal = true;
                orginalFile = this.DataURLtoFile(tempImageSrc, orginalFile+guid);
            }
            const files = [file, orginalFile];
            this.props.uploadCroppedProfilePicture(url, scale, rotate, positionX, positionY, tempImageSrc);              
            this.props.uploadCroppedProfilePictureOnServer(guid, files, scale, rotate, positionX, positionY, position_x, position_y , uploadOrginal);
            this.props.hideModal();
            
        }
    };

    DataURLtoFile = (dataurl, filename) => {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };

    render() {
        const { currentResume } = this.props;
        const { deleteLoading, showDeleteMessage, profilePicture, scale, rotate, positionX, positionY, tempImageSrc, pictureHasChanged, scaleHasChanged, rotateHasChanged, positionHasChanged } = this.state;
        return (
            <React.Fragment>
                <div  className="modal-inner-container">
                    <div className="modal-title-container">
                        <div className="modal-title">{currentResume.translation['edit_your_picture']}</div>
                    </div>
                    <div>
                        <div className="editorOverlayInner">
                            <div className="editorModalContent clearfix">
                                <div className="cropCnt">
                                    <div className="avatar-container">
                                        {this.props.picLoading ?
                                            <div className="loader" style={{ alignSelf: "center" }}><div className="lds-facebook"><div></div><div></div><div></div></div></div>
                                            :
                                            <AvatarEditor
                                                onPositionChange={this.onPositionChange}
                                                image={(tempImageSrc !== "" && profilePicture !== tempImageSrc) ? tempImageSrc : profilePicture.includes('data:image/') ? profilePicture : _HostnamePre + profilePicture}
                                                border={50}
                                                scale={scale}
                                                rotate={rotate}
                                                position={{ x: positionX, y: positionY }}
                                                ref={this.setEditorRef} className="cropCanvas" />
                                        }
                                    </div>
                                    <div className="crop-container">
                                        <div className="zoom-picture-container">
                                            <div className="zoom-picture-title">{currentResume.translation['zoom_picture']}</div>
                                            <div className="zoom-icons-container">
                                                <FontAwesomeIcon icon={faImage} style={{ fontSize: "10pt", color: "#b6b7b7", marginRight: "10px" }} />
                                                <input style={{ width: '100%' }} type="range" value={scale} name="scale" min="1" max="4" step="0.01" onChange={this.onScaleChange} />
                                                <FontAwesomeIcon icon={faImage} style={{ fontSize: "20pt", color: "#b6b7b7", marginLeft: "10px" }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="crop-container" style={{ marginTop: "0px" }}>
                                        <div className="zoom-picture-container">
                                            <div className="zoom-picture-title">{currentResume.translation['rotate']}: ({rotate})</div>
                                            <div className="zoom-icons-container">
                                                <button className="rotate-button" onClick={this.rotateLeft} ><FontAwesomeIcon icon={faArrowLeftRotate} /></button>
                                                <span style={{ margin: "0px 10px" }}>0&#176;</span>
                                                <input style={{ width: '100%' }} type="range" value={rotate} name="rotate" min={0} max={360} step={1} onChange={this.onRotateChange} />
                                                <span style={{ margin: "0px 10px" }}>360&#176;</span>
                                                <button className="rotate-button" onClick={this.rotateRight} ><FontAwesomeIcon icon={faArrowRightRotate} /></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="crop-picture-container">
                                        <div className="crop-button-container">
                                            <button className="crop-button" onClick={this.onCrop} style={(!this.props.pictureHasChanged && !pictureHasChanged && !rotateHasChanged && !scaleHasChanged && !positionHasChanged) ? { backgroundColor: "#b6b7b7" } : {}} disabled={(!this.props.pictureHasChanged && !pictureHasChanged && !rotateHasChanged && !scaleHasChanged && !positionHasChanged) ? true : false}>
                                                <FontAwesomeIcon icon={faCrop} style={currentResume.language === 'ar' ? { marginLeft: "10px" } : { marginRight: "10px" }} />{currentResume.translation['crop_and_save']}</button>
                                        </div>
                                    </div>
                                    <div className="change-picture-container">
                                        <div className="crop-button-container">
                                            <label htmlFor="upload-button" >
                                                <div className="change-picture-button">
                                                    <input type="file" accept="image/jpeg, image/x-png, .jpg, .jpeg, .png" className="change-picture-file" id="upload-button" onChange={this.fileChangedHandler} />
                                                    <FontAwesomeIcon icon={faUpload} style={currentResume.language === 'ar' ? { marginLeft: "10px" } : { marginRight: "10px" }} />{currentResume.translation['change_picture']}</div>
                                            </label>
                                        </div>
                                    </div>
                                    {!this.props.pictureHasChanged && !pictureHasChanged ?
                                        <div className="delete-picture-container">
                                            <div className="crop-button-container">
                                                {(showDeleteMessage === true) ?
                                                    (deleteLoading === false) ?
                                                        <div className="flex-center-fullwidth">
                                                            <span className="are-you-sure-button">{currentResume.translation['are_you_sure']}</span>
                                                            <label htmlFor="upload-button" onClick={this.deletePicture} >
                                                                <div className="yes-button">
                                                                {currentResume.translation['yes']}</div>
                                                            </label>
                                                            <label htmlFor="upload-button" onClick={this.hideDeleteMessage}>
                                                                <div className="no-button">
                                                                {currentResume.translation['no']}</div>
                                                            </label>
                                                        </div>
                                                        :
                                                        <div className="loader" style={{ top: "-21px" }}><div className="lds-facebook"><div></div><div></div><div></div></div></div>
                                                    :
                                                    <button className="delete-picture-button" style={(this.props.currentResume.personalDetails.image !== "") ? { } : { backgroundColor: "#b6b7b7"}} onClick={this.showDeleteMessage}  disabled={(this.props.currentResume.personalDetails.image !== "") ? false : true}>
                                                        <FontAwesomeIcon icon={faTimesCircle} style={currentResume.language === 'ar' ? { marginLeft: "10px" } : { marginRight: "10px" }} />{currentResume.translation['delete_picture']}</button>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className="delete-picture-container">
                                            <div className="crop-button-container">                                                
                                                <button className="delete-picture-button" onClick={this.props.hideModal}>
                                                <FontAwesomeIcon icon={faTimesCircle} style={currentResume.language === 'ar' ? { marginLeft: "10px" } : { marginRight: "10px" }} />{currentResume.translation['cancel']}
                                                </button>                                                
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentResume: state.viewModel.currentResume,
        preview: state.preview,
        isDataLoaded: state.isDataLoaded,
        picLoading: state.picLoading
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        uploadCroppedProfilePictureOnServer: (guid, file, scale, rotate, positionX, positionY, position_x, position_y) => dispatch(uploadCroppedProfilePictureOnServer(guid, file, scale, rotate, positionX, positionY, position_x, position_y)),
        uploadCroppedProfilePicture: (file, scale, rotate, positionX, positionY, tempImageSrc) => dispatch(uploadCroppedProfilePicture(file, scale, rotate, positionX, positionY, tempImageSrc)),
        showSpinner: (isShowSpinner) => dispatch(showSpinner(isShowSpinner)),
        onLoadSuccess: (guid) => dispatch(onLoadSuccess(guid)),
        updateProfilePicture: (profilePicture) => dispatch(updateProfilePicture(profilePicture)),
        hideModal: () => dispatch(hideModal()),
        DeleteProfilePicture: (guid) => dispatch(DeleteProfilePicture(guid)),
        DeleteProfilePictureOnServer: (guid) => dispatch(DeleteProfilePictureOnServer(guid)),
        setShowLoading: (type, value) => dispatch(setShowLoading(type, value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePictureEditor)
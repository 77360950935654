import React, { Component } from 'react'
import { connect } from 'react-redux'

class InlineLoading extends Component {
    render() {
        const { isShowLoading } = this.props.loading;
        return (
            <React.Fragment>
                {isShowLoading &&
                    <div className="inline-loading">                                                     
                        <img alt="loading" src="loading" width="20" /> Loading
                    </div>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.loading
    }
}


export default connect(mapStateToProps)(InlineLoading)

import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import Slider from "react-slick";
import TemplateListItem from './TemplateListItem'
import $ from 'jquery';

function setSlickSliderWidth() {
  $('.slick-slider').css("width", ($(window).width() - 80) + 'px');
}

const TemplateFooterContainer = (props) => {
  const useStyles = createUseStyles({
    container: {
      height: 'fit-content',
      display: 'none',
      flexDirection: 'row !important',
      justifyContent: 'space-evenly',
      position: 'fixed',
      width: 'inherit',
      zIndex: 'inherit',
      bottom: 0,
      alignItems: 'center',
      zIndex:3
    },
  })

  const [selectedTemplate, setSelectedTemplate] = useState(props.currentResume.template);
  const [slideIndex, setSlideIndex] = useState(6);
  useEffect(() => {
    slideIndex !== -1 &&
      setSlickSliderWidth();
  }, [window.innerWidth, props.isShowEditor]);

  useEffect(() => {
    setSlideIndex(props.templateList.findIndex(x => x.name === props.currentResume.template))
  }, [props.currentResume.template]);

  useEffect(() => {
    slideIndex !== -1 &&
      setSlickSliderWidth();
  }, [slideIndex]);

  const onChangeSelectedTemplate = (name) => {
    setSelectedTemplate(name);
  }

  const classes = useStyles()

  //const slidesToShow = props.isShowEditor ?
    //props.innerWidth > 1600 ? Math.ceil(props.innerWidth / 500) : Math.floor(props.innerWidth / 500)
    //:
    const slidesToShow = props.innerWidth > 1600 ? Math.ceil(props.innerWidth / 250) : Math.floor(props.innerWidth / 250);
  return (
    props.displayColorPicker !== true &&
    <div 
    className="templatesFooter" 
    style={{
      height: 'fit-content',
      display: 'none',
      flexDirection: 'row !important',
      justifyContent: 'space-evenly',
      position: 'fixed',
      width: 'inherit',
      zIndex: 'inherit',
      bottom: 0,
      alignItems: 'center',
      zIndex:100,
      background: props.isDarkMode ? '#2f2e2e' : '#f4f4f4',
      boxShadow: props.isDarkMode ? '0 -4px 0.5cm rgb(0 0 0 / 50%)' : 'rgb(0 0 0 / 50%) 20px 2px 0.5cm',
    }}>
      {/* {
        displayColorPicker &&
        <div
          className="color-pop-over"
          ref={colorPopOver}
          style={{
            left: innerWidth < 1100 ? !isShowEditor ? 'calc(50% + 85px)' : 'calc(50% - 110px)': 'calc(50% - 110px)'
          }}
        >
          <div />
          <ChromePicker
            color={color}
            onChange={handleChange}
            disableAlpha={true}
            onChangeComplete={(color) => ChangeColorOnServer(color.hex)}
          />
        </div>
      } */}
      {
        slideIndex !== -1 &&
        <Slider {...{
          infinite: false,
          slidesToShow: slidesToShow,
          slidesToScroll: slidesToShow,
          initialSlide: slideIndex,
          touchMove: (e) => {
            e.stopPropagation();
          }
        }}>
          {props.templateList && props.templateList.length > 0 && props.templateList.slice(0, 12).map((templateListItem, index) => {
            return (
              <TemplateListItem
                key={index}
                name={templateListItem.name}
                thumbnail={templateListItem.thumbnail}
                selected={templateListItem.selected}
                selectedTemplate={props.currentResume.template}
                onChangeSelectedTemplate={onChangeSelectedTemplate}
              />
            )
          })}
        </Slider>
      }
    </div >
  )
}

export default TemplateFooterContainer;
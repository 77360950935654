import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { GetResumeList } from '../actions/resume/resumeListActions'
import ResumeListItem from './ResumeListItem'
import PlusButton from './PlusButton'
import axios from 'axios'
import { CreateResume, setShowLoading } from '../actions/rootActions'
import { setLeftScreenItemFullWidth } from '../actions/rootActions'
import { _HostnamePre } from '../Const'
import { isLoggedIn } from '../actions/auth/authActions'
import Navbar from './Navbar';


class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resumeList: props.resumeList,
      redirect: null,
      loaded: false,
      isResume: true
    }
  }

  toggleIsResume = () => {
    this.setState({
      isResume: !this.state.isResume
    })
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loaded: true
      });
    }, 500);
  }
  componentDidUpdate(prevProps) {
    if (this.props.auth.isLoggedIn !== prevProps.auth.isLoggedIn) {
      this.setState({
        redirect: "/"
      });
    }
  }

  componentWillMount() {
    if (window.location.href.indexOf("/main/resumes") > -1) {
      this.setState({
        isResume: true
      });
    } else {
      this.setState({
        isResume: false
      });
    }
    this.setState({
      loaded: false
    });
    this.props.isLoggedIn();
    document.documentElement.scrollTop = 0;
    this.props.setLeftScreenItemFullWidth(true);
    this.props.GetResumeList();
    //alert(window.location.href);
    if (window.location.href.indexOf("newResume=") > -1) {
      var template = window.location.href.split('newResume=')[1];
      if (template.indexOf("&") > -1) {
        template = template.split('&')[0];
      }
      //alert(template);
      this.createResume('resume', template);
    } else if (window.location.href.indexOf("newCoverLetter=") > -1) {
      var template = window.location.href.split('newCoverLetter=')[1];
      if (template.indexOf("&") > -1) {
        template = template.split('&')[0];
      }
      //alert(template);
      this.createResume('coverLetter', template);
    }
  }
  createResume = (type, template = '') => {
    this.props.setShowLoading("SHOW_LOADING_CREATE_RESUME", true);
    CreateResume(type, template, (response) => {
      response.data.viewModel && response.data.viewModel.guid ?
        this.setState({
          redirect: response.data.viewModel.type === "resume" ? "/main/resumes/" + response.data.viewModel.guid + "/editor" : "/main/cover-letters/" + response.data.viewModel.guid + "/editor"
        })
        :
        this.setState({
          redirect: "/"
        });
      this.props.setShowLoading("SHOW_LOADING_CREATE_RESUME", false);
    })
  }
  duplicateResume = (guid) => {
    axios({
      url: _HostnamePre + 'index.php?controller=Resume&method=DuplicateResume&guid=' + guid,
      method: 'get',
      headers: {
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    }).then((response) => {
      let url = "/main/cover-letters/" + response.data.viewModel.guid + "/editor";
      if (window.location.href.indexOf("/main/resumes") > -1) {
        url = "/main/resumes/" + response.data.viewModel.guid + "/editor";
      }
      this.setState({
        redirect: url
      });
      this.props.setShowLoading("SHOW_LOADING_DUPLICATE_RESUME", false);
    });
  }
  render() {
    const { auth } = this.props;
    const { redirect } = this.state;
    if (redirect !== null) {
      return <Redirect to={redirect} />
    }
    const { resumeList } = this.props;

    const type = this.state.isResume ? 'resume' : 'coverLetter';
    return (
      this.state.loaded &&
      <div className="body-container">
        <Navbar isShowIframe={false} preview={this.props.isMobilePreview} />
        <div className="container">
          <div className='list_head_title'>

            <div
              style={{
                cursor: !this.state.isResume ? 'pointer' : 'default'
              }}
              className={this.state.isResume ? 'selected' : ''}
              onClick={() => {
                !this.state.isResume && this.toggleIsResume()
                this.props.GetResumeList()
              }}>
              <Link to={'/main/resumes'}>Resumes</Link>
            </div>

            <div
              style={{
                cursor: this.state.isResume ? 'pointer' : 'default'
              }}
              className={!this.state.isResume ? 'selected' : ''}
              onClick={() => {
                this.state.isResume && this.toggleIsResume()
                this.props.GetResumeList()
              }}>
              <Link to={'/main/cover-letters'}>Cover Letters</Link>
            </div>

          </div>
          <div className="resume_list_container">

            {resumeList && resumeList.length > 0 && resumeList.filter(x => x.type == type).map((resumeListItem, index) => {
              return (
                <ResumeListItem
                  key={index}
                  guid={resumeListItem.guid}
                  title={resumeListItem.title}
                  pdfFileName={resumeListItem.pdfFileName}
                  pdfThumbnailFileName={resumeListItem.pdfThumbnailFileName}
                  updatedTime={resumeListItem.updatedTime}
                  language={resumeListItem.language}
                  duplicateResume={(guid) => this.duplicateResume(guid)} />
              )
            })}

            <PlusButton onClick={() => {
              (window.location.href.indexOf("/resumes") > -1) ?
                this.createResume('resume')
                :
                this.createResume('coverLetter')
            }
            } />
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    resumeList: state.viewModel.resumeList,
    auth: state.viewModel.auth,
    messages: state.messages,
    currentResume: state.currentResume,
    isMobilePreview: state.isMobilePreview,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    GetResumeList: () => dispatch(GetResumeList()),
    isLoggedIn: () => dispatch(isLoggedIn()),
    setShowLoading: (type, value) => dispatch(setShowLoading(type, value)),
    setLeftScreenItemFullWidth: (value) => dispatch(setLeftScreenItemFullWidth(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)
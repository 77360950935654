import React, { Component } from 'react';
import { connect } from 'react-redux'
import { changeLanguage, changeLanguageOnServer, ToggleCategoryDisplay } from '../actions/resume/generalResumeActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark, faDownload, faFileAlt, faMoon, faPaintBrush, faSun, faTimesCircle, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import { updateTitle, changeComponent, download, ToggleIsDarkMode, setShowLoading, setShowEditor, setShowTemplates } from '../actions/rootActions'
import { Link } from 'react-router-dom';
import { ChangeColor, ChangeColorOnServer, closeResume } from '../actions/resume/resumeListActions';
import { Redirect } from 'react-router-dom'
//import { _HostnamePre } from '../Const';
import $ from 'jquery';
import PreviewButton from './PreviewButton';
import EditButton from './EditButton';

var embedStyle = $('.rightScreenItem').attr("style");
var rightScreenItemStyle = $('.rightScreenItem').attr("style");
var iconWrapperDirectionsLeftSvg = $('.iconWrapperDirectionsLeft svg').css("color");

function fix(isShowEditor, setShowTemplates, isShowTemplates) {
    //console.log(isShowEditor);
    if (isShowEditor && window.innerWidth < 1020) {
        slideLeft(isShowEditor);
        setShowTemplates(false);
    }
    // if(!isShowEditor)
    // if (window.innerWidth >= 1020) {
    //     console.log(window.innerWidth)
    //     $('.rightScreenItem').css("width", "50%");
    //     setShowTemplates(true)
    //     toggleShowEditor(true, setShowTemplates, isShowTemplates);
    // }
    // if(!isShowEditor)
    // if (window.innerWidth < 1020) {
    //     console.log(window.innerWidth)
    //     $('.rightScreenItem').css("width", "100%");
    //     toggleShowEditor(false, setShowTemplates, isShowTemplates);
    //     setShowTemplates(false)
    // }
}
function toggleShowEditor(isShowEditor, setShowTemplates, isShowTemplates) {
    if (!isShowEditor /*$('.container.container-edit').css("display") !== 'none'*/) {


        $('.iconWrapperDirectionsLeft > div').css("border", '1px inset #fff');
        iconWrapperDirectionsLeftSvg = $('.iconWrapperDirectionsLeft svg').css("color");
        $('.iconWrapperDirectionsLeft svg').css("color", '#5494e2');
        $('.container.container-edit').hide();
        $('.resumeTitle').hide();
        rightScreenItemStyle = $('.rightScreenItem').attr("style");
        $('.rightScreenItem').attr("style", "width: 100% !important; max-width: 100% !important; height: 100% !important");
        if (window.innerWidth < 1690) {
            embedStyle = $('.embed').attr("style");
            if (window.innerWidth > 1020) {
                $('.embed').attr("style", "transform-origin: unset; position: unset; transform: unset;");
                //$('.rightScreenItem').css('display', 'flex');
            }
            if ($('#colorsSamples').css("display") === 'flex') {
                if (window.innerWidth < 577) {
                    $('.embed').css("margin-top", "150px");
                } else {
                    $('.embed').css("margin-top", "100px");
                }
                if (window.innerWidth < 1020) {
                    $('.embedInnerContainer').css("margin-bottom", "270px");
                    $('.leftScreenItem').css("margin-bottom", "270px");
                }
            } else {
                $('.embed').css("margin-top", "50px");
                $('.embedInnerContainer').css("margin-bottom", "0px");
                $('.leftScreenItem').css("margin-bottom", "0px");
            }
        }


        //$('.slick-slider').css("width", ($('.rightScreenItem').width() - 50) + 'px');
    } else {

        $('.iconWrapperDirectionsLeft > div').css("border", '1px inset transparent');
        $('.iconWrapperDirectionsLeft svg').css("color", iconWrapperDirectionsLeftSvg);
        $('.container.container-edit').show();
        $('.resumeTitle').show();
        $('.rightScreenItem').attr("style", rightScreenItemStyle);
        $('.rightScreenItem').css('display', 'flex');
        if (window.innerWidth < 1690) {
            $('.embed').removeAttr("style");
            $('.embed').attr("style", embedStyle);
            if ($('#colorsSamples').css("display") === 'flex') {
                if (window.innerWidth < 1690) {
                    if (window.innerWidth < 577) {
                        $('.embed').css("margin-top", "150px");
                    } else {
                        $('.embed').css("margin-top", "100px");
                    }
                }
                if (window.innerWidth < 1020) {
                    $('.embedInnerContainer').css("margin-bottom", "270px");
                    $('.leftScreenItem').css("margin-bottom", "270px");
                }
            } else {
                $('.embed').css("margin-top", "50px");
                $('.embedInnerContainer').css("margin-bottom", "0px");
                $('.leftScreenItem').css("margin-bottom", "0px");
            }
        }
        //slideLeft()
        //$('.slick-slider').css("width", ($('.rightScreenItem').width() - 160) + 'px');
    }
}


function slideRight(isShowEditor, setShowTemplates) {
    const direction = $('.edit-container').css("direction");
    let distance = false
    if (direction === 'ltr') {
        distance = $('.templates.my-slider').css("left") === '0px'
    } else {
        distance = $('.templates.my-slider').css("right") === '0px'
    }
    if (distance || $('.templatesFooter').css("display") === 'flex') {
        slideLeft(isShowEditor);
        setShowTemplates(false)
    } else {
        if (window.innerWidth < 1690) {
            if (isShowEditor) {
                $('.templatesFooter').css("visibility", 'hidden');
                $('.templates.my-slider').css("visibility", 'visible');
            } else {
                $('.templatesFooter').css("visibility", 'visible');
                $('.templates.my-slider').css("visibility", 'hidden');
            }
        }

        if (isShowEditor) {
            if (direction === 'ltr') {
                $('.templates.my-slider').removeClass('my-slider-ltr');
                $('.templates.my-slider').addClass('my-slider-back-ltr');
            } else {
                $('.templates.my-slider').removeClass('my-slider-rtl');
                $('.templates.my-slider').addClass('my-slider-back-rtl');
            }
            setTimeout(() => {
                $('.container-edit').css("display", 'none');
            }, 500);
        }



        $('.iconWrapperTemplates > div').addClass("selected");
        $('.iconWrapperTemplates svg').addClass("selected");
        localStorage.setItem('stop-icon-jumb', true);
        $('#colorsSamples').slideDown({
            duration: 500,
            start: function () {
                $(this).css({
                    display: "flex"
                })
            }
        });

        if (!isShowEditor) {
            $('.templatesFooter').slideDown({
                duration: 500,
                start: function () {
                    $(this).css({
                        display: "flex"
                    })
                }
            });
        }

        if (window.innerWidth < 577) {
            $('.embed').css("margin-top", 150 + 'px');
        } else {
            $('.embed').css("margin-top", 100 + 'px');
        }
        if (window.innerWidth < 1020) {
            $('.embedInnerContainer').css("margin-bottom", "270px");
            $('.leftScreenItem').css("margin-bottom", "270px");
        }
    }
}

function slideLeft(isShowEditor) {
    const direction = $('.edit-container').css("direction");
    if (isShowEditor) {
        $('.container-edit').css("display", 'block');
        if (direction === 'ltr') {
            $('.templates.my-slider').removeClass('my-slider-back-ltr');
            $('.templates.my-slider').removeClass('my-slider-rtl');
            $('.templates.my-slider').addClass('my-slider-ltr');
        } else {
            $('.templates.my-slider').removeClass('my-slider-back-rtl');
            $('.templates.my-slider').removeClass('my-slider-ltr');
            $('.templates.my-slider').addClass('my-slider-rtl');
        }
    }
    $('.iconWrapperTemplates > div').removeClass("selected");
    $('.iconWrapperTemplates svg').removeClass("selected");
    $('.iconWrapperTemplates svg').css("color", iconWrapperDirectionsLeftSvg);
    $('#colorsSamples').slideUp(300);
    if (!isShowEditor) {
        $('.templatesFooter').slideUp(300);
    }
    $('.embed').css("margin-top", 50 + 'px');
    $('.embedInnerContainer').css("margin-bottom", "0px");
    $('.leftScreenItem').css("margin-bottom", "0px");
}

let isSentCloseResume = false;
class ResumeTitleAndLanguage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: props.currentResume.title,
            isEditTitle: false,
            width: window.innerWidth,
            scrollTop: document.documentElement.scrollTop,
            color: props.currentResume.color,
            redirect: null
        }
    }

    handleResize = () => {
        this.setState({ width: window.innerWidth })
        fix(this.props.isShowEditor, this.props.setShowTemplates, this.props.isShowTemplates)
    }
    handleScroll = () => {
        this.setState({ scrollTop: document.documentElement.scrollTop })
    }
    hashchange = (event) => {
        if (event.newURL.endsWith('/main')) {
            if (isSentCloseResume === false) {
                this.closeResume(this.props.currentResume.guid)
            }
            isSentCloseResume = true;
        }
    }
    componentDidMount() {
        isSentCloseResume = false;
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('orientationchange', this.handleResize);
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('hashchange', this.hashchange);
        this._isMounted = true;
        window.onpopstate = () => {
            if (this._isMounted) {
                const { hash } = location;
                if (hash.indexOf('main/resumes') > -1 || hash.indexOf('main') > -1) {
                    if (!this.props.isShowEditor) {
                        toggleShowEditor(!this.props.isShowEditor, this.props.setShowTemplates, this.props.isShowTemplates);
                        this.props.setShowEditor(!this.props.isShowEditor)
                    }
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentResume.title !== prevProps.currentResume.title) { this.setState({ title: this.props.currentResume.title }); }
        if (prevProps.currentResume.color !== this.props.currentResume.color) {
            this.setState({
                color: this.props.currentResume.color
            });
        }
    }
    editTitle = (isEditTitle) => {
        this.setState({
            isEditTitle: isEditTitle
        });
    }
    handleChange = (color) => {
        this.setState({ color: color.hex })
        this.props.ChangeColor(this.props.currentResume.guid, color.hex);
    };
    handleClose = (color) => {
        this.setState({ color: color });
        this.props.ChangeColorOnServer(this.props.currentResume.guid, color.substr(1));

    };
    saveTitle = (event) => {
        if (event.key === "Enter") {
            this.editTitle(false);
            this.props.updateTitle(this.props.currentResume.guid, this.state.title);
        }
    }
    saveTitleBlur = (event) => {
        this.editTitle(false);
        this.props.updateTitle(this.props.currentResume.guid, this.state.title);
    }
    handleChangeTitle = (e) => {
        this.setState({
            title: e.target.value
        });
    }
    showLanguageList = () => {
        this.setState({
            showLanguageList: true
        });
    }
    hideLanguageList = () => {
        this.setState({
            showLanguageList: false
        });
    }
    changeLanguage = (language) => {
        if (language !== this.props.currentResume.language) {
            this.hideLanguageList();
            this.props.changeLanguage(language);
            this.props.changeLanguageOnServer(this.props.currentResume.guid, language);
        }
    }
    closeResume = (guid) => {
        this.props.setShowEditor(true);
        this.props.closeResume(guid)
        this.props.changeComponent('editor')
        // this.setState({
        //     redirect: '/main'
        // })
    }
    render() {
        const { currentResume, auth, isDarkMode, currentComponent } = this.props;
        const { title, isEditTitle, showLanguageList } = this.state;
        const imageStyle = {
            marginRight: currentResume.language === 'ar' ? 'unset' : "10px",
            marginLeft: currentResume.language === 'ar' ? "10px" : 'unset',
            width: '30px'
        }
        if (this.state.redirect !== null) {
            return <Redirect to="/main" />
        }
        const resumeHeadSectionClassName = (isDarkMode) ? "resume_head_section resume_head_section_dark_mode" : "resume_head_section";
        // const templateButtonClassName = isDarkMode ? 'templatesButton templatesButton_dark_mode disable-select' : 'templatesButton disable-select';
        // const templatesButtonHoverClassName = isDarkMode ? 'templatesButtonHover_dark_mode' : 'templatesButtonHover';
        // const editorButtonHoverClassName = isDarkMode ? 'editorButtonHover_dark_mode' : 'editorButtonHover';
        // const closeButtonHoverClassName = isDarkMode ? 'closeButtonHover_dark_mode' : 'closeButtonHover';
        let url = '/#/main/cover-letters';
        if (window.location.href.indexOf("/main/resumes") > -1) {
            url = '/#/main/resumes';
        }
        return (
            <React.Fragment>
                {
                    this.props.innerWidth < 1020 && this.props.isShowEditor &&
                    <PreviewButton onClick={() => {
                        toggleShowEditor(!this.props.isShowEditor, this.props.setShowTemplates, this.props.isShowTemplates);
                        this.props.setShowEditor(!this.props.isShowEditor)
                    }} />
                }
                {
                    this.props.innerWidth < 1020 && !this.props.isShowEditor &&

                    <EditButton onClick={() => {
                        toggleShowEditor(!this.props.isShowEditor, this.props.setShowTemplates, this.props.isShowTemplates);
                        this.props.setShowEditor(!this.props.isShowEditor)
                    }}
                        onClick2={() => {
                            this.props.setShowTemplates(true);
                            slideRight(this.props.isShowEditor, this.props.setShowTemplates)
                        }} />
                }
                <div className={resumeHeadSectionClassName}
                    style={{
                        width: '100%',
                    }}>
                    <div style={{
                        width: '100%',
                        display: 'contents',
                    }}>
                        <div style={{ display: 'flex', minWidth: 'fit-content', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', width: '100%' }}>
                            <div style={{ display: 'flex', minWidth: 'fit-content', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row' }}>
                                <div className="brand-logo-container">
                                    <a className="brand-logo" href="https://resumejournal.com/">
                                        <img alt="Resume Journal" className="logo" src={isDarkMode ? "/logo_darkMode.svg" : "/logo.svg"} />
                                    </a>
                                </div>
                            </div>
                            {
                                auth.isLoggedIn && currentResume.guid &&

                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                    {
                                        this.props.innerWidth >= 1020 && !this.props.isShowEditor &&

                                        <div className='iconWrapperDirections'
                                            style={{
                                                margin: '0px 5px',
                                                visibility: this.props.innerWidth >= 1020 && !this.props.isShowTemplates ? 'visible' : 'hidden',
                                                width: this.props.innerWidth >= 1020 && !this.props.isShowTemplates ? 'unset' : '0px'
                                            }}>
                                            <div
                                                className='iconWrapperDirectionsLeft'
                                                style={{ padding: 0, cursor: 'pointer', }}
                                                onClick={() => {
                                                    toggleShowEditor(!this.props.isShowEditor, this.props.setShowTemplates, this.props.isShowTemplates);
                                                    this.props.setShowEditor(!this.props.isShowEditor)
                                                }}
                                            >
                                                <div style={{
                                                    padding: '2px 6px',
                                                    border: '1px inset transparent',
                                                }}>
                                                    <FontAwesomeIcon
                                                        icon={faFileAlt}
                                                        style={{
                                                            color: '#ef4b56',
                                                            display: 'flex',
                                                            fontSize: 20
                                                        }}

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        //this.props.innerWidth >= 1020 &&
                                        <div
                                            className='iconWrapperTemplates'
                                            style={{
                                                padding: 0,
                                                cursor: 'pointer',
                                                margin: '0px 5px',
                                                visibility: this.props.innerWidth >= 1020 ? 'visible' : 'hidden',
                                                width: this.props.innerWidth >= 1020 ? 'unset' : '0px'
                                            }}
                                            onClick={() => {
                                                this.props.setShowTemplates(true);
                                                slideRight(this.props.isShowEditor, this.props.setShowTemplates)
                                            }}
                                        >
                                            <div style={{
                                                padding: '2px 6px',
                                                border: '1px inset transparent',
                                            }}>
                                                <ViewQuiltIcon style={{
                                                        color: '#ef4b56',
                                                        display: 'flex',
                                                        fontSize: 32
                                                    }} />
                                                {/* <FontAwesomeIcon
                                                    className={(this.props.bruchShouldJumb && localStorage.getItem('stop-icon-jumb') !== 'true') ? 'bounce' : ''}
                                                    icon={faPaintBrush}
                                                    style={{
                                                        color: '#ef4b56',
                                                        display: 'flex',
                                                        fontSize: 20
                                                    }}

                                                /> */}
                                            </div>
                                        </div>
                                    }
                                    <div
                                        className='iconWrapper'
                                        style={{ padding: '4px 8px', cursor: 'pointer', margin: '0px 5px', }}
                                        onClick={() => this.props.ToggleIsDarkMode(!isDarkMode)}
                                    >
                                        <FontAwesomeIcon
                                            icon={(isDarkMode) ? faSun : faMoon}
                                            style={{
                                                color: '#ef4b56',
                                                display: 'flex',
                                                fontSize: 20
                                            }}
                                        />
                                    </div>
                                    <div
                                        className='iconWrapperDownload'
                                        style={{ padding: '4px 8px', cursor: 'pointer', margin: '0px 5px', }}
                                        onClick={() => {
                                            this.props.setShowLoading("SHOW_LOADING_DOWNLOAD", true);
                                            download(currentResume.guid, () => this.props.setShowLoading("SHOW_LOADING_DOWNLOAD", false))
                                        }}
                                    >
                                        {
                                            this.props.isShowLoadingDownload === true ?
                                                <div className="loader-css-small" style={{ borderWidth: 8 }}></div>
                                                : <FontAwesomeIcon
                                                    icon={faDownload}
                                                    style={{
                                                        color: '#ef4b56',
                                                        display: 'flex',
                                                        fontSize: 20
                                                    }}
                                                />
                                        }

                                    </div>
                                    <div
                                        className='profileButton'
                                        style={{ padding: '4px 8px', cursor: 'pointer', margin: '0px 5px', }}
                                        onClick={() => {
                                            window.location.href = "https://resumejournal.com/account-general";
                                        }}
                                    >
                                        <div style={{
                                            border: '1px inset transparent',
                                        }}>
                                            <FontAwesomeIcon
                                                icon={faUserCircle}
                                                style={{
                                                    color: '#ef4b56',
                                                    display: 'flex',
                                                    fontSize: 20
                                                }}

                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                this.closeResume(this.props.currentResume.guid);
                                                window.location.href = url;
                                            }}
                                        >
                                            <div style={{ padding: '10px 10px' }} className="closeButton">
                                                <FontAwesomeIcon
                                                    icon={faCircleXmark}
                                                    style={{
                                                        color: '#ef4b56',
                                                        display: 'flex',
                                                        fontSize: 20
                                                    }}
                                                />
                                                {/* <div className="closeButtonHover" style={{ justifyContent: 'center', padding: '6px', borderRadius: '10px', cursor: 'pointer', display: 'flex', alignItems: 'flex-end' }}>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {
                    currentComponent === 'editor' &&
                    <div className='resumeTitle' style={{ height: 60, padding: '0 20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ height: 60, display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: 40 }}>
                            {
                                currentComponent === 'editor' && !isEditTitle &&
                                <div className="resume_language" style={currentResume.language === 'ar' ? { minWidth: '0px', marginLeft: '10px' } : { minWidth: '0px', marginRight: '10px' }}>
                                    <div onMouseLeave={this.hideLanguageList} >
                                        {this.props.currentResume.language &&
                                            <div onClick={this.showLanguageList}><img alt={this.props.currentResume.language} src={"../static/flags/" + this.props.currentResume.language} style={{ cursor: "pointer", width: '30px' }} />
                                                {/* <label>English</label> */}
                                            </div>
                                        }
                                        <div className="language_list" style={{ right: currentResume.language === 'ar' ? -15 : -100, display: (showLanguageList) ? "flex" : "none", justifyContent: 'center' }}>
                                            <div onClick={() => this.changeLanguage("en")}><img alt={"English"} src={"../static/flags/en"} style={imageStyle} /><label>English</label></div>
                                            <div onClick={() => this.changeLanguage("sv")}><img alt={"Svenska"} src="../static/flags/sv" style={imageStyle} /><label>Svenska</label></div>
                                            <div onClick={() => this.changeLanguage("da")}><img alt={"Dansk"} src="../static/flags/da" style={imageStyle} /><label>Dansk</label></div>
                                            <div onClick={() => this.changeLanguage("no")}><img alt={"Norsk"} src="../static/flags/no" style={imageStyle} /><label>Norsk</label></div>
                                            {/* <div onClick={() => this.changeLanguage("ar")}><img alt={"عربي"} src="../static/flags/ar" style={imageStyle} /><label>عربي</label></div> */}
                                            <div onClick={() => this.changeLanguage("su")}><img alt={"Suomi"} src="../static/flags/su" style={imageStyle} /><label>Suomi</label></div>
                                            <div onClick={() => this.changeLanguage("fr")}><img alt={"Français"} src="../static/flags/fr" style={imageStyle} /><label>Français</label></div>
                                            <div onClick={() => this.changeLanguage("ne")}><img alt={"Nederlands"} src="../static/flags/ne" style={imageStyle} /><label>Nederlands</label></div>
                                            <div onClick={() => this.changeLanguage("es")}><img alt={"Español"} src="../static/flags/es" style={imageStyle} /><label>Español</label></div>
                                            <div onClick={() => this.changeLanguage("de")}><img alt={"Deutsch"} src="../static/flags/de" style={imageStyle} /><label>Deutsch</label></div>
                                            <div onClick={() => this.changeLanguage("po")}><img alt={"Português"} src="../static/flags/po" style={imageStyle} /><label>Português</label></div>
                                            <div onClick={() => this.changeLanguage("ch")}><img alt={"Čeština"} src="../static/flags/ch" style={imageStyle} /><label>Čeština</label></div>
                                            <div onClick={() => this.changeLanguage("it")}><img alt={"Italiano"} src="../static/flags/it" style={imageStyle} /><label>Italiano</label></div>
                                            <div onClick={() => this.changeLanguage("ru")}><img alt={"Русский"} src="../static/flags/ru" style={imageStyle} /><label>Русский</label></div>
                                            <div onClick={() => this.changeLanguage("pol")}><img alt={"Polski"} src="../static/flags/pol" style={imageStyle} /><label>Polski</label></div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div style={{ height: 60, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div className="resume_title" style={{ fontSize: '1rem', color: '#444' }}>
                                {
                                    !isEditTitle ?
                                        <div
                                            className={(isDarkMode) ? "title title_dark_mode" : "title"}
                                            style={{ marginLeft: "0px" }}
                                            onClick={() => this.editTitle(true)}>
                                            <span
                                                className="edit-title-icon"
                                            >
                                                {title === "" ? currentResume.translation['Untitled'] : title}
                                            </span>
                                        </div>

                                        :
                                        <div className={(isDarkMode) ? "title title_dark_mode" : "title"}>
                                            <input
                                                style={{ backgroundColor: 'transparent !important', color: isDarkMode ? '#b6b7b7' : 'default' }}
                                                className='titleEdit'
                                                maxLength="22"
                                                ref={input => input && input.focus()}
                                                type="text"
                                                value={title}
                                                onKeyPress={this.saveTitle}
                                                onChange={this.handleChangeTitle}
                                                onBlur={this.saveTitleBlur} />
                                        </div>
                                }
                            </div>
                        </div>

                    </div>
                }

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.viewModel.auth,
        currentResume: state.viewModel.currentResume,
        isDarkMode: state.viewModel.auth.isDarkMode,
        isMobilePreview: state.isMobilePreview,
        leftScreenItemFullWidth: state.leftScreenItemFullWidth,
        currentComponent: state.currentComponent,
        isShowLoadingDownload: state.isShowLoadingDownload,
        bruchShouldJumb: state.bruchShouldJumb,
        isShowEditor: state.isShowEditor,
        isShowTemplates: state.isShowTemplates,
        innerWidth: state.innerWidth
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        changeLanguage: (language) => dispatch(changeLanguage(language)),
        changeLanguageOnServer: (guid, language) => dispatch(changeLanguageOnServer(guid, language)),
        updateTitle: (guid, title) => dispatch(updateTitle(guid, title)),
        ToggleCategoryDisplay: (id0, id1) => dispatch(ToggleCategoryDisplay(id0, id1)),
        changeComponent: (currentComponent) => dispatch(changeComponent(currentComponent)),
        ChangeColor: (guid, color) => dispatch(ChangeColor(guid, color)),
        ChangeColorOnServer: (guid, color) => dispatch(ChangeColorOnServer(guid, color)),
        closeResume: (guid) => dispatch(closeResume(guid)),
        ToggleIsDarkMode: (value) => dispatch(ToggleIsDarkMode(value)),
        setShowLoading: (type, value) => dispatch(setShowLoading(type, value)),
        setShowEditor: (value) => dispatch(setShowEditor(value)),
        setShowTemplates: (value) => dispatch(setShowTemplates(value))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ResumeTitleAndLanguage)
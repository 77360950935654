import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function ControlledAccordions({data}) {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div>
            {
                data.map((item, index) => {
                    return (
                        <Accordion className='Accordion' expanded={expanded === 'panel' + index} onChange={handleChange('panel' + index)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={"panel" + index + "bh-content"}
                                id={"panel" + index + "bh-header"}
                            >
                                <Typography>
                                    {item.title}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {item.body}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    );
                })
            }
        </div>
    );
}

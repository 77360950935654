import axios from 'axios'
import { _HostnamePre } from '../../Const'

export const GetTemplateList = (guid) => {
  return (dispatch) => {
    axios.get(_HostnamePre + 'index.php?controller=Resume&method=GetTemplateList&guid=' + guid, {
      headers: {
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    })
      .then(function ({ data }) {
        dispatch({
          type: 'UPDATE_TEMPLATE_LIST',
          data: data
        });
      })
  };
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { toggleTemplates, setLTemplatesLeft, setIsMobilePreview } from '../actions/rootActions'
import { GetTemplateList } from '../actions/template/templateListActions'
import { GetResume } from '../actions/resume/resumeListActions'
import { isLoggedIn } from '../actions/auth/authActions'
import TemplateListItem from './TemplateListItem'

class MobileTemplatesView extends Component {
    componentWillMount(){
        document.documentElement.scrollTop = 0;
        this.setState({
            init: true
        });
        this.handleResize();
        this.props.setIsMobilePreview(true);
        this.props.setLTemplatesLeft(- this.props.innerWidth);
    }
    componentDidUpdate(prevProps){
        if (this.props.auth.isLoggedIn !== prevProps.auth.isLoggedIn) {
            if (this.props.auth.isLoggedIn !== true) window.location.href = "/"
        }
        if (this.props.currentResume !== prevProps.currentResume) {
            this.props.setLTemplatesLeft(- this.props.innerWidth);
        }
    }
    handleResize = () => {
        this.props.setLTemplatesLeft(- this.props.innerWidth);     
    }
    toggleTemplates = () => {
        this.props.toggleTemplates(this.props.mobileViewTemplatesLeft);
    }

    render() {
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('orientationchange', this.handleResize);
        // if(this.props.innerWidth >= 1020){
        //     return <Redirect to={"/main/"+this.props.guid+"/editor"} />
        // }
        const { templateList, mobileViewTemplatesLeft, currentResume } = this.props;
        return (
            <React.Fragment>                
                <div className="mobile-templates-view-container" style={{ left: mobileViewTemplatesLeft, display: 'none'}}>
                    <div className="mobile-templates-view-close" onClick={this.toggleTemplates}>
                        <FontAwesomeIcon icon={faTimes} />
                    </div>
                    <div className="template_list_container">
                    {templateList && templateList.length > 0 && templateList.slice(0, 12).map((templateListItem, index) => {
                        return (
                            <TemplateListItem
                                key={index}
                                name={templateListItem.name}
                                thumbnail={templateListItem.thumbnail}
                                selected={templateListItem.selected}
                                selectedTemplate={currentResume.template}
                            />
                        )
                        })}
                    </div>
                </div>                
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        guid: state.viewModel.currentResume.guid,
        currentResume: state.viewModel.currentResume,
        auth: state.viewModel.auth,
        messages: state.messages,
        templateList: state.templateList,
        mobileViewTemplatesLeft: state.mobileViewTemplatesLeft,
        mobileViewInit: state.mobileViewInit,
        innerWidth: state.innerWidth
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        GetTemplateList: (guid) => dispatch(GetTemplateList(guid)),
        GetResume: (guid) => dispatch(GetResume(guid)),
        isLoggedIn: () => dispatch(isLoggedIn()),
        toggleTemplates: (left) => dispatch(toggleTemplates(left)),
        setLTemplatesLeft: (left) => dispatch(setLTemplatesLeft(left)),
        setIsMobilePreview: (value) => dispatch(setIsMobilePreview(value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileTemplatesView)

const initState = {
  didMount: false,
  currentComponent: 'editor',
  innerWidth: window.innerWidth,
  messages: {
    ErrorMessages: [],
    InfoMessages: [],
    SuccessMessages: [],
    WorningMessages: []
  },
  viewModel: {
    auth: {
      token: null,
      isLoggedIn: null,
      visitorToken: null,
      isDarkMode: null,
      isPremium: null,
    },
    resumeList: [],
    currentResume: {
      guid: null,
      color: "",
      lineSpace: 1.15,
      fontSize: 16,
      hidePicture: true,
      contactInfo: {
        email: "",
        linkdin: "",
        mobile: ""
      },
      personalDetails: {
        croppedProfilePicture: {
          profilePicture: "",
          scale: 0,
          rotate: 0,
          positionX: 0.5,
          positionY: 0.5,
        }, address: {
          companyAddress: "",
          companyCity: "",
          companyName: "",
          companyPostalCode: "",
          hiringManagerName: "",
          hiringManagerRoleName: "",
          street: "",
          postalCode: "",
          city: "",
          country: ""
        },
      },
      professionalSummary: null,
      employmentHistory: [],
      education: [],
      volunteers: [],
      internships: [],
      courses: [],
      certificates: [],
      references: [],
      customSections: [],
      skills: [],
      languages: [],
      hobbies: [],
      links: [],
      showSummary: false,
      blockOrder: [],
      template: "",
      translation: []
    }
  },
  templateList: [],
  // local state
  preview: {
    showIframe: false,
    showOrginalCanvus: true,
    isShowSpinner: false,
  },
  isDataLoaded: false,
  picLoading: false,
  modal: {
    content: null,
    onHideModal: null,
    isShowModal: false,
    isTransparent: false,
    isFillAvailable: false
  },
  loading: {
    isShowLoading: false
  },
  leftScreenItemFullWidth: true,
  mobileViewTemplatesLeft: "-1019px",
  isMobilePreview: false,
  mobileViewInit: true,
  isZoomedIn: false,
  embedWidth: 849,
  embedHeight: 1198,
  tempHeight: "",
  isShowLoadingCropPicture: false,
  isShowLoadingDeleteResume: false,
  isShowLoadingDuplicateResume: false,
  isShowLoadingCreateResume: false,
  isShowLoadingColorItemSelector: false,
  isShowLoadingDownload: false,
  currentTemplate: "",
  scrollTop: 0,
  bruchShouldJumb: false,
  isShowEditor: true,
  isShowTemplates: false,
  displayColorPicker: false
}
const rootReducer = (state = initState, action) => {
  /* auth */
  if (action.type === 'LOGIN' || action.type === 'LOGOUT') {
    const auth = (action.data.viewModel) ? action.data.viewModel.auth : {
      token: null, isLoggedIn: false,
      isPremium: null
    };
    localStorage.setItem('token', auth.token);
    action.type === 'LOGIN' && localStorage.setItem('isDarkMode', auth.isDarkMode);
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        auth: auth,
        currentResume: {
          guid: null,
          hidePicture: true,
          skills: [],
          languages: [],
          hobbies: [],
          links: [],
          employmentHistory: [],
          education: [],
          volunteers: [],
          internships: [],
          courses: [],
          certificates: [],
          references: [],
          customSections: [],
          blockOrder: [],
          contactInfo: {
            email: "",
            linkdin: "",
            mobile: ""
          },
          personalDetails: {
            croppedProfilePicture: {
              profilePicture: "",
              scale: 0,
              rotate: 0,
              positionX: 0.5,
              positionY: 0.5,
            }, address: {
              companyAddress: "",
              companyCity: "",
              companyName: "",
              companyPostalCode: "",
              hiringManagerName: "",
              hiringManagerRoleName: "",
              street: "",
              postalCode: "",
              city: "",
              country: ""
            },
          },
          professionalSummary: ""
        }
      }
    }
  }

  if (action.type === 'IS_LOGGED_IN') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        auth: action.data.viewModel.auth,
      },
      didMount: true
    }
  }

  if (action.type === 'DARK_MODE') {
    action.isDarkMode !== null && localStorage.setItem('isDarkMode', action.isDarkMode)
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        auth: {
          ...state.viewModel.auth,
          isDarkMode: action.isDarkMode
        },
      }
    }
  }


  /* End auth */


  if (action.type === 'LEFT_SCREEN_ITEM_ADJUST') {
    return {
      ...state,
      leftScreenItemFullWidth: action.leftScreenItemFullWidth
    }
  }

  if (action.type === 'TOGGLE_TEMPLATES') {
    return {
      ...state,
      mobileViewTemplatesLeft: action.mobileViewTemplatesLeft,
      mobileViewInit: false
    }
  }

  /* root */
  if (action.type === 'SHOW_SPINNER') {
    return {
      ...state,
      preview: {
        ...state.preview,
        isShowSpinner: action.isShowSpinner
      }
    }
  }
  /* end root */

  if (action.type === 'MOBILE_PREVIW') {
    return {
      ...state,
      isMobilePreview: action.isMobilePreview
    }
  }

  if (action.type === 'UPDATE_SCROLL_VALUE') {
    return {
      ...state,
      scrollTop: action.scrollTop
    }
  }

  /* Resume List */

  if (action.type === 'UPDATE_TITLE') {
    return {
      ...state,
      messages: action.data.messages,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          title: action.title
        }
      }
    }
  }
  if (action.type === 'CHANGE_COMPONENT') {
    return {
      ...state,
      currentComponent: action.currentComponent
    }
  }
  if (action.type === 'UPDATE_PICTURE') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          personalDetails: {
            ...state.viewModel.currentResume.personalDetails,
            image: action.image,
            croppedProfilePicture: {
              ...state.viewModel.currentResume.personalDetails.croppedProfilePicture,
              profilePicture: action.croppedProfilePicture,
              scale: action.scale,
              rotate: action.rotate,
              positionX: action.positionX,
              positionY: action.positionY,
            }
          }
        }
      }
    }
  }
  if (action.type === 'UPDATE_JOB') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          employmentHistory: action.newList
        }
      }
    }
  }
  if (action.type === 'UPDATE_EDUCATION') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          education: action.newList
        }
      }
    }
  }
  if (action.type === 'UPDATE_INTERNSHIP') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          internships: action.newList
        }
      }
    }
  }
  if (action.type === 'UPDATE_VOLUNTEER') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          volunteers: action.newList
        }
      }
    }
  }
  if (action.type === 'UPDATE_COURSE') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          courses: action.newList
        }
      }
    }
  }
  if (action.type === 'UPDATE_CERTIFICATE') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          certificates: action.newList
        }
      }
    }
  }
  if (action.type === 'UPDATE_REFERENCE') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          references: action.newList
        }
      }
    }
  }
  if (action.type === 'UPDATE_CUSTOM_SECTION') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          refecustomSectionsrences: action.newList
        }
      }
    }
  }
  if (action.type === 'UPDATE_SKILL') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          skills: action.newList
        }
      }
    }
  }
  if (action.type === 'UPDATE_LANGUAGE') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          languages: action.newList
        }
      }
    }
  }
  if (action.type === 'UPDATE_HOBBY') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          hobbies: action.newList
        }
      }
    }
  }
  if (action.type === 'UPDATE_LINK') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          links: action.newList
        }
      }
    }
  }
  if (action.type === 'DELETE_PROFILE_PICTURE') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          hidePicture: true,
          personalDetails: {
            ...state.viewModel.currentResume.personalDetails,
            image: "",
            croppedProfilePicture: {
              profilePicture: "",
              scale: 0,
              rotate: 0,
              positionX: 0.5,
              positionY: 0.5,
            }
          }
        }
      }
    }
  }
  if (action.type === 'UPDATE_EMPLOYMENT_HISTORY') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          employmentHistory: action.newList
        }
      }
    }
  }
  if (action.type === 'UPDATE_EDUCATION') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          education: action.newList

        }
      }
    }
  }
  if (action.type === 'UPDATE_INTERNSHIP') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          internships: action.newList

        }
      }
    }
  }

  if (action.type === 'UPDATE_VOLUNTEER') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          volunteers: action.newList

        }
      }
    }
  }
  if (action.type === 'UPDATE_COURSE') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          courses: action.newList

        }
      }
    }
  }
  if (action.type === 'UPDATE_CERTIFICATE') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          certificates: action.newList

        }
      }
    }
  }
  if (action.type === 'UPDATE_REFERENCE') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          references: action.newList
        }
      }
    }
  }
  if (action.type === 'UPDATE_CUSTOM_SECTION') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          refecustomSectionsrences: action.newList
        }
      }
    }
  }
  if (action.type === 'UPDATE_SKILL') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          skills: action.newList
        }
      }
    }
  }
  if (action.type === 'UPDATE_LANGUAGE') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          languages: action.newList
        }
      }
    }
  }
  if (action.type === 'UPDATE_HOBBY') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          hobbies: action.newList
        }
      }
    }
  }
  if (action.type === 'UPDATE_LINK') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          links: action.newList
        }
      }
    }
  }

  if (action.type === 'UPDATE_LIST') {
    return {
      ...state,
      modal: {
        ...state.modal,
        isShowModal: false,
        content: null,
        isTransparent: false,
        isFillAvailable: false
      },
      loading: {
        isShowLoading: false
      },
      preview: {
        ...state.preview,
        showIframe: false
      },
      viewModel: {
        ...state.viewModel,
        resumeList: action.data.viewModel ? action.data.viewModel.resumeList : [],
        auth: {
          ...state.viewModel.auth,
          isLoggedIn: action.data.viewModel ? action.data.viewModel.auth.isLoggedIn : false,
          isDarkMode: action.data.viewModel ? action.data.viewModel.auth.isDarkMode !== null ? action.data.viewModel.auth.isDarkMode : localStorage.getItem("isDarkMode") === "true" : null,
        },
        currentResume: {
          guid: null,
          hidePicture: true,
          personalDetails: {
            croppedProfilePicture: {
              profilePicture: "",
              scale: 0,
              rotate: 0,
              positionX: 0.5,
              positionY: 0.5,
            }, address: {
              companyAddress: "",
              companyCity: "",
              companyName: "",
              companyPostalCode: "",
              hiringManagerName: "",
              hiringManagerRoleName: "",
              street: "",
              postalCode: "",
              city: "",
              country: ""
            },
          },
          professionalSummary: "",
          employmentHistory: [],
          education: [],
          volunteers: [],
          internships: [],
          courses: [],
          certificates: [],
          references: [],
          customSections: [],
          skills: [],
          languages: [],
          hobbies: [],
          links: [],
          blockOrder: []
        }
      },
      currentTemplate: action.data.viewModel ? action.data.viewModel.currentResume.template : ""
    }
  }
  /*if (action.type === 'UPDATE_TEMPLATE_LIST') {
    return {
      ...state,
      messages: action.data.messages,
      viewModel: {
        
        ...state.viewModel,
        auth: action.data.viewModel.auth
      },
      templateList: action.data.viewModel.templateList
    }
  }*/
  /* end Resume List */

  /* resume editor */

  if (action.type === 'CLEAR_RESUME') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          guid: null,
          hidePicture: true,
          personalDetails: {
            croppedProfilePicture: {
              profilePicture: "",
              scale: 0,
              rotate: 0,
              positionX: 0.5,
              positionY: 0.5,
            }, address: {
              companyAddress: "",
              companyCity: "",
              companyName: "",
              companyPostalCode: "",
              hiringManagerName: "",
              hiringManagerRoleName: "",
              street: "",
              postalCode: "",
              city: "",
              country: ""
            },
          },
          professionalSummary: "",
          employmentHistory: [],
          education: [],
          volunteers: [],
          internships: [],
          courses: [],
          certificates: [],
          references: [],
          customSections: [],
          skills: [],
          languages: [],
          hobbies: [],
          links: [],
          blockOrder: []
        }
      }
    }
  }

  if (action.type === 'UPDATE_PERSONAL_DETAILS') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          personalDetails: {
            ...state.viewModel.currentResume.personalDetails,
            firstName: action.firstName,
            lastName: action.lastName,
            jobTitle: action.jobTitle,
            address: {
              ...state.viewModel.currentResume.address,

              companyAddress: action.companyAddress,
              companyCity: action.companyCity,
              companyName: action.companyName,
              companyPostalCode: action.companyPostalCode,
              hiringManagerName: action.hiringManagerName,
              hiringManagerRoleName: action.hiringManagerRoleName,

              street: action.street,
              postalCode: action.postalCode,
              city: action.city,
              country: action.country
            },
            email: action.email,
            mobile: action.mobile,
            nationality: action.nationality,
            dateOfBirth: action.dateOfBirth,
            drivingLicense: action.drivingLicense,
          },
        },
      }
    }
  }
  if (action.type === 'UPDATE_SUMMARY') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          professionalSummary: action.summary,
        }
      }
    }
  }
  if (action.type === 'UPDATE_BLOCK_ORDER_LIST') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          blockOrder: action.blockOrder,
          showSummary: true
        }
      }
    }
  }
  if (action.type === 'LOAD_RESUME') {
    const viewModel = (action.data.viewModel) ? action.data.viewModel : {
      auth: {
        token: null,
        isLoggedIn: null,
        visitorToken: null,
        isPremium: null,
      },
      // resumeList: [],
      currentResume: {
        guid: null,
        hidePicture: true,
        personalDetails: {
          croppedProfilePicture: {
            profilePicture: "",
            scale: 0,
            rotate: 0,
            positionX: 0.5,
            positionY: 0.5,
          }
        },
        professionalSummary: "",
        //address: {},
        template: "",
        employmentHistory: [],
        education: [],
        volunteers: [],
        internships: [],
        courses: [],
        certificates: [],
        references: [],
        customSections: [],
        skills: [],
        languages: [],
        hobbies: [],
        links: [],
        blockOrder: {
          main: {},
          side: {}
        }
      }
    }
    return {
      ...state,
      /*modal:{
        ...state.modal,
        isShowModal: false,
        content: null,
        isTransparent: false
      },*/
      preview: {
        ...state.preview,
        showIframe: true
      },
      viewModel: {
        ...viewModel,
        currentResume: {
          ...viewModel.currentResume,
          personalDetails: {
            ...viewModel.currentResume.personalDetails,
            croppedProfilePicture: viewModel.currentResume.personalDetails.croppedProfilePicture || {
              profilePicture: "",
              scale: 0,
              rotate: 0,
              positionX: 0.5,
              positionY: 0.5,
            }
          }
        }
      },
      templateList: action.data.viewModel && action.data.viewModel.templateList,
      isDataLoaded: false,
      loading: {
        isShowLoading: false
      },
      isShowLoadingCropPicture: false,
      isShowLoadingColorItemSelector: false,
      currentTemplate: viewModel.currentResume ? viewModel.currentResume.template : ""
    }
  }
  /* end resume editor */


  /* profile picture */
  if (action.type === 'UPDATE_PROFILE_PICTURE') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          hidePicture: false,
          personalDetails: {
            ...state.viewModel.currentResume.personalDetails,
            image: action.profilePicture
          }
        }
      }
    }
  }

  if (action.type === 'HIDE_PICTURE') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          hidePicture: action.hidePicture
        }
      }
    }
  }

  /*if (action.type === 'SHOW_LOADING') {
    return {
      ...state,
      loading:{
        isShowLoading: action.isShowLoading
      }
    }
  }*/


  if (action.type === 'SHOW_LOADING_CROP_PICTURE') {
    return {
      ...state,
      isShowLoadingCropPicture: action.value
    }
  }
  if (action.type === 'SHOW_LOADING_DELETE_RESUME') {
    return {
      ...state,
      isShowLoadingDeleteResume: action.value
    }
  }

  if (action.type === 'SHOW_LOADING_DUPLICATE_RESUME') {
    return {
      ...state,
      isShowLoadingDuplicateResume: action.value
    }
  }

  if (action.type === 'SHOW_LOADING_DOWNLOAD') {
    return {
      ...state,
      isShowLoadingDownload: action.value
    }
  }

  if (action.type === 'SHOW_LOADING_CREATE_RESUME') {
    return {
      ...state,
      isShowLoadingCreateResume: action.value
    }
  }

  if (action.type === 'SHOW_LOADING_COLOR_ITEM_SELECTOR') {
    return {
      ...state,
      isShowLoadingColorItemSelector: action.value
    }
  }

  if (action.type === 'BRUCH_SHOULD_JUMB') {
    return {
      ...state,
      bruchShouldJumb: action.value
    }
  }

  if (action.type === 'SWOW_EDITOR') {
    return {
      ...state,
      isShowEditor: action.value
    }
  }

  if (action.type === 'SWOW_TEMPLATES') {
    return {
      ...state,
      isShowTemplates: action.value
    }
  }

  if (action.type === 'DISPLAY_COLOR_PICKER') {
    return {
      ...state,
      displayColorPicker: action.value
    }
  }



  if (action.type === 'CHANGE_TEMPLATE') {
    if (action.name !== '')
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          currentResume: {
            ...state.viewModel.currentResume,
            template: action.name
          }
        },
        currentTemplate: action.name
      }
  }


  if (action.type === 'CHANGE_COLOR') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          color: action.color
        }
      }
    }
  }

  if (action.type === 'CHANGE_LINE_SPACE') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          lineSpace: action.lineSpace
        }
      }
    }
  }
  if (action.type === 'CHANGE_FONT_SIZE') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          fontSize: action.fontSize
        }
      }
    }
  }

  if (action.type === 'CHANGE_LANGUAGE') {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        currentResume: {
          ...state.viewModel.currentResume,
          language: action.language,
        }
      }
    }
  }

  if (action.type === 'CHANGE_FONT') {
    if (action.font !== '')
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          currentResume: {
            ...state.viewModel.currentResume,
            font: action.font
          }
        }
      }
  }




  if (action.type === 'SHOW_MODAL') {
    return {
      ...state,
      modal: {
        isShowModal: action.isShowModal,
        content: action.content,
        onHideModal: action.onHideModal,
        isTransparent: action.isTransparent,
        isFillAvailable: action.isFillAvailable
      }
    }
  }
  if (action.type === 'HIDE_MODAL') {
    return {
      ...state,
      modal: {
        ...state.modal,
        isShowModal: false,
        content: null,
        isTransparent: false,
        isFillAvailable: false
      }
    }
  }
  if (action.type === 'PIC_LOADING') {
    return {
      ...state,
      picLoading: action.picLoading
    }
  }
  /* end profile picture */
  if (action.type === 'TOGGLE_ZOOM') {
    return {
      ...state,
      embedWidth: action.embedWidth,
      embedHeight: action.embedHeight,
      isZoomedIn: action.isZoomedIn
    }
  }
  if (action.type === 'EMBED_WIDTH') {
    return {
      ...state,
      embedWidth: action.embedWidth
    }
  }
  if (action.type === 'EMBED_HEIGHT') {
    return {
      ...state,
      embedHeight: action.embedHeight
    }
  }
  if (action.type === 'ZOOM_IN') {
    return {
      ...state,
      embedWidth: action.embedWidth,
      embedHeight: action.embedHeight,
      tempHeight: action.tempHeight,
      isZoomedIn: true
    }
  }
  if (action.type === 'ZOOM_OUT') {
    return {
      ...state,
      embedHeight: action.embedHeight,
      isZoomedIn: false
    }
  }
  if (action.type === 'SET_INNER_WIDTH') {
    return {
      ...state,
      innerWidth: action.innerWidth
    }
  }

  return state;
}

export default rootReducer
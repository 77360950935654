import React, { Component } from 'react';
import { connect } from 'react-redux'
import { showModal } from '../../actions/rootActions'
import { faAngleUp, faAngleDoubleDown, faAngleDoubleUp, faEyeSlash, faGlobeAmericas, faBriefcase, faHandsHelping, faGraduationCap, faUniversity, faCertificate, faBullhorn, faTrophy, faLanguage, faAnchor, faHeart, faTimes, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import ListWrapper from '../ListWrapper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SortableElement } from 'react-sortable-hoc';
import { Handle } from './Handle'
import SortableList from './SortableList'
import { ToggleCategoryDisplay } from '../../actions/resume/generalResumeActions'
import { UpdateBlockOrder, UpdateBlockOrderOnServer } from '../../actions/resume/resumeListActions'


const emplymentHistoryHelp = () => {
  return (
    <>
      <p style={{ fontSize: '18px', marginTop: '-30px', marginBottom: '39px', marginRight: '20px' }}>
        <h5><strong>Employment History Tips</strong></h5>
      </p>
      <div className='help-modal'>
        <p>
          The employment history section, which often takes the most significant space on your resume, is what most clearly conveys to the employer your professional background.
          It will give the employer a better understanding of your previous achievements, knowledge base, skills, and experience you will bring to the workspace.
        </p>
        <p>
          Write more about the relevant employment and write less about the remaining employments. Regardless of employment's relevance, you should always be concise and to the point in your descriptions.
        </p>
        <br />
        <p>In this section, you should include:</p>
        <p>
          <ul className='ul-list'>
            <li>
              <b>Job title:</b> Give a job title that accurately represents your position within the company.
            </li>
            <li>
              <b>Employer:</b> Name of the company you worked for.
            </li>
            <li>
              <b>Location:</b> Include the location of the business you previously worked for. You can just
              enter "remote" if you worked remotely.
            </li>
            <li>
              <b>Start date / End date:</b> Provide the start- and end date for your employment.
            </li>
            <li>
              <b>Description:</b> In this section, you should describe your achievement and personal
              responsibility. This section should include:
              <ul className='ul-list-2'>
                <li>
                  What have you done in your daily work, and what have you been responsible for?
                </li>
                <li>
                  How have you worked? Maybe you've worked on a project basis? Functional? Acted following a unique service concept? Or in a business that places a strong focus on sales?
                </li>
                <li>
                  Have you accomplished any achievements? Individually or as a member of a department/group?
                </li>
              </ul>
            </li>
          </ul>
        </p>
      </div>
    </>
  )
}

const educationHelp = () => {
  return (
    <>
      <p style={{ fontSize: '18px', marginTop: '-30px', marginBottom: '39px', marginRight: '20px' }}>
        <h5><strong>Education Tips</strong></h5>
      </p>
      <div className='help-modal'>
        <p>
          The education section is an important part of your resume. This section will give the employer your educational background. Many employers insist that those applying for the job have the required qualifications but will at the same time take their experience and personal development into account.
        </p>
        <br />
        <p>For each degree, you should include:</p>
        <p>
          <ul className='ul-list'>
            <li>
              Degree name.
            </li>
            <li>
              Name and location of the school where you received your degree from.
            </li>
            <li>
              Start and graduation date.
            </li>
            <li>
              A brief description of the content of the degree.
            </li>
          </ul>
        </p>
        <br />
        <p>Recommended:</p>
        <p>
          <ul className='ul-list'>
            <li>
              Including a non-completed education is recommended if it is relevant to the
              position/role you are applying for.
            </li>
            <li>
              If you have a high level of education, you can list it first and then list other education
              in reverse chronological order.
            </li>
            <li>
              Write a briefly and concisely description of your degree/ education.
            </li>
          </ul>
        </p>
      </div>
    </>
  )
}

const internshipsHelp = () => {
  return (
    <>
      <p style={{ fontSize: '18px', marginTop: '-30px', marginBottom: '39px', marginRight: '20px' }}>
        <h5><strong>Internships Tips</strong></h5>
      </p>
      <div className='help-modal'>
        <p>
          It is recommended to mention the performed internships in your resume. Depending on how much work experience you have had and how relevant the internship is to the position you are looking for. A solid internship at a reputable company can be beneficial.
          In this section, you should include:
        </p>
        <br />
        <p>
          <ul className='ul-list'>
            <li>
              <b>Job title:</b> Give a job title that accurately represents your internship position.
            </li>
            <li>
              <b>Employer:</b> Name of the company you worked for.
            </li>
            <li>
              <b>Location:</b> Include the location of the business you did your internship at.
            </li>
            <li>
              <b>Start date / End date:</b> Provide your internship's start and end date.
            </li>
            <li>
              <b>Description:</b> In this section, you should describe your responsibilities throughout the internship.
            </li>
          </ul>
        </p>
      </div>
    </>
  )
}

const volunteeringHelp = () => {
  return (
    <>
      <p style={{ fontSize: '18px', marginTop: '-30px', marginBottom: '39px', marginRight: '20px' }}>
        <h5><strong>Volunteering Tips</strong></h5>
      </p>
      <div className='help-modal'>
        <p>
          Volunteering is an activity that can enhance your CV by demonstrating some of your essential hard and soft abilities and skills, giving it a more well-rounded appearance. In this section, you should include:
        </p>
        <br />
        <p>
          <ul className='ul-list'>
            <li>
              <b>Job title:</b> Give a job title that accurately represents your volunteering.
            </li>
            <li>
              <b>Employer:</b> Name of the company you worked for.
            </li>
            <li>
              <b>Location:</b> Include the location of the business you did your volunteering work at.
            </li>
            <li>
              <b>Start date / End date:</b> Provide your volunteering’s start and end date.
            </li>
            <li>
              <b>Description:</b> you should describe your responsibilities throughout the
              volunteering work.
            </li>
          </ul>
        </p>
      </div>
    </>
  )
}

const courseHelp = () => {
  return (
    <>
      <p style={{ fontSize: '18px', marginTop: '-30px', marginBottom: '39px', marginRight: '20px' }}>
        <h5><strong>Course Tips</strong></h5>
      </p>
      <div className='help-modal'>
        <p>
          By adding courses, you may show that you have the knowledge and abilities to be a good fit for the company.
        </p>
        <p>
          In this section, you should include:
        </p>
        <br />
        <p>
          <ul className='ul-list'>
            <li>
              Course name
            </li>
            <li>
              Institution
            </li>
            <li>
              Start date / End date
            </li>
          </ul>
        </p>
      </div>
    </>
  )
}

const skillsHelp = () => {
  return (
    <>
      <p style={{ fontSize: '18px', marginTop: '-30px', marginBottom: '39px', marginRight: '20px' }}>
        <h5><strong>Skills Tips</strong></h5>
      </p>
      <div className='help-modal'>
        <p>
          Your resume's skills section lists your relevant competencies to the positions you are applying for. In this section, you should include the skill and choose between various levels that accurately describe your skill.
        </p>
      </div>
    </>
  )
}

const certificationsHelp = () => {
  return (
    <>
      <p style={{ fontSize: '18px', marginTop: '-30px', marginBottom: '39px', marginRight: '20px' }}>
        <h5><strong>Certifications Tips</strong></h5>
      </p>
      <div className='help-modal'>
        <p>
          Various types of certifications can enhance any resume. It is recommended to focus primarily on certifications directly related to the position you are applying for. When adding a certificate, you should provide:
        </p>
        <br />
        <p>
          <ul className='ul-list'>
            <li>
              Certification name
            </li>
            <li>
              The name of the organisation where you got your certification from.
            </li>
            <li>
              The start and end date
            </li>
          </ul>
        </p>
      </div>
    </>
  )
}

const referencesHelp = () => {
  return (
    <>
      <p style={{ fontSize: '18px', marginTop: '-30px', marginBottom: '39px', marginRight: '20px' }}>
        <h5><strong>References Tips</strong></h5>
      </p>
      <div className='help-modal'>
        <p>
          References are crucial when applying for jobs. It is an essential part of your resume because they determine the recruiter's image of you. A reference should be someone who knows you well, preferably through work. For instance, your references should be able to discuss how you've managed challenges at work and what role you have taken in groups.
        </p>
      </div>
    </>
  )
}

const hobbiesHelp = () => {
  return (
    <>
      <p style={{ fontSize: '18px', marginTop: '-30px', marginBottom: '39px', marginRight: '20px' }}>
        <h5><strong>Hobbies Tips</strong></h5>
      </p>
      <div className='help-modal'>
        <p>
          Including hobbies on your resume will draw attention to significant interests outside your work experience and educational background. Therefore, you have the chance to excel at your hobbies and also demonstrate your skills. Additionally, you give employers a better idea of who you are.
        </p>
      </div>
    </>
  )
}

const languagesHelp = () => {
  return (
    <>
      <p style={{ fontSize: '18px', marginTop: '-30px', marginBottom: '39px', marginRight: '20px' }}>
        <h5><strong>Languages Tips</strong></h5>
      </p>
      <div className='help-modal'>
        <p>
          Language proficiency is always attractive and may even be necessary for some positions. Include the languages you know and choose between various levels that accurately describe your language skills.
        </p>
      </div>
    </>
  )
}

const linksHelp = () => {
  return (
    <>
      <p style={{ fontSize: '18px', marginTop: '-30px', marginBottom: '39px', marginRight: '20px' }}>
        <h5><strong>Links Tips</strong></h5>
      </p>
      <div className='help-modal'>
        <p>
          In this section, you can provide links to your social media profiles, blog, or website. It is essential to provide links to your sites that reflect your professional achievements.
        </p>
      </div>
    </>
  )
}


export class BaseCategory extends Component {
  UpdateBlockOrder = (sectionName) => {
    const { currentResume } = this.props;
    let blockOrder = currentResume.blockOrder.filter(block => block !== sectionName);
    var blockOrderArrayString = "[" + blockOrder.map(block => '"' + block + '"') + "]";
    this.props.UpdateBlockOrder(blockOrder);
    this.props.UpdateBlockOrderOnServer(this.props.currentResume.guid, blockOrderArrayString);
  }
  render() {
    const tabIndex = this.props.tabbable ? 0 : -1;
    var icon = "";
    switch (this.props.item.name) {
      case 'EmploymentHistory':
        icon = faGlobeAmericas
        break;
      case 'Internships':
        icon = faBriefcase
        break;
      case 'Volunteers':
        icon = faHandsHelping
        break;
      case 'Education':
        icon = faGraduationCap
        break;
      case 'Courses':
        icon = faUniversity
        break;
      case 'Certificates':
        icon = faCertificate
        break;
      case 'References':
        icon = faBullhorn
        break;
      case 'Skills':
        icon = faTrophy
        break;
      case 'Languages':
        icon = faLanguage
        break;
      case 'Hobbies':
        icon = faHeart
        break;
      case 'Links':
        icon = faAnchor
        break;
      default:
        icon = faTimes
    }

    let title = '';
    let help = '';
    switch (this.props.item.title) {
      case 'Employment History':
        title = 'employment_history';
        help = emplymentHistoryHelp();
        break;
      case 'Languages':
        title = 'languages';
        help = languagesHelp();
        break;
      case 'Skills':
        title = 'skills';
        help = skillsHelp();
        break;
      case 'Hobbies':
        title = 'hobbies';
        help = hobbiesHelp();
        break;
      case 'Links':
        title = 'links';
        help = linksHelp();
        break;
      case 'Education':
        title = 'education';
        help = educationHelp();
        break;
      case 'Volunteers':
        title = 'volunteers';
        help = volunteeringHelp();
        break;
      case 'Internships':
        title = 'internships';
        help = internshipsHelp();
        break;
      case 'Courses':
        title = 'courses';
        help = courseHelp();
        break;
      case 'Certificates':
        title = 'certificates';
        help = certificationsHelp();
        break;
      case 'References':
        title = 'references';
        help = referencesHelp();
        break;
      default: title = 'error'
    }
    const middle = {
      marginLeft: this.props.currentResume.language === 'ar' ? 'unset' : "10px",
      marginRight: this.props.currentResume.language === 'ar' ? "10px" : 'unset',
    };
    const iconStyle = {
      marginRight: this.props.currentResume.language === 'ar' ? 'unset' : "10px",
      marginLeft: this.props.currentResume.language === 'ar' ? "10px" : 'unset',
    };

    const categoryStyle = {
      textAlign: this.props.currentResume.language === 'ar' ? 'right' : 'unset',
      direction: this.props.currentResume.language === 'ar' ? 'rtl' : 'unset'
    }
    const { isDarkMode } = this.props;
    return (
      <div className="category" style={categoryStyle}>
        <div className="categoryHeader" style={{ cursor: this.props.innerWidth > 1019 ? 'pointer' : 'default' }} onClick={() => this.props.innerWidth > 1019 && this.props.ToggleCategoryDisplay("header", this.props.index)}>
          <div>
            <Handle tabIndex={tabIndex} />
            <span className="middle" style={middle}><FontAwesomeIcon icon={icon} style={iconStyle} />{this.props.currentResume.translation[title]}</span>
          </div>
          <div className="flex-no-wrape">
            <button className="trash-icon delete margin-r10" onClick={() => this.UpdateBlockOrder(this.props.item.id)}>
              <div style={{ display: "inline-block" }}>
                <FontAwesomeIcon icon={faEyeSlash} />
              </div>
            </button>
            <button
              className="help"
              onClick={(e) => {
                e.stopPropagation();
                this.props.showModal(
                  <div>
                    <div style={{ margin: '20px 0px' }}>
                      <label className="personal-details-title">
                        <div className={(isDarkMode) ? "title_dark_mode" : ""}>
                          {help}
                        </div>
                      </label>
                    </div>
                  </div>,
                  null,
                  false,
                  false
                );
              }}>
              <div style={{ display: "inline-block" }}>
                <FontAwesomeIcon icon={faQuestionCircle} style={{ display: "inline-block" }} />
              </div>
            </button>
            <button className="toggle toggle-all" onClick={(e) => { e.stopPropagation(); this.props.ToggleCategoryDisplay("allHeader", this.props.index) }}>
              <div id={"AngleDownAll" + this.props.index} style={{ display: "none" }}>
                <FontAwesomeIcon icon={faAngleDoubleDown} style={{ display: "none" }} />
              </div>
              <div id={"AngleUpAll" + this.props.index} style={{ display: "inline-block" }}>
                <FontAwesomeIcon icon={faAngleDoubleUp} style={{ display: "inline-block" }} />
              </div>
            </button>
            <button className="toggle" onClick={() => this.props.innerWidth <= 1019 && this.props.ToggleCategoryDisplay("header", this.props.index)}>
              <div id={"AngleDown" + this.props.index} style={{ display: "none" }}>
                <FontAwesomeIcon icon={faAngleDown} style={{ display: "none" }} />
              </div>
              <div id={"AngleUp" + this.props.index} style={{ display: "inline-block" }}>
                <FontAwesomeIcon icon={faAngleUp} style={{ display: "inline-block" }} />
              </div>
            </button>
          </div>
        </div>
        {
          <div className="categoryContainer" >
            <ListWrapper
              component={SortableList}
              parentName={this.props.item.name}
              parentIndex={this.props.index}
              items={this.props.item.children}
              shouldUseDragHandle={true}
              helperClass={"stylizedHelper"}
            />
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentResume: state.viewModel.currentResume,
    isDarkMode: state.viewModel.auth.isDarkMode,
    innerWidth: state.innerWidth
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (content, onHideModal, isTransparent, isFillAvailable) => dispatch(showModal(content, onHideModal, isTransparent, isFillAvailable)),
    ToggleCategoryDisplay: (id0, id1) => dispatch(ToggleCategoryDisplay(id0, id1)),
    UpdateBlockOrder: (blockOrder) => dispatch(UpdateBlockOrder(blockOrder)),
    UpdateBlockOrderOnServer: (guid, blockOrder) => dispatch(UpdateBlockOrderOnServer(guid, blockOrder)),
  }
}

const Category = SortableElement(BaseCategory)

export default connect(mapStateToProps, mapDispatchToProps)(Category)
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ProfilePictureEditor from './ProfilePictureEditor';
import ProfilePictureDragZone from './ProfilePictureDragZone';
import Resizer from 'react-image-file-resizer';

class UploadProfilePictureContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tempImageSrc: props.currentResume.personalDetails.image,
            isSelectFile: false,
            pictureHasChanged: false
        }
    }
    setTempImageSrc = (tempImageSrc) => {
        Resizer.imageFileResizer(
            tempImageSrc,
            1000,
            1000,
            'JPEG',
            80,
            0,
            uri => {
                this.setState({
                    tempImageSrc: uri,
                    isSelectFile: false,
                    pictureHasChanged: true
                });
            },
            'base64'
        );
    }
    render() {
        const {tempImageSrc, isSelectFile, pictureHasChanged} = this.state;
        return (
            this.props.currentResume.personalDetails.image || this.props.picLoading || tempImageSrc !== "" ? 
            <ProfilePictureEditor tempImageSrc={tempImageSrc} isSelectFile={isSelectFile} pictureHasChanged={pictureHasChanged} /> 
            : 
            <ProfilePictureDragZone setTempImageSrc={this.setTempImageSrc} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        picLoading: state.picLoading,
        currentResume: state.viewModel.currentResume
    }
}

export default connect(mapStateToProps)(UploadProfilePictureContent)
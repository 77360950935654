import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useState } from 'react';
import { SwatchesPicker, ChromePicker} from 'react-color';

export default function TemplateColors({ changeColor, handleClose }) {
    const [color, setColor] = useState('#000');
    const [mode, setMode] = useState(0);
    const colors = ['#133337', '#264448', '#2a474b', '#334d55', '#1b4e59', '#005964', '#3a6c7b', '#3f7278', '#468499', '#008080', '#3e8763', '#1e6649',  
                    '#2d6047', '#065535', '#1d473a', '#313178', '#242444', '#082a4d', '#2f405e', '#0e2f44', '#20394a', '#2c4f67', '#396685', '#46648c', 
                    '#125a94', '#4982af', '#455a64', '#576675', '#994c8e', '#800080', '#66335e', '#660066', '#656554', '#696969', '#6e7660', '#545454', 
                    '#3f3f3f', '#424739', '#ab7001', '#c35d56', '#87300d', '#8d2d2d', '#7f0000', '#5f2125', '#581010', '#420420', '#33192f', '#263238', 
                    '#2a2a2a', '#1b212f', '#13222c', '#151515', '#000000', 
    ];
    const handleChange1 = (color) => {
        setColor(color.hex)
        changeColor(color.hex);
    };
    const handleChange2 = (color) => {
        setColor(color.hex)
        changeColor(color.hex);
        handleClose(color.hex)
    };
    const handleChange3 = (color) => {
        setColor(color)
        changeColor(color);
        handleClose(color)
    };
    const nextMode = () => {
        const newMode = mode + 1;
        if (newMode === 3) {
            setMode(0)
        } else {
            setMode(newMode)
        }
    }
    return (
        <div className='color-box-container' style={{ display: 'flex', justifyContent: 'center', margin: '20px', flexDirection: 'column', alignContent: 'center' }}>
            {
                mode === 0 &&
                <div style={{ paddingTop: 15, minHeight: '300px' }}>
                    <ChromePicker color={color} onChange={handleChange1} disableAlpha={true} onChangeComplete={(color) => handleClose(color.hex)} />
                </div>
            }
            {
                mode === 1 &&
                <div style={{ maxWidth: '520px', minHeight: '300px' }}>
                    <SwatchesPicker color={color} onChange={handleChange2} disableAlpha={true} />
                </div>
            }
            {
                mode === 2 &&
                <div className='color-box-container' style={{ maxWidth: '784px', display: 'flex', justifyContent: 'center', alignContent: 'center', minHeight: '300px' }}>
                    {
                        colors.map(color => {
                            return (
                                <div
                                    onClick={() => handleChange3(color)}
                                    className='color-box'
                                    style={{
                                        height: '50px',
                                        width: '40px',
                                        margin: '0px',
                                        borderRadius: '0px'
                                    }}
                                >
                                    <div className='color-box-inner' style={{ margin: '2px', borderRadius: '0px' }}>
                                        <div style={{ backgroundColor: color, flexGrow: 3 }}></div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            }
            <div className="crop-picture-container" style={{ width: 'fit-content', margin: '20px auto' }}>
                <div className="crop-button-container">
                    <button className="crop-button" onClick={nextMode}>
                        <FontAwesomeIcon icon={faArrowAltCircleRight} /></button>
                </div>
            </div>
        </div>

    )
}
import React, { Component } from 'react';
import { connect } from 'react-redux'
import ArchimedesTemplate from './Templates/ArchimedesTemplate'
import AristotleTemplate from './Templates/AristotleTemplate'
import CurieTemplate from './Templates/CurieTemplate'
import DarwinTemplate from './Templates/DarwinTemplate'
import EdisonTemplate from './Templates/EdisonTemplate'
import $ from 'jquery';
import EinsteinTemplate from './Templates/EinsteinTemplate';
import GalileiTemplate from './Templates/GalileiTemplate';
import LinnaeusTemplate from './Templates/LinnaeusTemplate';
import LinnaeusTemplateCoverLetter from './Templates/CoverLetters/LinnaeusTemplateCoverLetter';
import FaradayTemplate from './Templates/FaradayTemplate';
import LovelaceTemplate from './Templates/LovelaceTemplate';
import NewtonTemplate from './Templates/NewtonTemplate';
import FranklinTemplate from './Templates/FranklinTemplate';
import EinsteinTemplateCoverLetter from './Templates/CoverLetters/EinsteinTemplateCoverLetter';
import GalileiTemplateCoverLetter from './Templates/CoverLetters/GalileiTemplateCoverLetter';
import LovelaceTemplateCoverLetter from './Templates/CoverLetters/LovelaceTemplateCoverLetter';
import NewtonTemplateCoverLetter from './Templates/CoverLetters/NewtonTemplateCoverLetter';
import FranklinTemplateCoverLetter from './Templates/CoverLetters/FranklinTemplateCoverLetter';
import FaradayTemplateCoverLetter from './Templates/CoverLetters/FaradayTemplateCoverLetter';
import EdisonTemplateCoverLetter from './Templates/CoverLetters/EdisonTemplateCoverLetter';
import DarwinTemplateCoverLetter from './Templates/CoverLetters/DarwinTemplateCoverLetter';
import CurieTemplateCoverLetter from './Templates/CoverLetters/CurieTemplateCoverLetter';
import AristotleTemplateCoverLetter from './Templates/CoverLetters/AristotleTemplateCoverLetter';
import ArchimedesTemplateCoverLetter from './Templates/CoverLetters/ArchimedesTemplateCoverLetter';

function calculateTextColor(color, keepOrginal = false) {
  if (color) {
    var c = color.replace('#', '');
    var rgb = [];
    rgb[0] = parseInt(c.substr(0, 2), 16);
    rgb[1] = parseInt(c.substr(2, 2), 16);
    rgb[2] = parseInt(c.substr(4, 2), 16);
    if (rgb[0] + rgb[1] + rgb[2] < 382) {
      return keepOrginal ? color : '#fff';
    } else {
      return '#444';
    }
  }
  return color;
}


function changeBlockquoteColorHtmlDocument(currentResume) {
  if (currentResume.language === 'ar') {
    $('blockquote').css("border-right", '4px solid ' + calculateTextColor(currentResume.color, true));
    $('blockquote').css("border-left-width", '0px');
    $('blockquote').css("padding-right", '16px');
  } else {
    $('blockquote').css("border-left", '4px solid ' + calculateTextColor(currentResume.color, true));
    $('blockquote').css("border-right-width", '0px');
    $('blockquote').css("padding-left", '16px');
  }

  $('blockquote').css("margin", '0');
}

class HtmlDocument extends Component {

  componentDidUpdate(prevProps) {

    if (prevProps.currentResume !== this.props.currentResume) {
      if (prevProps.currentResume.font !== this.props.currentResume.font) {
        const { currentResume } = this.props;
        var fontFileName = currentResume.font;
        if (typeof fontFileName !== undefined) {
          if (currentResume.font === "Arial, Helvetica") {
            fontFileName = "Arial";
          }
          if (currentResume.font === "Red Rose") {
            fontFileName = "RedRose";
          }
          var oldFontFileName = $('#fontFamily').prop("href");
          if (oldFontFileName !== fontFileName + ".css") {
            $('#fontFamily').prop("href", fontFileName + ".css");
          }
        }
      }
    }
    if (window.location.href.indexOf("editor") > 0 || window.location.href.indexOf("templates") > 0 || window.location.href.indexOf("mobileTemplatesView") > 0) {
      if (prevProps.currentResume !== this.props.currentResume || prevProps.currentTemplate !== this.props.currentTemplate) {
        changeBlockquoteColorHtmlDocument(this.props.currentResume);
      }
    }
  }


  render() {
    const { currentTemplate, currentResume } = this.props;
    const isShouldRenderTemplate = window.location.href.indexOf("editor") > 0 || window.location.href.indexOf("templates") > 0 || window.location.href.indexOf("mobileTemplatesView") > 0;
    var template = "";
    if (isShouldRenderTemplate && currentTemplate === "Archimedes") {
      if (currentResume.type === "coverLetter") {
        template = <ArchimedesTemplateCoverLetter snipMe={this.snipMe} currentTemplate={currentTemplate} />;
      } else {
        template = <ArchimedesTemplate snipMe={this.snipMe} currentTemplate={currentTemplate} />;
      }
    } else if (isShouldRenderTemplate && currentTemplate === "Aristotle") {
      if (currentResume.type === "coverLetter") {
        template = <AristotleTemplateCoverLetter snipMe={this.snipMe} currentTemplate={currentTemplate} />;
      } else {
        template = <AristotleTemplate snipMe={this.snipMe} currentTemplate={currentTemplate} />;
      }
    } else if (isShouldRenderTemplate && currentTemplate === "Curie") {
      if (currentResume.type === "coverLetter") {
        template = <CurieTemplateCoverLetter snipMe={this.snipMe} currentTemplate={currentTemplate} />;
      } else {
        template = <CurieTemplate snipMe={this.snipMe} currentTemplate={currentTemplate} />;
      }
    } else if (isShouldRenderTemplate && currentTemplate === "Darwin") {
      if (currentResume.type === "coverLetter") {
        template = <DarwinTemplateCoverLetter snipMe={this.snipMe} currentTemplate={currentTemplate} />;
      } else {
        template = <DarwinTemplate snipMe={this.snipMe} currentTemplate={currentTemplate} />;
      }
    } else if (isShouldRenderTemplate && currentTemplate === "Edison") {
      if (currentResume.type === "coverLetter") {
        template = <EdisonTemplateCoverLetter snipMe={this.snipMe} currentTemplate={currentTemplate} />;
      } else {
        template = <EdisonTemplate snipMe={this.snipMe} currentTemplate={currentTemplate} />;
      }
    } else if (isShouldRenderTemplate && currentTemplate === "Einstein") {
      if (currentResume.type === "coverLetter") {
        template = <EinsteinTemplateCoverLetter snipMe={this.snipMe} currentTemplate={currentTemplate} />;
      } else {
        template = <EinsteinTemplate snipMe={this.snipMe} currentTemplate={currentTemplate} />;
      }
    } else if (isShouldRenderTemplate && currentTemplate === "Galilei") {
      if (currentResume.type === "coverLetter") {
        template = <GalileiTemplateCoverLetter snipMe={this.snipMe} currentTemplate={currentTemplate} />;
      } else {
        template = <GalileiTemplate snipMe={this.snipMe} currentTemplate={currentTemplate} />;
      }
    } else if (isShouldRenderTemplate && currentTemplate === "Linnaeus") {
      if (currentResume.type === "coverLetter") {
        template = <LinnaeusTemplateCoverLetter snipMe={this.snipMe} currentTemplate={currentTemplate} />;
      } else {
        template = <LinnaeusTemplate snipMe={this.snipMe} currentTemplate={currentTemplate} />;
      }
    } else if (isShouldRenderTemplate && currentTemplate === "Faraday") {
      if (currentResume.type === "coverLetter") {
        template = <FaradayTemplateCoverLetter snipMe={this.snipMe} currentTemplate={currentTemplate} />;
      } else {
        template = <FaradayTemplate snipMe={this.snipMe} currentTemplate={currentTemplate} />;
      }
    } else if (isShouldRenderTemplate && currentTemplate === "Lovelace") {
      if (currentResume.type === "coverLetter") {
        template = <LovelaceTemplateCoverLetter snipMe={this.snipMe} currentTemplate={currentTemplate} />;
      } else {
        template = <LovelaceTemplate snipMe={this.snipMe} currentTemplate={currentTemplate} />;
      }
    } else if (isShouldRenderTemplate && currentTemplate === "Newton") {
      if (currentResume.type === "coverLetter") {
        template = <NewtonTemplateCoverLetter snipMe={this.snipMe} currentTemplate={currentTemplate} />;
      } else {
        template = <NewtonTemplate snipMe={this.snipMe} currentTemplate={currentTemplate} />;
      }
    } else if (isShouldRenderTemplate && currentTemplate === "Franklin") {
      if (currentResume.type === "coverLetter") {
        template = <FranklinTemplateCoverLetter snipMe={this.snipMe} currentTemplate={currentTemplate} />;
      } else {
        template = <FranklinTemplate snipMe={this.snipMe} currentTemplate={currentTemplate} />;
      }
    } else {
      template = "";
    }
    return (
      <React.Fragment>
        {template}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    currentTemplate: state.currentTemplate,
    currentResume: state.viewModel.currentResume,
    scrollTop: state.scrollTop
  }
}
export default connect(mapStateToProps)(HtmlDocument)
import axios from 'axios';
import $ from 'jquery';
import {_HostnamePre} from '../../Const'

export const ToggleSplitSection = () => {
  return (dispatch) => {
      var display = $("#SplitAngleDown").css("display");
      if (display==="none") {
        $(".hideable").slideUp("fast");
        $("#SplitAngleDown").show();
        $("#SplitAngleUp").hide();
        $("#SplitAngleDown > svg").show();
        $("#SplitAngleUp > svg").hide();
      } else {
        $(".hideable").slideDown("fast");
        $("#SplitAngleDown").hide();
        $("#SplitAngleUp").show();
        $("#SplitAngleDown > svg").hide();
        $("#SplitAngleUp > svg").show();
      }
    }
  }

export function updatePersonalDetailsOnServer(guid, firstName, lastName, jobTitle, email, mobile, nationality, dateOfBirth, drivingLicense, street, postalCode, city, country, companyName, hiringManagerName, hiringManagerRoleName, companyPostalCode, companyAddress, companyCity) {
  return function (dispatch) {
    const formData = new FormData();
    formData.append('guid', guid);
    formData.append('jobTitle', jobTitle);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('mobile', mobile);
    formData.append('nationality', nationality);
    formData.append('dateOfBirth', dateOfBirth);
    formData.append('drivingLicense', drivingLicense);
    formData.append('street', street);
    formData.append('postalCode', postalCode);
    formData.append('city', city);
    formData.append('country', country);
    formData.append('companyName', companyName);
    formData.append('hiringManagerName', hiringManagerName);
    formData.append('hiringManagerRoleName', hiringManagerRoleName);
    formData.append('companyPostalCode', companyPostalCode);
    formData.append('companyAddress', companyAddress);
    formData.append('companyCity', companyCity);
    axios.post(_HostnamePre + "index.php?controller=Resume&method=UpdatePersonalDetails", formData, {
      headers: {
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    })
  };
}


export function updatePersonalDetails(guid, firstName, lastName, jobTitle, email, mobile, nationality, dateOfBirth, drivingLicense, street, postalCode, city, country, companyName, hiringManagerName, hiringManagerRoleName, companyPostalCode, companyAddress, companyCity) {
  return (dispatch) => {
    dispatch({ 
      type: 'UPDATE_PERSONAL_DETAILS',
      firstName: firstName,
      lastName: lastName,
      jobTitle: jobTitle,
      email: email,
      mobile: mobile,
      nationality: nationality,
      dateOfBirth: dateOfBirth,
      drivingLicense: drivingLicense,
      street: street,
      postalCode: postalCode,
      city: city,
      country: country, 
      companyName: companyName, 
      companyCity: companyCity,
      companyAddress: companyAddress,
      companyPostalCode: companyPostalCode,
      hiringManagerName: hiringManagerName,
      hiringManagerRoleName: hiringManagerRoleName
    });
};
}

export function uploadCroppedProfilePictureOnServer(guid, files, scale, rotate, positionX, positionY, position_x, position_y, uploadOrginal=false) {
  return function (dispatch) {
    const url = _HostnamePre + 'index.php?controller=Resume&method=UploadCroppedProfilePicture';
    const formData = new FormData();
    formData.append('file[]', files[0]);
    formData.append('file[]', files[1]);
    formData.append('guid', guid);
    formData.append('scale', scale);
    formData.append('rotate', rotate);    
    formData.append('positionX', positionX);
    formData.append('positionY', positionY);
    formData.append('position_x', position_x);
    formData.append('position_y', position_y);    
    formData.append('uploadOrginal', uploadOrginal);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    }
    axios.post(url, formData, config)
  };
}
export function uploadCroppedProfilePicture(url, scale, rotate, positionX, positionY, image) {
  return function (dispatch) {
        dispatch({
          type: 'UPDATE_PICTURE',
          croppedProfilePicture: url,
          scale: scale,
          rotate: rotate,
          positionX: positionX,
          positionY: positionY,
          image: image
        });
  };
}
export const DeleteProfilePicture = (guid) => {
  return (dispatch) => {
    dispatch({ 
      type: 'DELETE_PROFILE_PICTURE'
    });
    dispatch({ 
      type: 'HIDE_MODAL'
    });
  }
}
export const HidePicture = (value) => {
  return (dispatch) => {
    dispatch({ 
      type: 'HIDE_PICTURE',
      hidePicture: value
    });
  }
}

  export const HidePictureOnServer = (guid, hidePicture) => {
    return (dispatch) => {
      axios.get(_HostnamePre +'index.php?controller=Resume&method=HidePicture&guid=' + guid + '&hidePicture=' + hidePicture, {
        headers: {
          'Authorization': `token ${localStorage.getItem('token')}`
        }
      })
    };
  }

export const DeleteProfilePictureOnServer = (guid) => {
  return (dispatch) => {
    axios.get(_HostnamePre + 'index.php?controller=Resume&method=DeleteProfilePicture&guid='+guid, {
      headers: {
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    })  
    .then(function ({ data }) {
      dispatch({ 
        type: 'LOAD_RESUME',
        data: data 
      });
    })    
  };
}


export const onLoadSuccess = (picLoading) => {
  return {
      type: 'PIC_LOADING',
      picLoading: picLoading
  }
}

export const updateProfilePicture = (profilePicture) => {
  return {
      type: 'UPDATE_PROFILE_PICTURE',
      profilePicture: profilePicture
  }
}
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { SortableContainer } from 'react-sortable-hoc';
import Item from './Item'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { UpdateList } from '../../actions/resume/generalResumeActions'
import { debounce } from "lodash";
import axios from 'axios';
import $ from 'jquery';
import {_HostnamePre} from '../../Const'

let lock = false;
let lockTime = 0;
export class BaseSortableList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isloading: false,
      parentName: "",
      itemsCount: 0
    }
  }
  ToggleItemDisplay = function (id0, id1, open = false) {
    lock=true;
    lockTime= Date.now();
    var display = $("#" + id0 + id1).css("display");
    if (display === "none" || open===true) {
      $(".item-inner").slideUp("fast");
      $(".item .toggle > :first-child").show()/*.css("display", "inline-block")*/;
      $(".item .toggle > :first-child > svg").show()/*.css("display", "inline-block")*/;
      $(".item .toggle > :last-child").hide();
      $(".item .toggle > :last-child > svg").hide();
      $("#" + id0 + id1).slideDown("fast")/*.css("display", "flex")*/;
      $("#AngleDown" + id1).hide();
      $("#AngleUp" + id1).show()/*.css("display", "inline-block")*/;
      $("#AngleDown" + id1 + "> svg").hide();
      $("#AngleUp" + id1 + "> svg").show()/*.css("display", "inline-block")*/;
    } else {
      $("#" + id0 + id1).slideUp("fast");
      $("#AngleDown" + id1).show()/*.css("display", "inline-block")*/;
      $("#AngleUp" + id1).hide();
      $("#AngleDown" + id1 + "> svg").show()/*.css("display", "inline-block")*/;
      $("#AngleUp" + id1 + "> svg").hide();
    }
    lock=false;
  }

  AddItem = (parentName, id, sort) => {
    const { currentResume } = this.props;
    let newList = '';
    switch (parentName) {
      case "EmploymentHistory":
        newList = currentResume.employmentHistory;
        newList.push(
          {
            id: id,
            jobTitle: "",
            employer: "",
            startDate: "",
            endDate: "",
            city: "",
            description: "",
            type: parentName,
            sort: sort
          }
        );
        break;
      case "Internships":
        newList = currentResume.internships;
        newList.push(
          {
            id: id,
            jobTitle: "",
            employer: "",
            startDate: "",
            endDate: "",
            city: "",
            description: "",
            type: parentName,
            sort: sort
          }
        );
        break;
      case "Volunteers":
        newList = currentResume.volunteers;
        newList.push(
          {
            id: id,
            jobTitle: "",
            employer: "",
            startDate: "",
            endDate: "",
            city: "",
            description: "",
            type: parentName,
            sort: sort
          }
        );
        break;
      case "Education":
        newList = currentResume.education;
        newList.push(
          {
            id: id,
            degree: "",
            school: "",
            startDate: "",
            endDate: "",
            city: "",
            description: "",
            type: parentName,
            sort: sort
          }
        );
        break;
      case "Courses":
        newList = currentResume.courses;
        newList.push(
          {
            id: id,
            course: "",
            institution: "",
            startDate: "",
            endDate: "",
            city: "",
            type: parentName,
            sort: sort
          }
        );
        break;
      case "Certificates":
        newList = currentResume.certificates;
        newList.push(
          {
            id: id,
            name: "",
            organization: "",
            startDate: "",
            endDate: "",
            city: "",
            type: parentName,
            sort: sort
          }
        );
        break;
      case "References":
        newList = currentResume.references;
        newList.push(
          {
            id: id,
            name: "",
            company: "",
            phone: "",
            email: "",
            type: parentName,
            sort: sort
          }
        );
        break;
      case "Skills":
        newList = currentResume.skills;
        newList.push(
          {
            id: id,
            skill: "",
            level: "",
            type: parentName,
            sort: sort
          }
        );
        break;
      case "Languages":
        newList = currentResume.languages;
        newList.push(
          {
            id: id,
            language: "",
            level: "",
            type: parentName,
            sort: sort
          }
        );
        break;
      case "Hobbies":
        newList = currentResume.hobbies;
        newList.push(
          {
            id: id,
            title: "",
            type: parentName,
            sort: sort
          }
        );
        break;
      case "Links":
        newList = currentResume.links;
        newList.push(
          {
            id: id,
            label: "",
            link: "",
            type: parentName,
            sort: sort
          }
        );
        break;
    }


    this.props.UpdateList(parentName, newList);
  }

  handleAddItem(parentName) {
    const { currentResume } = this.props;

    let itemsCount = 0;
    switch (parentName) {
      case "EmploymentHistory":
        itemsCount = currentResume.employmentHistory.length;
        break;
      case "Education":
        itemsCount = currentResume.education.length;
        break;
      case "Internships":
        itemsCount = currentResume.internships.length;
        break;
      case "Volunteers":
        itemsCount = currentResume.volunteers.length;
        break;
      case "Certificates":
        itemsCount = currentResume.certificates.length;
        break;
      case "Courses":
        itemsCount = currentResume.courses.length;
        break;
      case "References":
        itemsCount = currentResume.references.length;
        break;
      case "Skills":
        itemsCount = currentResume.skills.length;
        break;
      case "Languages":
        itemsCount = currentResume.languages.length;
        break;
      case "Hobbies":
        itemsCount = currentResume.hobbies.length;
        break;
      case "Links":
        itemsCount = currentResume.links.length;
        break;
    }

    this.setState({
      isloading: true,
      parentName: parentName,
      itemsCount: itemsCount
    });
    this.AddItemOnServer(currentResume.guid, parentName);
  }

  AddItemOnServer = (guid, parentName) => {
    let url = '';
    switch (parentName) {
      case "EmploymentHistory":
        url = _HostnamePre + 'index.php?controller=Resume&method=AddJob';
        break;
      case "Education":
        url = _HostnamePre + 'index.php?controller=Resume&method=AddEducation';
        break;
      case "Internships":
        url = _HostnamePre + 'index.php?controller=Resume&method=AddInternship';
        break;
      case "Volunteers":
        url = _HostnamePre + 'index.php?controller=Resume&method=AddVolunteer';
        break;
      case "Courses":
        url = _HostnamePre + 'index.php?controller=Resume&method=AddCourse';
        break;
      case "Certificates":
        url = _HostnamePre + 'index.php?controller=Resume&method=AddCertificate';
        break;
      case "References":
        url = _HostnamePre + 'index.php?controller=Resume&method=AddReference';
        break;
      case "Skills":
        url = _HostnamePre + 'index.php?controller=Resume&method=AddSkill';
        break;
      case "Languages":
        url = _HostnamePre + 'index.php?controller=Resume&method=AddLanguage';
        break;
      case "Hobbies":
        url = _HostnamePre + 'index.php?controller=Resume&method=AddHobby';
        break;
      case "Links":
        url = _HostnamePre + 'index.php?controller=Resume&method=AddLink';
        break;
    }
    const formData = new FormData();
    formData.append('guid', guid);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    }
    axios.post(url, formData, config)
      .then(({ data }) => {
        this.AddItem(data.viewModel.type, data.viewModel.id, data.viewModel.id);
      })
  }
  componentDidUpdate(prevProps) {
    if (this.state.isloading === true) {
      let parentIndex = -1;


      let focusItem = '';
      const { blockOrder } = this.props.currentResume;
      switch (this.state.parentName) {
        case "EmploymentHistory":
          focusItem = "employmentHistory_jobtitle_";
          parentIndex = blockOrder.indexOf("employmentHistory");
          break;
        case "Internships":
          focusItem = "internship_jobtitle_";
          parentIndex = blockOrder.indexOf("internships");
          break;
        case "Volunteers":
          focusItem = "volunteer_jobtitle_";
          parentIndex = blockOrder.indexOf("volunteers");
          break;
        case "Education":
          focusItem = "education_degree_";
          parentIndex = blockOrder.indexOf("education");
          break;
        case "Courses":
          focusItem = "course_course_";
          parentIndex = blockOrder.indexOf("courses");
          break;
        case "Certificates":
          focusItem = "certificate_name_";
          parentIndex = blockOrder.indexOf("certificates");
          break;
        case "References":
          focusItem = "reference_fullname_";
          parentIndex = blockOrder.indexOf("references");
          break;
        case "Skills":
          focusItem = "skill_skill_";
          parentIndex = blockOrder.indexOf("skills");
          break;
        case "Languages":
          focusItem = "language_language_";
          parentIndex = blockOrder.indexOf("languages");
          break;
        case "Hobbies":
          focusItem = "hobby_title_";
          parentIndex = blockOrder.indexOf("hobbies");
          break;
        case "Links":
          focusItem = "link_label_";
          parentIndex = blockOrder.indexOf("links");
          break;
      }

      if (focusItem !== '' && parentIndex !== -1) {
        var display = $("#AngleDown" + this.state.itemsCount + "" + parentIndex);
        if (display.length && display.length !== 0) {
          if(!lock && (Date.now() - lockTime) > 1019){
            this.ToggleItemDisplay("inner", this.state.itemsCount + "" + parentIndex, true);
            this.focus(focusItem);
          }
        }
      }
    }
  }
  focus = debounce((focusItem) => {
    $("#" + focusItem + this.state.itemsCount).focus();
    if ($("#" + focusItem + this.state.itemsCount).is(":focus")) {
      this.setState({
        isloading: false
      });
    }
  }, 10);

  render() {
    const {
      items,
      parentName,
      parentIndex,
      isSorting,
      shouldUseDragHandle,
      type,
    } = this.props;

    let newParentName = '';
    switch(parentName){
      case 'References': newParentName = 'add_reference'; break;
      case 'Certificates': newParentName = 'add_certificate'; break;
      case 'Courses': newParentName = 'add_course'; break;
      case 'Internships': newParentName = 'add_internship'; break;
      case 'Volunteers': newParentName = 'add_volunteer'; break;
      case 'Education': newParentName = 'add_education'; break;
      case 'Links': newParentName = 'add_link'; break;
      case 'Hobbies': newParentName = 'add_hobby'; break;
      case 'Skills': newParentName = 'add_skill'; break;
      case 'Languages': newParentName = 'add_language'; break;
      case 'EmploymentHistory': newParentName = 'add_employment_history'; break;
    }
    const iconStyle = {
      marginRight: this.props.currentResume.language === 'ar' ? 'unset' : "10px",
      marginLeft: this.props.currentResume.language === 'ar' ? "10px" : 'unset',
      display: "inline-block"
    };

    return (
      <div id={"header" + parentIndex}>
        {items && items.map((item, index) => {

          return (
            <Item
              tabbable
              key={`item-${item.id}`}
              parentName={parentName}
              parentIndex={parentIndex}
              index={index}
              itemIndex={index}

              item={item}


              shouldUseDragHandle={shouldUseDragHandle}
              type={type}
              isSorting={isSorting}
              ToggleItemDisplay={this.ToggleItemDisplay}
            />
          );
        })}
        {this.state.isloading ?
          <div className="add-entry-gray" >
            <FontAwesomeIcon icon={faPlus} style={iconStyle} /> {this.props.currentResume.translation[newParentName]}
          </div>
          :
          <div className="add-entry" onClick={() => this.handleAddItem(parentName)}>
            <FontAwesomeIcon icon={faPlus} style={iconStyle} /> {this.props.currentResume.translation[newParentName]} 
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentResume: state.viewModel.currentResume
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateList: (parentName, newList) => dispatch(UpdateList(parentName, newList)),
  }
}

const SortableList = SortableContainer(BaseSortableList)

export default connect(mapStateToProps, mapDispatchToProps)(SortableList)
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { SortableElement } from 'react-sortable-hoc';
import { showModal } from '../../actions/rootActions'
import ConfirmMessage from '../ConfirmMessage';
import EmploymentHistory from '../EmploymentHistory'
import Education from '../Education'
import Internship from '../Internship'
import Volunteer from '../Volunteer'
import Course from '../Course'
import Certificate from '../Certificate'
import Reference from '../Reference'
import Skill from '../Skill'
import Language from '../Language'
import Hobby from '../Hobby'
import Link from '../Link'
import { UpdateList, DeleteItemFromServer } from '../../actions/resume/generalResumeActions'

export class BaseItem extends Component {
  showConfirmDeleteMessage = (parentName, id) => {
    const title = this.props.currentResume.translation['delete_item'];
    const message = this.props.currentResume.translation['are_you_sure_you_want_to_delete_this_item'];
    const okButtonTitle = this.props.currentResume.translation['delete'];
    const cancelButtonTitle = this.props.currentResume.translation['cancel'];
    this.props.showModal(<ConfirmMessage title={title} message={message} okButtonTitle={okButtonTitle} cancelButtonTitle={cancelButtonTitle} callbackFunction={() => this.DeleteItem(parentName, id)} />, null, false, false);
  }
  DeleteItem = (parentName, id) => {
    const { currentResume } = this.props;


    let newList = '';
    switch (parentName) {
      case "EmploymentHistory":
        newList = currentResume.employmentHistory.filter(job => job.id !== id);
        break;
      case "Education":
        newList = currentResume.education.filter(education => education.id !== id);
        break;
      case "Internships":
        newList = currentResume.internships.filter(internship => internship.id !== id);
        break;
      case "Volunteers":
        newList = currentResume.volunteers.filter(volunteer => volunteer.id !== id);
        break;
      case "Courses":
        newList = currentResume.courses.filter(course => course.id !== id);
        break;
      case "Certificates":
        newList = currentResume.certificates.filter(certificate => certificate.id !== id);
        break;
      case "References":
        newList = currentResume.references.filter(reference => reference.id !== id);
        break;
      case "Skills":
        newList = currentResume.skills.filter(skill => skill.id !== id);
        break;
      case "Languages":
        newList = currentResume.languages.filter(language => language.id !== id);
        break;
      case "Hobbies":
        newList = currentResume.hobbies.filter(hobby => hobby.id !== id);
        break;
      case "Links":
        newList = currentResume.links.filter(link => link.id !== id);
        break;
      default: newList = currentResume.links;
    }

    this.props.UpdateList(parentName, newList);
    this.props.DeleteItemFromServer(currentResume.guid, parentName, id);
  }

  renderSwitch = (parentName, itemIndex, item, parentIndex, isDisabled, shouldUseDragHandle, bodyTabIndex) => {
    switch (parentName) {
      case 'EmploymentHistory':
        return <EmploymentHistory
          index={itemIndex}
          item={item}
          parentIndex={parentIndex}
          parentName={parentName}
          isDisabled={isDisabled}
          shouldUseDragHandle={shouldUseDragHandle}
          bodyTabIndex={bodyTabIndex}
          showConfirmDeleteMessage={this.showConfirmDeleteMessage}
          ToggleItemDisplay={this.props.ToggleItemDisplay} />;
      case 'Internships':
        return <Internship
          index={itemIndex}
          item={item}
          parentIndex={parentIndex}
          parentName={parentName}
          isDisabled={isDisabled}
          shouldUseDragHandle={shouldUseDragHandle}
          bodyTabIndex={bodyTabIndex}
          showConfirmDeleteMessage={this.showConfirmDeleteMessage}
          ToggleItemDisplay={this.props.ToggleItemDisplay} />;
      case 'Volunteers':
        return <Volunteer
          index={itemIndex}
          item={item}
          parentIndex={parentIndex}
          parentName={parentName}
          isDisabled={isDisabled}
          shouldUseDragHandle={shouldUseDragHandle}
          bodyTabIndex={bodyTabIndex}
          showConfirmDeleteMessage={this.showConfirmDeleteMessage}
          ToggleItemDisplay={this.props.ToggleItemDisplay} />;
      case 'Education':
        return <Education
          index={itemIndex}
          item={item}
          parentIndex={parentIndex}
          parentName={parentName}
          isDisabled={isDisabled}
          shouldUseDragHandle={shouldUseDragHandle}
          bodyTabIndex={bodyTabIndex}
          showConfirmDeleteMessage={this.showConfirmDeleteMessage}
          ToggleItemDisplay={this.props.ToggleItemDisplay} />;
      case 'Courses':
        return <Course
          index={itemIndex}
          item={item}
          parentIndex={parentIndex}
          parentName={parentName}
          isDisabled={isDisabled}
          shouldUseDragHandle={shouldUseDragHandle}
          bodyTabIndex={bodyTabIndex}
          showConfirmDeleteMessage={this.showConfirmDeleteMessage}
          ToggleItemDisplay={this.props.ToggleItemDisplay} />;
      case 'Certificates':
        return <Certificate
          index={itemIndex}
          item={item}
          parentIndex={parentIndex}
          parentName={parentName}
          isDisabled={isDisabled}
          shouldUseDragHandle={shouldUseDragHandle}
          bodyTabIndex={bodyTabIndex}
          showConfirmDeleteMessage={this.showConfirmDeleteMessage}
          ToggleItemDisplay={this.props.ToggleItemDisplay} />;
      case 'References':
        return <Reference
          index={itemIndex}
          item={item}
          parentIndex={parentIndex}
          parentName={parentName}
          isDisabled={isDisabled}
          shouldUseDragHandle={shouldUseDragHandle}
          bodyTabIndex={bodyTabIndex}
          showConfirmDeleteMessage={this.showConfirmDeleteMessage}
          ToggleItemDisplay={this.props.ToggleItemDisplay} />;

      case 'Skills':
        return <Skill
          index={itemIndex}
          item={item}
          parentIndex={parentIndex}
          parentName={parentName}
          isDisabled={isDisabled}
          shouldUseDragHandle={shouldUseDragHandle}
          bodyTabIndex={bodyTabIndex}
          showConfirmDeleteMessage={this.showConfirmDeleteMessage}
          ToggleItemDisplay={this.props.ToggleItemDisplay} />;
      case 'Languages':
        return <Language
          index={itemIndex}
          item={item}
          parentIndex={parentIndex}
          parentName={parentName}
          isDisabled={isDisabled}
          shouldUseDragHandle={shouldUseDragHandle}
          bodyTabIndex={bodyTabIndex}
          showConfirmDeleteMessage={this.showConfirmDeleteMessage}
          ToggleItemDisplay={this.props.ToggleItemDisplay} />;
      case 'Hobbies':
        return <Hobby
          index={itemIndex}
          item={item}
          parentIndex={parentIndex}
          parentName={parentName}
          isDisabled={isDisabled}
          shouldUseDragHandle={shouldUseDragHandle}
          bodyTabIndex={bodyTabIndex}
          showConfirmDeleteMessage={this.showConfirmDeleteMessage}
          ToggleItemDisplay={this.props.ToggleItemDisplay} />;
      case 'Links':
        return <Link
          index={itemIndex}
          item={item}
          parentIndex={parentIndex}
          parentName={parentName}
          isDisabled={isDisabled}
          shouldUseDragHandle={shouldUseDragHandle}
          bodyTabIndex={bodyTabIndex}
          showConfirmDeleteMessage={this.showConfirmDeleteMessage}
          ToggleItemDisplay={this.props.ToggleItemDisplay} />;
      default:
        return <React.Fragment></React.Fragment>
    }
  };

  render() {
    const { tabbable, parentIndex, parentName, isDisabled, shouldUseDragHandle, item, itemIndex } = this.props;
    const bodyTabIndex = tabbable && !shouldUseDragHandle ? 0 : -1;
    return (
      this.renderSwitch(parentName, itemIndex, item, parentIndex, isDisabled, shouldUseDragHandle, bodyTabIndex)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentResume: state.viewModel.currentResume
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (content, onHideModal, isTransparent, isFillAvailable) => dispatch(showModal(content, onHideModal, isTransparent, isFillAvailable)),
    DeleteItemFromServer: (guid, parentName, id) => dispatch(DeleteItemFromServer(guid, parentName, id)),
    UpdateList: (parentName, newList) => dispatch(UpdateList(parentName, newList)),
  }
}

const Item = SortableElement(BaseItem)

export default connect(mapStateToProps, mapDispatchToProps)(Item)
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { hideModal } from '../actions/rootActions'

class PageLoading extends Component {
    render() {
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('orientationchange', this.handleResize);
        const { innerWidth, isDarkMode } = this.props;
        const additionalClassName = isDarkMode ? "_dark_mode" : ""
        return (
            innerWidth >= 1020 /*|| this.props.isDarkMode*/ ?
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: window.innerHeight-100 + 'px'
            }}>
                <div className="loader-css" style={{ width: "100px", height: "100px" }}></div>
            </div>
            :
            <div style={{ marginTop: '100px', padding: '0 10px'}}>
                <div className="page-loading-space"></div>
                <div className="page-loading-square">
                    <div className={"pagePdf-animated-background" + additionalClassName} style={{
                        height: '100px', borderRadius: '50px'
                    }}>
                        <div className="pagePdf-btn-divide-left"></div>
                    </div>
                </div>
                <div className="page-loading-space"></div>
                <div className="page-loading-short-item">
                    <div className={"pagePdf-animated-background" + additionalClassName}>
                        <div className="background-masker pagePdf-btn-divide-left" style={{ width: this.props.innerWidth > 654 ? '10px' : '0px'}}></div>
                    </div>
                </div>
                <div className="page-loading-short-item">
                    <div className={"pagePdf-animated-background" + additionalClassName}>
                        <div className="background-masker pagePdf-btn-divide-left" style={{ width: this.props.innerWidth > 654 ? '10px' : '0px'}}></div>
                    </div>
                </div>
                <div className="page-loading-short-item">
                    <div className={"pagePdf-animated-background" + additionalClassName}>
                        <div className="background-masker pagePdf-btn-divide-left" style={{ width: this.props.innerWidth > 654 ? '10px' : '0px'}}></div>
                    </div>
                </div>
                <div className="page-loading-short-item">
                    <div className={"pagePdf-animated-background" + additionalClassName}>
                        <div className="background-masker pagePdf-btn-divide-left" style={{ width: this.props.innerWidth > 654 ? '10px' : '0px'}}></div>
                    </div>
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        modal: state.modal,
        isMobilePreview: state.isMobilePreview,
        innerWidth: state.innerWidth,
        isDarkMode: state.viewModel.auth.isDarkMode,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        hideModal: () =>
            dispatch(hideModal())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageLoading)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { showSpinner } from '../actions/rootActions'
import { updateSkill, updateSkillOnServer } from '../actions/resume/skillActions'
import { debounce } from "lodash";
import 'react-dropzone-uploader/dist/styles.css'
import 'react-quill/dist/quill.snow.css';
import "react-datepicker/dist/react-datepicker.css";
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import { Handle } from './ListWrapper/Handle'
import M from 'materialize-css';
import Select from 'react-select';

class Skill extends Component {
    constructor(props) {
        super(props);
        const options = [
            { value: '20', label: props.currentResume.translation['novice'] },
            { value: '40', label: props.currentResume.translation['beginner'] },
            { value: '60', label: props.currentResume.translation['skillful'] },
            { value: '80', label: props.currentResume.translation['experienced'] },
            { value: '100', label: props.currentResume.translation['expert'] },
        ];
        this.state = {
            id: props.item.id,
            skill: props.item.skill,
            level: options.find(x => x.value === props.item.level),
            sort: props.item.sort,
            isDataLoaded: false
        }
    }
    componentDidMount() {
        let selects = document.querySelectorAll('select');
        M.FormSelect.init(selects, {});
        window.addEventListener('languagechange', () => { alert(5) });
    }
    componentDidUpdate(prevProps) {
        if (!this.state.isDataLoaded) {
            if (this.props.item !== prevProps.item) {
                const options = [
                    { value: '20', label: this.props.currentResume.translation['novice'] },
                    { value: '40', label: this.props.currentResume.translation['beginner'] },
                    { value: '60', label: this.props.currentResume.translation['skillful'] },
                    { value: '80', label: this.props.currentResume.translation['experienced'] },
                    { value: '100', label: this.props.currentResume.translation['expert'] },
                ];
                this.setState({
                    id: this.props.item.id,
                    skill: this.props.item.skill,
                    level: options.find(x => x.value === this.props.item.level),
                    sort: this.props.item.sort,
                    isDataLoaded: true
                });
            }
        }
    }

    handleChange2 = (selectedOption) => {
        this.setState({
            level: selectedOption
        });
        var { id, skill, sort } = this.state;
        this.updateSkill(id, skill, selectedOption.value, sort);

    };

    handleChange = (e) => {
        const { index } = this.props;
        switch (e.target.id) {
            case "skill_skill_" + index:
                this.setState({
                    skill: e.target.value
                });
                var { id, level, sort } = this.state;
                const levelValue = level ? level.value : '';
                this.updateSkill(id, e.target.value, levelValue, sort);
                break;
        }



    }

    updateSkill = (id, skill, level, sort) => {
        const elementsIndex = this.props.currentResume.skills.findIndex(skill => skill.id === id);
        let newList = [...this.props.currentResume.skills];
        newList[elementsIndex] = { id, skill, level, sort, type: "Skill" };

        this.props.updateSkill(newList);
        this.raiseUpdatepersonalDetailsWhenUserStoppedTyping();
    }

    raiseUpdatepersonalDetailsWhenUserStoppedTyping = debounce(() => {
        const { id, skill, level } = this.state;
        const levelValue = level ? level.value : '';
        this.props.updateSkillOnServer(this.props.currentResume.guid, id, skill, levelValue);
        if (!this.state.isShowSpinner) {
            this.props.showSpinner(true);
        }
    }, 1500);
    render() {
        const { skill, level } = this.state;
        const { currentResume, isDarkMode, index, item, parentIndex, isDisabled, shouldUseDragHandle, bodyTabIndex, parentName } = this.props;

        const options = [
            { value: '20', label: currentResume.translation['novice'] },
            { value: '40', label: currentResume.translation['beginner'] },
            { value: '60', label: currentResume.translation['skillful'] },
            { value: '80', label: currentResume.translation['experienced'] },
            { value: '100', label: currentResume.translation['expert'] },
        ];

        const levelValue = this.state.level ? options.find(x => x.value === this.state.level.value) : { value: '', label: currentResume.translation['select_level'] }

        const itemStyle = {
            textAlign: this.props.currentResume.language === 'ar' ? 'right' : 'unset',
            direction: this.props.currentResume.language === 'ar' ? 'rtl' : 'unset'
        }
        return (
            <div className="item" style={itemStyle}>
                <div
                    className="stylizedItem"
                    tabIndex={bodyTabIndex}
                    data-index={index}
                    onClick={() => this.props.innerWidth > 1019 && this.props.ToggleItemDisplay("inner", index + "" + parentIndex)}
                    style={{ cursor: this.props.innerWidth > 1019 ? 'pointer' : 'default' }}
                >
                    {shouldUseDragHandle &&
                        <React.Fragment>
                            <div className="wrapper">
                                <Handle tabIndex={bodyTabIndex} />
                                <div className="middle">
                                    <div className="item-title-edit">{skill || currentResume.translation['not_specified']}</div>
                                    <div className="item-date-edit">{level && level.label}</div>
                                </div>
                            </div>
                            <div className="flex-no-wrape">
                                <button className="trash-icon delete margin-r10" onClick={() => this.props.showConfirmDeleteMessage(parentName, item.id)}>
                                    <div style={{ display: "inline-block" }}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </div>
                                </button>
                                <button className="toggle margin-r10" onClick={() => this.props.innerWidth <= 1019 && this.props.ToggleItemDisplay("inner", index + "" + parentIndex)}>
                                    <div id={"AngleDown" + index + "" + parentIndex} className="ItemAngleDown" style={{ display: "inline-block" }}>
                                        <FontAwesomeIcon icon={faAngleDown} />
                                    </div>
                                    <div id={"AngleUp" + index + parentIndex} className="ItemAngleUp" style={{ display: "none" }}>
                                        <FontAwesomeIcon icon={faAngleUp} />
                                    </div>
                                </button>
                            </div>
                        </React.Fragment>
                    }
                </div>
                <div className="item-inner" id={"inner" + index + parentIndex} style={{ display: "none" }}>
                    <div className="personal-details-field">
                        <div>
                            <div>
                                <div>
                                    <label className="personal-details-title">
                                        <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['skill']}</div>
                                    </label>
                                    <div>
                                        <input type="text" autoComplete="off" value={skill} id={"skill_skill_" + index} onChange={this.handleChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="personal-details-field">
                        <div>
                            <div>
                                <div>
                                    <label className="personal-details-title">
                                        <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['level']}</div>
                                    </label>
                                    <div className="input-field col s12">
                                        <Select
                                            isSearchable={false}
                                            value={levelValue}
                                            onChange={this.handleChange2}
                                            options={options}
                                            placeholder={currentResume.translation['select_level']}
                                            styles={isDarkMode ? {
                                                valueContainer: base => ({
                                                    ...base,
                                                    height: 45,
                                                    display: 'block',
                                                    padding: 11,
                                                    background: isDarkMode ? '#646465' : 'inherit',
                                                }),
                                                singleValue: base => ({
                                                    ...base,
                                                    color: '#e3e3e3'
                                                }),
                                                menuList: base => ({
                                                    ...base,
                                                    background: '#646465',
                                                    color: '#e3e3e3',
                                                }),
                                                option: base => ({
                                                    ...base,
                                                    ":hover": {
                                                        color: '#646465',
                                                        background: '#e3e3e3',
                                                    }
                                                }),
                                            }
                                                :
                                                {
                                                    valueContainer: base => ({
                                                        ...base,
                                                        height: 45,
                                                        display: 'block',
                                                        padding: 11,
                                                        background: isDarkMode ? '#646465' : 'inherit',
                                                    })
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentResume: state.viewModel.currentResume,
        isDarkMode: state.viewModel.auth.isDarkMode,
        innerWidth: state.innerWidth
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateSkill: (newList) =>
            dispatch(updateSkill(newList)),
        updateSkillOnServer: (guid, id, skill, level) =>
            dispatch(updateSkillOnServer(guid, id, skill, level)),
        showSpinner: (isShowSpinner) =>
            dispatch(showSpinner(isShowSpinner)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Skill)
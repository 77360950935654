import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import arrayMove from 'array-move';
import 'react-quill/dist/quill.snow.css';
import { sortOnServer } from '../actions/resume/generalResumeActions'
import { UpdateBlockOrder } from '../actions/resume/resumeListActions'
import { UpdateList } from '../actions/resume/generalResumeActions'

class ListWrapper extends Component {
  state = {
    items: this.props.items,
    isSorting: false,
  };

  static propTypes = {
    items: PropTypes.array,
    className: PropTypes.string,
    itemClass: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    onSortStart: PropTypes.func,
    onSortEnd: PropTypes.func,
    component: PropTypes.func,
    shouldUseDragHandle: PropTypes.bool,
    disabledItems: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    width: 400,
    height: 600,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      this.setState({
        items: this.props.items
      });
    }
  }
  onSortStart = (sortEvent, nativeEvent) => {
    const { onSortStart } = this.props;
    this.setState({ isSorting: true });

    document.body.style.cursor = 'grabbing';

    if (onSortStart) {
      onSortStart(sortEvent, nativeEvent, this.refs.component);
    }
  };

  onSortEnd = (sortEvent, nativeEvent) => {
    const { onSortEnd } = this.props;
    const { oldIndex, newIndex } = sortEvent;
    const { items } = this.state;

    this.setState({
      items: arrayMove(items, oldIndex, newIndex),
      isSorting: false,
    });

    const type = items[0].type;
    if (type) {
      var oldSort = items[oldIndex].sort;
      var newSort = items[newIndex].sort;
      this.sort(type, oldSort, newSort);
      this.props.sortOnServer(this.props.currentResume.guid, type, oldSort, newSort);
    } else {
      this.sort("blocks", oldIndex, newIndex);
      this.props.sortOnServer(this.props.currentResume.guid, "blocks", oldIndex, newIndex);
    }

    document.body.style.cursor = '';

    if (onSortEnd) {
      onSortEnd(sortEvent, nativeEvent, this.refs.component);
    }

  };

  sort = (parentName, oldIndex, newIndex) => {
    if (oldIndex !== newIndex) {
      const { currentResume } = this.props;
      if (parentName === "blocks") {
        var blockOrder = Object.values(currentResume.blockOrder);

        var temp = blockOrder[oldIndex];
        if (oldIndex > newIndex) {
          for (var i = oldIndex - 1; i >= newIndex; i--) {
            blockOrder[i + 1] = blockOrder[i];
          }
          blockOrder[newIndex] = temp;
        } else {
          for (var i = oldIndex + 1; i <= newIndex; i++) {
            blockOrder[i - 1] = blockOrder[i];
          }
          blockOrder[newIndex] = temp;
        }
        this.props.UpdateBlockOrder(blockOrder);

      } else {

        let newList = '';
        switch (parentName) {
          case "EmploymentHistory":
            newList = currentResume.employmentHistory;
            break;
          case "Education":
            newList = currentResume.education;
            break;
          case "Internship":
            newList = currentResume.internships;
            break;
          case "Volunteer":
            newList = currentResume.volunteers;
            break;
          case "Course":
            newList = currentResume.courses;
            break;
          case "Certificate":
            newList = currentResume.certificates;
            break;
          case "Reference":
            newList = currentResume.references;
            break;
          case "Skill":
            newList = currentResume.skills;
            break;
          case "Language":
            newList = currentResume.languages;
            break;
          case "Hobby":
            newList = currentResume.hobbies;
            break;
          case "Link":
            newList = currentResume.links;
            break;
        }

        var temp = newList.find(x => x.sort === oldIndex);
        var list = newList;
        var sorts = new Array();
        var ids = new Array();
        var j = 0;
        for (var i = 0; i < newList.length; i++) {
          if (oldIndex > newIndex && newList[i].sort <= oldIndex && newList[i].sort >= newIndex) {
            sorts[j] = newList[i].sort;
            ids[j++] = newList[i].id;
          }
          if (oldIndex < newIndex && newList[i].sort >= oldIndex && newList[i].sort <= newIndex) {
            sorts[j] = newList[i].sort;
            ids[j++] = newList[i].id;
          }
        }
        if (oldIndex > newIndex) {
          for (var i = sorts.length - 2; i >= 0; i--) {
            list.find(x => x.id === ids[i]).sort = sorts[i + 1];
          }
          list.find(x => x.id === temp.id).sort = sorts[0];
        } else {
          for (var i = 1; i <= sorts.length - 1; i++) {
            list.find(x => x.id === ids[i]).sort = sorts[i - 1];
          }
          list.find(x => x.id === temp.id).sort = sorts[sorts.length - 1];
        }
        list = list.sort((a, b) => (a.sort > b.sort) ? 1 : ((b.sort > a.sort) ? -1 : 0));


        this.props.UpdateList(parentName, list);
      }
    }


  }

  render() {
    const Component = this.props.component;
    const { items, isSorting } = this.state;
    const props = {
      isSorting,
      items,
      onSortEnd: this.onSortEnd,
      onSortStart: this.onSortStart,
      ref: 'component',
      useDragHandle: this.props.shouldUseDragHandle,
    };

    return <Component {...this.props} {...props} />;
  }
}


const mapStateToProps = (state) => {
  return {
    currentResume: state.viewModel.currentResume
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    UpdateList: (parentName, newList) => dispatch(UpdateList(parentName, newList)),
    sortOnServer: (guid, type, id0, id1) => dispatch(sortOnServer(guid, type, id0, id1)),
    UpdateBlockOrder: (blockOrder) => dispatch(UpdateBlockOrder(blockOrder))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListWrapper)
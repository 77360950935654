import React, { Component } from 'react'
import { connect } from 'react-redux'
import { showModal, showSpinner } from '../actions/rootActions'
import { faAngleUp, faAngleDoubleDown, faAngleDoubleUp, faUser, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { updateProfessionalSummary, updateProfessionalSummaryOnServer } from '../actions/resume/resumeSummaryActions'
import { debounce } from "lodash";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ToggleCategoryDisplay } from '../actions/resume/generalResumeActions'
import ControlledAccordions from './ControlledAccordions'

const mock = [
    {
        title: 'High school student',
        body: <>
            <p>
                High school student who is responsible and has a knack for customer service.<br />
                Extensive experience with a variety of point-of-sale systems and price checkers.<br />
                Interested in gaining management experience.
            </p>
        </>,
    },
    {
        title: 'Recently Graduated Student',
        body: <>
            <p>
                Graduate in computer science with expertise working across the front-end of software development. Looking for a position where I can expand my knowledge and expertise while using my skills from previous successfully executed projects.
            </p>
        </>,
    },
    {
        title: 'Marketing / Social media manager',
        body: <>
            <p>
                Marketing manager with over six years of experience implementing and
                developing advertising strategies for businesses and companies. Lately dedicated to adopting new marketing methods through the usage of social media. Looking for a new role where I can apply my passion and dedication and gain more experience and knowledge.
            </p>
        </>,
    },
    {
        title: 'Nurse',
        body: <>
            <p>
                Registered nurse with over ten years of healthcare expertise. Skilled in
                ensuring patient safety and comfort while also keeping relatives updated. Seeking to use healthcare management expertise in your clinic.
            </p>
        </>,
    },
    {
        title: 'Data Analyst',
        body: <>
            <p>
                Data Analyst with three years of experience developing records management
                processes and policies and assisting with reporting processes. Looking to use superior knowledge of company operations and analytical tools.

            </p>
        </>,
    },
    {
        title: 'Customer Service',
        body: <>
            <p>
                Customer service representative with over 12 years of expertise serving
                individual and corporate clients in the short-term insurance market. Excellent organising abilities allow for high-quality service. Maintaining the highest integrity level to maintain consumer and business confidence and security. I'm excited to share my capable team leader, who can both inspire and be inspired by my team.
            </p>
        </>,
    },
    {
        title: 'Finance',
        body: <>
            <p>
                Expert in account management and business development with 5 years’ experience managing digital advertising and banking services for startups in performance marketing and banking.
            </p>
        </>,
    },
    {
        title: 'Teacher',
        body: <>
            <p>
                A sympathetic and kind individual who strives to teach moral and ethical principles in students along with academic knowledge. I have a strong academic background, a broad range of information, as well as critical thinking abilities with language and technical proficiency. One of my hobbies is discovering new places and topics.
            </p>
        </>,
    },
    {
        title: 'Mechanical engineer',
        body: <>
            <p>
                Mechanical engineer having substantial experience and knowledge of technological theories, concepts, standards, and specifications. Dedicated and motivated leader with more than five years of project management experience. Punctual and actionable. Completed complex tasks on schedule. Extensive knowledge of problem-solving techniques and problem analysis. Has extensive knowledge of monitoring manufacturing processes for various units. Exhibited effective teamwork with clients and coworkers.
            </p>
        </>,
    },
]

const professionalSummaryResume = () => {
    return (
        <>
            <p style={{ fontSize: '18px', marginTop: '-30px', marginBottom: '39px', marginRight: '20px' }}><h5><strong>Professional Summary Tips</strong></h5></p>
            <div className='help-modal'>
                <p>
                    A professional summary is a concise list of a few sentences that describe the candidate's relevant work experience, skills, and accomplishments. A professional summary aims to explain your qualifications for the job and persuade the employer to read your entire resume.
                </p>
                <br />
                <div >
                    <p>Things you should include in a Professional Summary:</p>
                    <p>
                        <ul className='ul-list'>
                            <li>
                                The summary should include information about your profession, qualifications, and
                                the type of job you're applying for.
                            </li>
                        </ul>
                        <ul className='ul-list'>
                            <li>
                                You should emphasise your most relevant strengths, professional skills, and critical
                                competencies uniquely describing you as a candidate.
                            </li>
                        </ul>
                    </p>
                    <br />
                    <h6><strong>Professional Summary Examples:</strong></h6>
                    <br />
                    <ControlledAccordions data={mock} />
                </div>
            </div>
        </>
    )
}
const professionalSummaryCoverLetter = () => {
    return (
        <>
            <p style={{ fontSize: '18px', marginTop: '-30px', marginBottom: '39px', marginRight: '20px' }}><h5><strong>Professional Letter Tips</strong></h5></p>
            <div className='help-modal'>
                <p>
                    The cover letter demonstrates your potential to benefit the business and excel in your chosen field. Your cover letter should be adapted for the company and the position you're applying for. It should explain why you want to work for this company and emphasise how you fit with the team and the role.
                </p>
                <br />
                <div >
                    <p>In the cover letter you should include:</p>
                    <p>
                        <ul className='ul-list'>
                            <li>
                                Your contact information. Include your name, email-address and phone number. Including your address is optional.
                            </li>
                            <li>
                                A heading section where you let the employer know what position you are applying for.
                                Create a compelling opening sentence. You can mention the reason you want to work for this specific company, what you have to contribute to the position you are applying for and why are you the right person to this position.
                            </li>
                            <li>
                                Add a brief ending paragraph to complete and finish your letter with your signature.
                            </li>
                        </ul>
                    </p>
                </div>
            </div>
        </>
    )
}

class ProfessionalSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            professionalSummary: props.currentResume.professionalSummary,
            showOrginalCanvus: props.preview.showOrginalCanvus,
            src1: props.preview.src1,
            src2: props.preview.src2,
            isShowSpinner: props.preview.isShowSpinner,
            isDataLoaded: false,
            isCssAddedToIframe: false
        }
    }
    modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'], [{ 'list': 'ordered' }, { 'list': 'bullet' }], ['link'], ['clean']
        ],
        clipboard: {
            matchVisual: false
        }
    }

    formats = [
        'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'link'
    ]
    componentDidUpdate(prevProps) {
        if (!this.state.isDataLoaded) {
            if (this.props.currentResume.professionalSummary !== prevProps.currentResume.professionalSummary) {
                this.setState({
                    professionalSummary: this.props.currentResume.professionalSummary,
                    isDataLoaded: true
                });
            }
        }
    }

    handleChange = (professionalSummary) => {
        this.setState({
            professionalSummary: professionalSummary
        })
        if (professionalSummary !== this.props.currentResume.professionalSummary) {
            this.props.updateProfessionalSummary(this.props.currentResume.guid, professionalSummary);
            this.raiseUpdateProfessionalSummaryWhenUserStoppedTyping();
        }
    }
    raiseUpdateProfessionalSummaryWhenUserStoppedTyping = debounce(() => {
        const { professionalSummary } = this.state;
        this.props.updateProfessionalSummaryOnServer(this.props.currentResume.guid, professionalSummary);
        if (!this.state.isShowSpinner) {
            this.props.showSpinner(true);
        }
    }, 1500);

    render() {
        const { professionalSummary } = this.state;
        const { isDarkMode, currentResume } = this.props;
        const middle = {
            marginLeft: currentResume.language === 'ar' ? 'unset' : "10px",
            marginRight: currentResume.language === 'ar' ? "10px" : 'unset',
        };
        const icon = {
            marginRight: currentResume.language === 'ar' ? 'unset' : "10px",
            marginLeft: currentResume.language === 'ar' ? "10px" : 'unset',
        };

        const categoryStyle = {
            textAlign: this.props.currentResume.language === 'ar' ? 'right' : 'unset',
            direction: this.props.currentResume.language === 'ar' ? 'rtl' : 'unset'
        }

        return (
            <div id="professionalSummary" className="category" style={categoryStyle}>
                <div className="categoryHeader" style={{ cursor: this.props.innerWidth > 1019 ? 'pointer' : 'default' }} onClick={() => this.props.innerWidth > 1019 && this.props.ToggleCategoryDisplay("header", "001")}>
                    <div>
                        <span className="middle" style={middle}><div className={(isDarkMode) ? "block-title title_dark_mode" : "block-title"}>
                            <FontAwesomeIcon icon={faUser} style={icon} />
                            {
                                this.props.isCoverLetter ?
                                    currentResume.translation['professional_letter']
                                    :
                                    currentResume.translation['professional_summary']
                            }
                        </div></span>
                    </div>
                    <div>
                        <button className="help" onClick={(e) => {
                            e.stopPropagation(); this.props.showModal(<div>
                                <div style={{ margin: '20px 0px' }}>
                                    <label className="personal-details-title">
                                        <div className={(isDarkMode) ? "title_dark_mode" : ""}>
                                            {this.props.isCoverLetter ? professionalSummaryCoverLetter() : professionalSummaryResume()}
                                        </div>
                                    </label>
                                </div>
                            </div>, null, false, false);
                        }}>
                            <div style={{ display: "inline-block" }}>
                                <FontAwesomeIcon icon={faQuestionCircle} style={{ display: "inline-block" }} />
                            </div>
                        </button>
                        <button className="toggle toggle-all" onClick={(e) => { e.stopPropagation(); this.props.ToggleCategoryDisplay("allHeader", "001") }}>
                            <div id="AngleDownAll001" style={{ display: "none" }}>
                                <FontAwesomeIcon icon={faAngleDoubleDown} style={{ display: "none" }} />
                            </div>
                            <div id="AngleUpAll001" style={{ display: "inline-block" }}>
                                <FontAwesomeIcon icon={faAngleDoubleUp} style={{ display: "inline-block" }} />
                            </div>
                        </button>
                        <button className="toggle" onClick={() => this.props.innerWidth <= 1019 && this.props.ToggleCategoryDisplay("header", "001")}>
                            <div id="AngleDown001" style={{ display: "none" }}>
                                <FontAwesomeIcon icon={faAngleDown} style={{ display: "none" }} />
                            </div>
                            <div id="AngleUp001" style={{ display: "inline-block" }}>
                                <FontAwesomeIcon icon={faAngleUp} style={{ display: "inline-block" }} />
                            </div>
                        </button>
                    </div>
                </div>
                {
                    <div className="categoryContainer" >
                        <div id="header001">
                            {this.props.currentResume.professionalSummary !== null &&
                                <>
                                    <ReactQuill value={professionalSummary}
                                        theme="snow"
                                        modules={this.modules}
                                        formats={this.formats}
                                        onChange={this.handleChange}
                                    />
                                </>
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        currentResume: state.viewModel.currentResume,
        preview: state.preview,
        isDarkMode: state.viewModel.auth.isDarkMode,
        innerWidth: state.innerWidth
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        showModal: (content, onHideModal, isTransparent, isFillAvailable) => dispatch(showModal(content, onHideModal, isTransparent, isFillAvailable)),
        updateProfessionalSummary: (guid, summary) =>
            dispatch(updateProfessionalSummary(guid, summary)),
        updateProfessionalSummaryOnServer: (guid, summary) =>
            dispatch(updateProfessionalSummaryOnServer(guid, summary)),
        showSpinner: (isShowSpinner) =>
            dispatch(showSpinner(isShowSpinner)),
        ToggleCategoryDisplay: (id0, id1) => dispatch(ToggleCategoryDisplay(id0, id1))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalSummary)
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {_ShareHostname} from '../Const'


class CropContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCopied: false,
            guid: props.guid
        };
    }
    copy = () =>{
        this.setState({
            isCopied: true  
        });
    }
    render() {
        var guid = (this.state.guid==null) ? this.props.currentResume.guid: this.state.guid;
        return (
            <div className="share-resume-modal">
                <div className="modal-title-container">
                    <div className="modal-title">Share your resume</div>
                </div>
                <div>
                    <p>This is the permanent url for your resume:</p>
                    <p>Copy this url and share it with your recruiters, managers, etc...</p>
                    <input type="text" value={_ShareHostname + "/?id=" + guid} readOnly />
                    
                    {this.state.isCopied ? 
                        <div className="clipboard-copied" > Copied.</div> 
                    : 
                        <CopyToClipboard text={_ShareHostname + "/?id=" + guid} onCopy={this.copy}>
                            <button className="copy-to-clipboard-button">Copy to clipboard</button>
                        </CopyToClipboard>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentResume: state.viewModel.currentResume,
    }
}

export default connect(mapStateToProps)(CropContent)
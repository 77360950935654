import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

class PlusButton extends Component {
    render() {
        const { onClick, isShowLoadingCreateResume } = this.props;
        return (
            <div className="plus-button">
                {(isShowLoadingCreateResume) ?
                    <div>
                        <div className="loader-css" style={{ width: "100px", height: "100px" }}></div>
                    </div>
                :
                    <div onClick={onClick}>
                        <FontAwesomeIcon icon={faPlusCircle} />
                    </div>
                }
                
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        isShowLoadingCreateResume: state.isShowLoadingCreateResume,
        isDarkMode: state.viewModel.auth.isDarkMode,
    }
}

export default connect(mapStateToProps)(PlusButton)
import React, { Component } from 'react';
import { connect } from 'react-redux'
import './NewtonTemplateCoverLetter.css';
import Parser from 'html-react-parser';
import { _HostnamePre } from '../../../Const';

function calculateTextColor(color, keepOrginal = false) {
  if (color) {
    var c = color.replace('#', '');
    var rgb = [];
    rgb[0] = parseInt(c.substr(0, 2), 16);
    rgb[1] = parseInt(c.substr(2, 2), 16);
    rgb[2] = parseInt(c.substr(4, 2), 16);
    if (rgb[0] + rgb[1] + rgb[2] < 382) {
      return keepOrginal ? color : '#fff';
    } else {
      return '#444';
    }
  }
  return color;
}

class NewtonTemplateCoverLetter extends Component {
  getDateObject = (startDate, endDate) => {
    const monthNames = this.props.currentResume.translation['longMonthNames'];
    var startDate0 = new Date(parseInt(startDate, 0));
    if (endDate === "0" || endDate === "NaN") {
      endDate = NaN;
    }
    var endDate0 = new Date(parseInt(endDate, 0));
    return {
      startMonthName: monthNames[startDate0.getMonth()],
      startYear: startDate0.getFullYear(),
      endMonthName: monthNames[endDate0.getMonth()],
      endYear: endDate0.getFullYear()
    }
  }

  getCompanyAddress = (address) => {
    let result = '';
    let coma = '';
    result += address && address?.companyAddress !== "" ? address?.companyAddress : '';
    coma = result !== "" ? ', ' : '';

    result += address && address?.companyPostalCode !== "" ? coma + address?.companyPostalCode : '';
    coma = result !== "" ? ', ' : '';

    result += address && address?.companyCity !== "" ? coma + address?.companyCity : '';
    return (
      <div>
        {result}
      </div>
    );
  }
  getHiringManager = (address) => {
    let result = '';
    let coma = '';
    result += address && address?.hiringManagerName !== "" ? address?.hiringManagerName : '';
    coma = result !== "" ? ', ' : '';

    result += address && address?.hiringManagerRoleName !== "" ? coma + address?.hiringManagerRoleName : '';
    return (
      <div>
        {result}
      </div>
    );
  }

  render() {

    const { currentResume } = this.props;
    const { firstName, lastName, jobTitle, croppedProfilePicture, mobile, email, address } = currentResume.personalDetails;
    const { professionalSummary } = currentResume;


    const street = currentResume.personalDetails.address.street;
    var comma = street === "" ? "" : ", ";
    const postalCode = currentResume.personalDetails.address.postalCode ? comma + currentResume.personalDetails.address.postalCode : "";
    comma = postalCode === "" && comma === "" ? "" : ", ";
    const city = currentResume.personalDetails.address.city ? comma + currentResume.personalDetails.address.city : "";
    comma = city === "" && comma === "" ? "" : ", ";
    const country = currentResume.personalDetails.address.country ? comma + currentResume.personalDetails.address.country : "";

    let mainBlocks = "";
    let sideBlocks = "";
    if (currentResume.blockOrder) {
      const blockOrder = Object.values(currentResume.blockOrder);
    }
    var newtonCoverLetterHeaderClassName = "newtonCoverLetter-header-empty";
    if (croppedProfilePicture && croppedProfilePicture.profilePicture !== null && croppedProfilePicture.profilePicture !== "" && currentResume.hidePicture !== true) {
      newtonCoverLetterHeaderClassName = "newtonCoverLetter-header";
    }

    const sideStyle = {
      marginRight: currentResume.language === 'ar' ? 'unset' : '0px',
      marginLeft: currentResume.language === 'ar' ? '0px' : 'unset',
      float: currentResume.language === 'ar' ? 'right' : 'left',
    }

    const sum = firstName.length + lastName.length;
    let newtonCoverLetterFullNameStyle = '';
    let newtonCoverLetterFirstNameStyle = '';
    let newtonCoverLetterLastNameStyle = '';
    if (croppedProfilePicture && croppedProfilePicture.profilePicture !== null && croppedProfilePicture.profilePicture !== "" && currentResume.hidePicture !== true) {
      if (sum > 17) {
        newtonCoverLetterFirstNameStyle = {
          fontSize: (currentResume.fontSize * 1.7),
          lineHeight: '1.5'
        }
        newtonCoverLetterLastNameStyle = {
          fontSize: (currentResume.fontSize * 1.7),
          lineHeight: '1.2'
        }
      } else {
        newtonCoverLetterFullNameStyle = {
          marginTop: 7,
          fontSize: (currentResume.fontSize * 2),
          lineHeight: '1.2'
        }
      }
    } else {
      newtonCoverLetterFullNameStyle = {
        marginTop: 17,
        fontSize: (currentResume.fontSize * 1.7),
        lineHeight: '1.2'
      }
    }

    const withImage = croppedProfilePicture && croppedProfilePicture.profilePicture !== null && croppedProfilePicture.profilePicture !== "" && currentResume.hidePicture !== true;


    let max = firstName.length;
    let marginTop = -8;
    let marginLeft = withImage ? 41 : -38;
    let jobTitleLeft = withImage ? 28 : -46;
    let style1 = '';
    if (max < lastName.length) {
      max = lastName.length;
    }
    if (max > 10) {
      marginTop = -8;
      marginLeft = withImage ? 41 : -38;
      jobTitleLeft = withImage ? 28 : -46;
      style1 = {
        top: '60px',
        fontSize: (currentResume.fontSize * 1),
      };
    } else if (max > 9) {
      marginTop = -8;
      marginLeft = withImage ? 41 : -38;
      jobTitleLeft = withImage ? 28 : -46;
      style1 = {
        top: '60px',
        fontSize: (currentResume.fontSize * 1.3),
      };
    } else if (max > 8) {
      marginTop = 24;
      marginLeft = withImage ? 29 : -54;
      jobTitleLeft = withImage ? 10 : -56;
      style1 = {
        top: '60px',
        fontSize: (currentResume.fontSize * 1.5),
      };
    } else if (max > 7) {
      marginTop = 8;
      marginLeft = withImage ? 25 : -63;
      jobTitleLeft = withImage ? 28 : -75;
      style1 = {
        top: '45px',
        fontSize: (currentResume.fontSize * 2),
      };
    } else if (max > 6) {
      marginTop = -8;
      marginLeft = withImage ? 34 : -62;
      jobTitleLeft = withImage ? 18 : -70;
      style1 = {
        top: '30px',
        fontSize: (currentResume.fontSize * 2.5),
      };
    }
    else if (max > 5) {
      marginTop = -8;
      marginLeft = withImage ? 55 : -40;
      jobTitleLeft = withImage ? 38 : -50;
      style1 = {
        top: '30px',
        fontSize: (currentResume.fontSize * 2.5),
      };
    } else {
      marginTop = -8;
      marginLeft = withImage ? 80 : -7;
      jobTitleLeft = withImage ? 68 : -10;
      style1 = {
        top: '30px',
        fontSize: (currentResume.fontSize * 2.5),
      };
    }


    return (
      this.props.currentTemplate !== "Newton" ? "" :
        <div className="newtonCoverLetter A4" style={{
          marginBottom: this.props.isMobilePreview && 0,
          boxShadow: this.props.isMobilePreview && 'none',
          lineHeight: currentResume.lineSpace,
          fontSize: currentResume.fontSize+"px"
        }}>
          <div className="main-background"></div>
          <div className="side-background" style={{ backgroundColor: currentResume.color }}></div>
          <div className="side" style={sideStyle} >
            <div className="side-container">
              <br />
              {(street !== "" || postalCode !== "" || city !== "" || country !== "") &&
                <div className="details-item" style={{ color: calculateTextColor(currentResume.color) }}>{street}{postalCode}{city}{country}</div>
              }
              {mobile !== "" && <div className="details-item" style={{ color: calculateTextColor(currentResume.color) }}>{mobile}</div>}
              {email !== "" && <div className="details-item" style={{ color: calculateTextColor(currentResume.color) }}>{email}</div>}

            </div>
          </div>
          <div style={{ backgroundColor: currentResume.color }} className={withImage ? "fullnameAndProfessionalTitleSide2" : "fullnameAndProfessionalTitleSide2-no-image"} >
          </div>
          <div style={{ backgroundColor: currentResume.color }} className={withImage ? "fullnameAndProfessionalTitleSide" : "fullnameAndProfessionalTitleSide-no-image"} >
          </div>
          <div className={newtonCoverLetterHeaderClassName} style={{ color: calculateTextColor(currentResume.color, true) }}>

            <div style={{ marginTop: marginTop, marginLeft: marginLeft }}>
              <div className="newtonCoverLetter-full-name" style={style1}>{firstName}</div>
              <div className="newtonCoverLetter-full-name" style={style1} >{lastName}</div>
            </div>

            {jobTitle !== "" && <div className="newtonCoverLetter-job-title" style={{fontSize: (currentResume.fontSize * 0.9), color: '#444', marginLeft: jobTitleLeft }}>{jobTitle}</div>}
          </div>
          {croppedProfilePicture && croppedProfilePicture.profilePicture !== null && croppedProfilePicture.profilePicture !== "" && currentResume.hidePicture !== true &&
            <React.Fragment>
              <div className="newtonCoverLetter-profile-picture-container">
                <img className="newtonCoverLetter-profile-picture" src={croppedProfilePicture.profilePicture.includes('data:image/') ? croppedProfilePicture.profilePicture : _HostnamePre + croppedProfilePicture.profilePicture} />
              </div>
            </React.Fragment>
          }
          <div className="main-content">
            <React.Fragment>
              <div className="details-item">
                <br />
                {/* {
                  new Date().getDate() + " " + currentResume.translation.shortMonthNames[new Date().getMonth()] + " " + new Date().getFullYear()
                }
                <br /><br /> */}
                {this.getCompanyAddress(address)}
                {this.getHiringManager(address)}
              </div>
              <br /><br />
              {
                professionalSummary !== "" && professionalSummary !== "<p><br></p>" &&
                Parser(professionalSummary)}
            </React.Fragment>
            {mainBlocks}
          </div>
        </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    currentResume: state.viewModel.currentResume,
    scrollTop: state.scrollTop,
    isMobilePreview: state.isMobilePreview,
  }
}

export default connect(mapStateToProps)(NewtonTemplateCoverLetter)
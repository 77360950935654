import axios from 'axios'
import {_HostnamePre} from '../../Const'

export const GetResumeList = () => {
  return (dispatch) => {
    axios.get(_HostnamePre+'index.php?controller=Resume&method=GetResumeList', {
      headers: {
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    })
      .then(function ({ data }) {
        dispatch({
          type: 'UPDATE_LIST',
          data: data
        });
      })
  };
}

export const GetResume = (guid) => {
  return (dispatch) => {
    axios.get(_HostnamePre +'index.php?controller=Resume&method=LoadResume&guid=' + guid, {
      headers: {
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    })
      .then(function ({ data }) {
        dispatch({
          type: 'LOAD_RESUME',
          data: data
        });
      })
  };
}

export const ClearResume = (guid) => {
  return (dispatch) => {
    dispatch({
      type: 'CLEAR_RESUME'
    });
  };
}

export const DeleteResume = (guid) => {
  return (dispatch) => {
    axios.get(_HostnamePre +'index.php?controller=Resume&method=DeleteResume&guid=' + guid, {
      headers: {
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    })
      .then(function ({ data }) {
        dispatch({
          type: 'UPDATE_LIST',
          data: data
        });
      })
  };
}

export const changeTemplate = (guid, name) => {  
  return (dispatch) => {
    dispatch({
      type: 'CHANGE_TEMPLATE',
      name: name
    });
  };
}

export const changeTemplateOnServer = (guid, name) => {
  return (dispatch) => {
    axios.get(_HostnamePre +'index.php?controller=Resume&method=ChangeTemplate&guid=' + guid + '&template=' + name, {
      headers: {
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    })
  };
}


export const ChangeColorOnServer = (guid, color) => {
  return (dispatch) => {
    axios.get(_HostnamePre +'index.php?controller=Resume&method=ChangeColor&guid=' + guid + '&color=' + color, {
      headers: {
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    })
  };
}

export const ChangeLineSpaceOnServer = (guid, lineSpace) => {
  return (dispatch) => {
    axios.get(_HostnamePre +'index.php?controller=Resume&method=ChangeLineSpace&guid=' + guid + '&lineSpace=' + lineSpace, {
      headers: {
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    })
  };
}


export const ChangeFontSizeOnServer = (guid, fontSize) => {
  return (dispatch) => {
    axios.get(_HostnamePre +'index.php?controller=Resume&method=ChangeFontSize&guid=' + guid + '&fontSize=' + fontSize, {
      headers: {
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    })
  };
}



export const closeResume = (guid) => {
  return (dispatch) => {
    axios.get(_HostnamePre +'index.php?controller=Resume&method=CloseResume&guid=' + guid , {
      headers: {
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    })
    .then(function ({ data }) {
      dispatch({
        type: 'UPDATE_LIST',
        data: data
      });
    })
  };
}

export const ChangeColor = (guid, color) => {
  return (dispatch) => {

    dispatch({
      type: 'CHANGE_COLOR',
      color: color
    });
  }
}

export const ChangeLineSpace = (guid, lineSpace) => {
  return (dispatch) => {

    dispatch({
      type: 'CHANGE_LINE_SPACE',
      lineSpace: lineSpace
    });
  }
}

export const ChangeFontSize = (guid, fontSize) => {
  return (dispatch) => {

    dispatch({
      type: 'CHANGE_FONT_SIZE',
      fontSize: fontSize
    });
  }
}

export const ChangeFont = (guid, font) => {
  return (dispatch) => {
    dispatch({
      type: 'CHANGE_FONT',
      font: font
    });
  };
}

export const ChangeFontOnServer = (guid, font) => {
  return (dispatch) => {
    axios.get(_HostnamePre +'index.php?controller=Resume&method=ChangeFont&guid=' + guid + '&font=' + font, {
      headers: {
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    })
  };
}

export const ToggleIconsVisibility = (guid, isHideIcons) => {
  return (dispatch) => {
    axios.get(_HostnamePre +'index.php?controller=Resume&method=ToggleIconsVisibility&guid=' + guid + '&isHideIcons=' + isHideIcons, {
      headers: {
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    })
      .then(function ({ data }) {
        dispatch({
          type: 'LOAD_RESUME',
          data: data
        });
      })
  };
}

export function UpdateBlockOrder(blockOrder) {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_BLOCK_ORDER_LIST',
      blockOrder: blockOrder
    });
  };
}

export function UpdateBlockOrderOnServer(guid, blockOrder) {
  return function (dispatch) {
    const formData = new FormData();
    formData.append('guid', guid);
    formData.append('blockOrder', blockOrder);
    axios.post(_HostnamePre +"index.php?controller=Resume&method=UpdateBlockOrder", formData, {
      headers: {
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    })
  };
}
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { login, isLoggedIn, loggin } from '../actions/auth/authActions'
import Navbar from './Navbar';
import jwt_decode from "jwt-decode";
import Recaptcha from 'react-google-invisible-recaptcha';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.auth,
      identification: '',
      password: '',
      isDarkMode: localStorage.getItem("isDarkMode") === "true",
      recaptchaSuccess: false,
      loginSent: false,
      recaptchaGenerated: ''
    }
  }

  loadScript = (src) =>
    new Promise((resolve, reject) => {
      if (document.querySelector(`script[src="${src}"]`)) return resolve()
      const script = document.createElement('script')
      script.src = src
      script.onload = () => resolve()
      script.onerror = (err) => reject(err)
      document.body.appendChild(script)
    })

  onResolved = () => {
    this.setState({
      recaptchaSuccess: true
    });
  }

  componentDidUpdate() {
    if (this.state.recaptchaSuccess === true && this.state.loginSent === false) {
      const captchaToken = this.recaptcha.getResponse();
      if(this.state.recaptchaGenerated !== captchaToken){
        this.setState({
          loginSent: true
        })
        this.setState({
          recaptchaGenerated: captchaToken
        })
        const { identification, password } = this.state;
        this.props.login(captchaToken, identification, password);
      }
    }
  }

  componentDidMount() {
    //document.body.style.backgroundColor = this.state.isDarkMode ? "#303030" : "#fff";
    const src = 'https://accounts.google.com/gsi/client'
    const id = '230298710115-3lr50bq8s07gi4poco0ug5v1kkhfr5m9.apps.googleusercontent.com'
    this.loadScript(src)
      .then(() => {
        /*global google*/
        google.accounts.id.initialize({
          client_id: id,
          callback: (response) => {
            const responsePayload = jwt_decode(response.credential);

            this.props.loggin(
              responsePayload.sub,
              responsePayload.name,
              responsePayload.given_name,
              responsePayload.family_name,
              responsePayload.picture,
              responsePayload.email,
              this.props.auth.visitorToken)


            // console.log("ID: " + responsePayload.sub);
            // console.log('Full Name: ' + responsePayload.name);
            // console.log('Given Name: ' + responsePayload.given_name);
            // console.log('Family Name: ' + responsePayload.family_name);
            // console.log("Image URL: " + responsePayload.picture);
            // console.log("Email: " + responsePayload.email);

          },
        })

        google.accounts.id.renderButton(
          document.getElementById("buttonDiv"), // Ensure the element exist and it is a div to display correcctly
          {
            theme: "outline",
            size: "large",
            width: window.innerWidth > 501 ?
              '350' :
              '250',
            type: "standard",
          }  // Customization attributes
        );
      })
      .catch(console.error)

    // const google = window.google = window.google ? window.google : {}
    // google.accounts.id.initialize({
    //   client_id: '230298710115-3lr50bq8s07gi4poco0ug5v1kkhfr5m9.apps.googleusercontent.com',
    //   callback: (response) => {
    //     const responsePayload = jwt_decode(response.credential);
    //     console.log(responsePayload.email)
    //   }
    // });
  }
  signIn() {
    const src = 'https://accounts.google.com/gsi/client'
    this.loadScript(src)
      .then(() => {
        google.accounts.id.prompt();
      })
  }
  // componentWillMount() {
  //   this.props.isLoggedIn();
  // }

  handelChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  handelLogin = (e) => {
    this.recaptcha.execute();
    e.preventDefault();
    this.setState({
      loginSent: false
    })
  }
  render() {
    const { identification, password, isDarkMode } = this.state;
    const { auth } = this.props;
    if (auth.isLoggedIn) return <Redirect to="/main" />
    if (auth.isLoggedIn === null) {
      return ("");
    } else {

      return (
        <React.Fragment>
          <div className={isDarkMode ? "body-container dark_mode" : "body-container"}>
            <Navbar isShowIframe={false} preview={this.props.isMobilePreview} />
            <div className="container login-container">
              <p style={{
                margin: '20px auto',
                color: '#5494e2',
                fontSize: '18pt',
                fontWeight: '600'
              }}>Log in</p>
              <form onSubmit={this.handelLogin}>
                <div className="personal-details-field" style={{ padding: '5px 0px' }}>
                  <div>
                    <div>
                      <div>
                        <label className="personal-details-title">
                          <div className={(isDarkMode) ? "title_dark_mode" : ""}>email: </div>
                        </label>
                        <div>
                          <input type="email" id="identification" name='identification' autoComplete="off" value={identification} onChange={this.handelChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="personal-details-field" style={{ padding: '5px 0px' }}>
                  <div>
                    <div>
                      <div>
                        <label className="personal-details-title">
                          <div className={(isDarkMode) ? "title_dark_mode" : ""}>password: </div>
                        </label>
                        <div>
                          <input type="password" id="password" name='password' autoComplete="off" value={password} onChange={this.handelChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="personal-details-field" style={{ padding: '5px 0px' }}>
                  <div style={{ display: "flex", alignItems: "flexEnd", justifyContent: "flex-end" }}>
                    <button type="submit" className="crop-button" style={{ width: '-webkit-fill-available', marginTop: '20px' }}>Login</button>
                  </div>
                </div>
                <Recaptcha
                  sitekey={'6LdKITQhAAAAAAXQcdWCwFX7NCNkJ13PsCZYNwz7'}
                  ref={ref => this.recaptcha = ref}
                  onResolved={this.onResolved}
                />
              </form>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', margin: '20px 0px' }}>
                <label className="personal-details-title">
                  <div className={(isDarkMode) ? "title_dark_mode" : ""}>OR</div>
                </label>
              </div>
              <div id="buttonDiv" style={{ marginTop: '20px', overflow: 'hidden', display: 'flex', justifyContent: 'center' }}></div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.viewModel.auth,
    messages: state.messages,
    innerWidth: state.innerWidth,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    login: (captchaToken, identification, password) => dispatch(login(captchaToken, identification, password)),
    isLoggedIn: () => dispatch(isLoggedIn()),
    loggin: (id, fullName, firstName, lastName, imageURL, email, visitorToken) => dispatch(loggin(id, fullName, firstName, lastName, imageURL, email, visitorToken)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { showSpinner } from '../actions/rootActions'
import { updateHobby, updateHobbyOnServer } from '../actions/resume/hobbyActions'
import { debounce } from "lodash";
import 'react-dropzone-uploader/dist/styles.css'
import "react-datepicker/dist/react-datepicker.css";
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { Handle } from './ListWrapper/Handle'


class Hobby extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.item.id,
            title: props.item.title,
            sort: props.item.sort,
            isDataLoaded: false
        }
    }
    componentDidUpdate(prevProps) {
        if (!this.state.isDataLoaded) {
            if (this.props.item !== prevProps.item) {
                this.setState({
                    id: this.props.item.id,
                    title: this.props.item.title,
                    sort: this.props.item.sort,
                    isDataLoaded: true
                });
            }
        }
    }
    handleChange = (e) => {
        const { index } = this.props;
        switch (e.target.id) {
            case "hobby_title_" + index:
                this.setState({
                    title: e.target.value
                });
                var { id, sort } = this.state;
                this.updateHobby(id, e.target.value, sort);
                break;
        }

        
        
    }

    updateHobby = (id, title, sort) => {
        const elementsIndex = this.props.currentResume.hobbies.findIndex(hobby => hobby.id === id);
        let newList = [...this.props.currentResume.hobbies];
        newList[elementsIndex] = {id, title, sort, type: "Hobby"};

        this.props.updateHobby(newList);
        this.raiseUpdatepersonalDetailsWhenUserStoppedTyping();
    }


    raiseUpdatepersonalDetailsWhenUserStoppedTyping = debounce(() => {
        const { id, title } = this.state;
        this.props.updateHobbyOnServer(this.props.currentResume.guid, id, title);
        if (!this.state.isShowSpinner) {
            this.props.showSpinner(true);
        }
    }, 1500);
    render() {
        const { title } = this.state;
        const { currentResume, isDarkMode, index, item, parentIndex, isDisabled, shouldUseDragHandle, bodyTabIndex, parentName } = this.props;

        const itemStyle = {
            textAlign: this.props.currentResume.language === 'ar' ? 'right' : 'unset',
            direction: this.props.currentResume.language === 'ar' ? 'rtl' : 'unset'
        }
        return (
            <div className="item" style={itemStyle}>
                <div
                    className="stylizedItem"
                    tabIndex={bodyTabIndex}
                    data-index={index}
                    onClick={() => this.props.innerWidth > 1019 && this.props.ToggleItemDisplay("inner", index + "" + parentIndex)}
                    style={{cursor: this.props.innerWidth > 1019 ? 'pointer' : 'default'}}
                >
                    {shouldUseDragHandle &&
                        <React.Fragment>
                            <div className="wrapper">
                                <Handle tabIndex={bodyTabIndex} />
                                <div className="middle">
                                    <div className="item-title-edit">{title || currentResume.translation['not_specified']}</div>
                                </div>
                            </div>
                            <div className="flex-no-wrape">
                                <button className="trash-icon delete margin-r10" onClick={() => this.props.showConfirmDeleteMessage(parentName, item.id)}>
                                    <div style={{ display: "inline-block" }}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </div>
                                </button>
                                <button className="toggle margin-r10" onClick={() => this.props.innerWidth <= 1019 && this.props.ToggleItemDisplay("inner", index + "" + parentIndex)}>
                                    <div id={"AngleDown" + index + "" + parentIndex} className="ItemAngleDown" style={{ display: "inline-block" }}>
                                        <FontAwesomeIcon icon={faAngleDown} />
                                    </div>
                                    <div id={"AngleUp" + index + parentIndex} className="ItemAngleUp" style={{ display: "none" }}>
                                        <FontAwesomeIcon icon={faAngleUp} />
                                    </div>
                                </button>
                            </div>
                        </React.Fragment>
                    }
                </div>
                <div className="item-inner" id={"inner" + index + parentIndex} style={{ display: "none" }}>
                    <div className="personal-details-field">
                        <div>
                            <div>
                                <div>
                                    <label className="personal-details-title">
                                        <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['hobby']}</div>
                                    </label>
                                    <div>
                                        <input type="text" autoComplete="off" value={title} id={"hobby_title_" + index} onChange={this.handleChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentResume: state.viewModel.currentResume,
        isDarkMode: state.viewModel.auth.isDarkMode,
        innerWidth: state.innerWidth
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateHobby: (newList) =>
            dispatch(updateHobby(newList)),
        updateHobbyOnServer: (guid, id, title) =>
            dispatch(updateHobbyOnServer(guid, id, title)),
        showSpinner: (isShowSpinner) =>
            dispatch(showSpinner(isShowSpinner)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Hobby)
import React, { Component } from 'react';
import { connect } from 'react-redux'
import '../AnnotationLayer.css';
import { changeComponent, showModal, bruchShouldJumb, setShowTemplates, setDisplayColorPicker } from '../actions/rootActions'
import CropContent from './CropContent';
import { pdfjs } from 'react-pdf';
import { setLTemplatesLeft, toggleTemplates, ZoomIn, ZoomOut } from '../actions/rootActions'
import HtmlDocument from './HtmlDocument';
import TemplateHeaderContainer from './TemplateHeaderContainer'
import { ChangeColor, ChangeColorOnServer, ChangeFontSize, ChangeFontSizeOnServer, ChangeLineSpace, ChangeLineSpaceOnServer} from '../actions/resume/resumeListActions';
import $ from 'jquery';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function slideLeft(isShowEditor) {
    $('.container-edit').css("display", 'block');
    const direction = $('.edit-container').css("direction");
    if (direction === 'ltr') {
      $('.templates.my-slider').removeClass('my-slider-back-ltr');
      $('.templates.my-slider').removeClass('my-slider-rtl');
      $('.templates.my-slider').addClass('my-slider-ltr');
    } else {
      $('.templates.my-slider').removeClass('my-slider-back-rtl');
      $('.templates.my-slider').removeClass('my-slider-ltr');
      $('.templates.my-slider').addClass('my-slider-rtl');
    }
  
    //$('.templates.my-slider').css("min-height", $(window).height() + 'px');
    $('.iconWrapperTemplates > div').removeClass("selected");
    $('.iconWrapperTemplates svg').removeClass("selected");
    $('.iconWrapperTemplates svg').css("color", '#b6b7b7');
    $('#colorsSamples').slideUp(300);
    $('.templatesFooter').slideUp(300);
    $('.embed').css("marginTop", 50 + 'px');
    $('.embedInnerContainer').css("margin-bottom", "0px");
    $('.leftScreenItem').css("margin-bottom", "0px");
  }

class Embed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orginalWidth: 849,
            orginalHeight: 1198,
            display: props.display,
            pdfFile: props.pdfFile,
            copied: false,
        };
    }
    componentDidUpdate(prevProps) {
        if (this.props.pdfFile !== prevProps.pdfFile ||
            this.props.display !== prevProps.display) {
            this.setState({
                pdfFile: this.props.pdfFile,
                display: this.props.display
            });
        }
    }
    onDocumentLoadSuccess = ({ numPages }) => {
        this.props.onDocumentLoadSuccess(numPages);
    }

    zoomIn = () => {
        const { orginalWidth, orginalHeight, embedHeight } = this.state;
        this.props.ZoomIn(orginalWidth, orginalHeight, embedHeight);
    }
    zoomOut = () => {
        this.props.handleResize(true);
    }
    share = () => {
        this.props.showModal(<CropContent />, null, false, false);
    }
    toggleTemplates = () => {
        this.props.toggleTemplates(this.props.mobileViewTemplatesLeft);
    }
    render() {
        const { display } = this.state;
        const { embedWidth, innerWidth } = this.props;
        const transformOrigin = this.props.currentResume.language === 'ar' ? 'top right' : 'top left';
        const checkWidth = !this.props.isShowEditor ? 800 : 1690;
        const checkWidth2 = !this.props.isShowEditor ? 0 : 1020;
        return (
            <React.Fragment>
                <TemplateHeaderContainer
                    setDisplayColorPicker={this.props.setDisplayColorPicker}
                    currentComponent={this.props.currentComponent}
                    currentResume={this.props.currentResume}
                    changeComponent={this.props.changeComponent}
                    ChangeColor={this.props.ChangeColor}
                    ChangeColorOnServer={this.props.ChangeColorOnServer}
                    ChangeLineSpace={this.props.ChangeLineSpace}
                    ChangeLineSpaceOnServer={this.props.ChangeLineSpaceOnServer}
                    ChangeFontSize={this.props.ChangeFontSize}
                    ChangeFontSizeOnServer={this.props.ChangeFontSizeOnServer}
                    isDarkMode={this.props.isDarkMode}
                    isShowEditor={this.props.isShowEditor}
                    innerWidth={this.props.innerWidth}
                    templateList={this.props.templateList}
                />

                <div className="embed"
                    onClick={() => {
                        //if (!this.props.isShowEditor) {
                            slideLeft(this.props.isShowEditor);
                            this.props.setShowTemplates(false);
                        //}
                    }}
                    onMouseEnter={() => {
                        this.props.bruchShouldJumb(true);
                        setTimeout(() => {
                            this.props.bruchShouldJumb(false);
                        }, 1000);
                    }}
                    style={{
                        marginTop: 50,
                        width: embedWidth,
                        display: display ? "block" : "none",
                        transformOrigin: transformOrigin,
                        position: innerWidth > checkWidth ? 'relative' : 'absolute',
                        transform: innerWidth > checkWidth ? "scale(1)" : innerWidth > checkWidth ? "scale(1)" : innerWidth >= checkWidth2 ? "scale(" + (innerWidth / checkWidth) + ")" : "scale(" + (innerWidth / 810) + ")",

                    }}>
                    <div
                        className='embedInnerContainer'
                        style={{
                            display: "flex"
                        }}>
                        <HtmlDocument />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        preview: state.preview,
        currentResume: state.viewModel.currentResume,
        mobileViewTemplatesLeft: state.mobileViewTemplatesLeft,
        isDarkMode: state.viewModel.auth.isDarkMode,
        isZoomedIn: state.isZoomedIn,
        embedWidh: state.embedWidh,
        embedHeight: state.embedHeight,
        tempHeight: state.tempHeight,
        innerWidth: state.innerWidth,
        currentComponent: state.currentComponent,
        templateList: state.templateList,
        isShowEditor: state.isShowEditor
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        showModal: (content, onHideModal, isTransparent, isFillAvailable) =>
            dispatch(showModal(content, onHideModal, isTransparent, isFillAvailable)),
        setLTemplatesLeft: (left) => dispatch(setLTemplatesLeft(left)),
        toggleTemplates: (value) => dispatch(toggleTemplates(value)),
        ZoomIn: (width, height, tempHeight) => dispatch(ZoomIn(width, height, tempHeight)),
        ZoomOut: (height) => dispatch(ZoomOut(height)),
        changeComponent: (currentComponent) => dispatch(changeComponent(currentComponent)),
        bruchShouldJumb: (value) => dispatch(bruchShouldJumb(value)),
        ChangeColor: (guid, color) => dispatch(ChangeColor(guid, color)),
        ChangeColorOnServer: (guid, color) => dispatch(ChangeColorOnServer(guid, color)),
        ChangeLineSpace: (guid, lineSpace) => dispatch(ChangeLineSpace(guid, lineSpace)),
        ChangeLineSpaceOnServer: (guid, lineSpace) => dispatch(ChangeLineSpaceOnServer(guid, lineSpace)),
        ChangeFontSize: (guid, fontSize) => dispatch(ChangeFontSize(guid, fontSize)),
        ChangeFontSizeOnServer: (guid, fontSize) => dispatch(ChangeFontSizeOnServer(guid, fontSize)),
        setShowTemplates: (value) => dispatch(setShowTemplates(value)),
        setDisplayColorPicker: (value) => dispatch(setDisplayColorPicker(value))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Embed)
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { logout } from '../actions/auth/authActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas, faGraduationCap, faBriefcase, faHandsHelping, faUniversity, faCertificate, faTrophy, faLanguage, faHeart, faAnchor, faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { UpdateBlockOrder, UpdateBlockOrderOnServer } from '../actions/resume/resumeListActions'

class BlockButtonList extends Component {
  handelLogout = () => {
    this.props.logout();
  }
  addSection = (sectionName) => {
    const { currentResume } = this.props;
    let blockOrder = currentResume.blockOrder;
    if (blockOrder === null) {
      blockOrder = [];
    }
    var coma = blockOrder && blockOrder.length > 0 ? ", " : "";
    var blockOrderArrayString = `[${blockOrder.map(block => '"' + block + '"') + coma}"${sectionName}"]`;
    blockOrder.push(sectionName);


    this.props.UpdateBlockOrder(blockOrder);
    this.props.UpdateBlockOrderOnServer(this.props.currentResume.guid, blockOrderArrayString);
  }

  render() {
    const { currentResume } = this.props;
    const blockOrder = currentResume.blockOrder;
    // const middle = {
    //   marginLeft: currentResume.language === 'ar' ? 'unset' : "10px",
    //   marginRight: currentResume.language === 'ar' ? "10px" : 'unset',
    // };
    const icon = {
      marginRight: currentResume.language === 'ar' ? 'unset' : "10px",
      marginLeft: currentResume.language === 'ar' ? "10px" : 'unset',
      fontSize: "24px"
    };
    return (
      <div className="block_button_list">
        {(blockOrder && blockOrder.length < 11) &&
          <div className="addSection">{currentResume.translation['add_section']}</div>
        }
        {(blockOrder && !blockOrder.find(block => block === "employmentHistory")) &&
          <div>
            <div className="block_button" onClick={() => this.addSection("employmentHistory")}>
              <FontAwesomeIcon icon={faGlobeAmericas} style={icon} />{currentResume.translation['employment_history']}
            </div>
          </div>
        }
        {(blockOrder && !blockOrder.find(block => block === "education")) &&
          <div>
            <div className="block_button" onClick={() => this.addSection("education")}>
              <FontAwesomeIcon icon={faGraduationCap} style={icon} />{currentResume.translation['education']}
            </div>
          </div>
        }
        {(blockOrder && !blockOrder.find(block => block === "internships")) &&
          <div>
            <div className="block_button" onClick={() => this.addSection("internships")}>
              <FontAwesomeIcon icon={faBriefcase} style={icon} />{currentResume.translation['internships']}
            </div>
          </div>
        }
        {(blockOrder && !blockOrder.find(block => block === "volunteers")) &&
          <div>
            <div className="block_button" onClick={() => this.addSection("volunteers")}>
              <FontAwesomeIcon icon={faHandsHelping} style={icon} />{currentResume.translation['volunteers']}
            </div>
          </div>
        }
        {(blockOrder && !blockOrder.find(block => block === "courses")) &&
          <div>
            <div className="block_button" onClick={() => this.addSection("courses")}>
              <FontAwesomeIcon icon={faUniversity} style={icon} />{currentResume.translation['courses']}
            </div>
          </div>
        }
        {(blockOrder && !blockOrder.find(block => block === "certificates")) &&
          <div>
            <div className="block_button" onClick={() => this.addSection("certificates")}>
              <FontAwesomeIcon icon={faCertificate} style={icon} />{currentResume.translation['certificates']}
            </div>
          </div>
        }
        {(blockOrder && !blockOrder.find(block => block === "references")) &&
          <div>
            <div className="block_button" onClick={() => this.addSection("references")}>
              <FontAwesomeIcon icon={faBullhorn} style={icon} />{currentResume.translation['references']}
            </div>
          </div>
        }
        {(blockOrder && !blockOrder.find(block => block === "skills")) &&
          <div>
            <div className="block_button" onClick={() => this.addSection("skills")}>
              <FontAwesomeIcon icon={faTrophy} style={icon} />{currentResume.translation['skills']}
            </div>
          </div>
        }
        {(blockOrder && !blockOrder.find(block => block === "languages")) &&
          <div>
            <div className="block_button" onClick={() => this.addSection("languages")}>
              <FontAwesomeIcon icon={faLanguage} style={icon} />{currentResume.translation['languages']}
            </div>
          </div>
        }
        {(blockOrder && !blockOrder.find(block => block === "hobbies")) &&
          <div>
            <div className="block_button" onClick={() => this.addSection("hobbies")}>
              <FontAwesomeIcon icon={faHeart} style={icon} />{currentResume.translation['hobbies']}
            </div>
          </div>
        }
        {(blockOrder && !blockOrder.find(block => block === "links")) &&
          <div>
            <div className="block_button" onClick={() => this.addSection("links")}>
              <FontAwesomeIcon icon={faAnchor} style={icon} />{currentResume.translation['links']}
            </div>
          </div>
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.viewModel.auth,
    currentResume: state.viewModel.currentResume,
    messages: state.messages,
    isDarkMode: state.viewModel.auth.isDarkMode,
    leftScreenItemFullWidth: state.leftScreenItemFullWidth
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () =>
      dispatch(logout()),
    UpdateBlockOrder: (blockOrder) => dispatch(UpdateBlockOrder(blockOrder)),
    UpdateBlockOrderOnServer: (guid, blockOrder) => dispatch(UpdateBlockOrderOnServer(guid, blockOrder)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(BlockButtonList)
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import PersonalDetails from './PersonalDetails'
import ProfessionalSummary from './ProfessionalSummary'
import ListWrapper from './ListWrapper'
import { NestedSortableList } from './ListWrapper/NestedSortableList'
import { GetResume, ClearResume, UpdateBlockOrder, UpdateBlockOrderOnServer } from '../actions/resume/resumeListActions'
import { isLoggedIn } from '../actions/auth/authActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setLeftScreenItemFullWidth, setIsMobilePreview, changeComponent, showSpinner, setDisplayColorPicker } from '../actions/rootActions'
import BlockButtonList from './BlockButtonList'
import { UpdateScrollValue, setEmbedWidth, setEmbedHeight, ZoomOut, setInnerWidth } from '../actions/rootActions'
import ResumeTitleAndLanguage from './ResumeTitleAndLanguage';
import Templates from './Templates';
import MobileTemplatesView from './MobileTemplatesView';
import PageLoading from './PageLoading';
import ResumePreview from './ResumePreview'
import { ChangeColor, ChangeColorOnServer } from '../actions/resume/resumeListActions';
import EditButton from './EditButton';
import EditTemplate from './EditTemplate';
import TemplateFooterContainer from './TemplateFooterContainer';
import ColorPicker from './ColorPicker';

class CoverLettersEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDataLoaded: false,
      showTemplates: false,
      showDownloadButton: false,
      downloadButtonLeft: 0,
      isPaneOpen: false,
      isPaneOpenLeft: false,
    }
  }
  componentWillMount() {
    document.documentElement.scrollTop = 0;
    //this.props.isLoggedIn();
    this.props.GetResume(this.props.guid);
    this.props.setIsMobilePreview(false);
    this.props.setLeftScreenItemFullWidth(false);
    this.props.changeComponent('editor');
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('orientationchange', this.handleResize);
  }
  componentDidUpdate(prevProps) {
    if (this.props.auth.isLoggedIn !== prevProps.auth.isLoggedIn) {
      if (!this.props.auth.isLoggedIn) window.location.href = "/"
    }
  }
  showSummary = () => {
    this.props.UpdateBlockOrder([]);
    this.props.UpdateBlockOrderOnServer(this.props.guid, "[]");
  }
  hasVerticalScroll = (node) => {
    if (node === undefined) {
      if (window.innerHeight) {
        return document.body.offsetHeight > window.innerHeight;
      }
      else {
        return document.documentElement.scrollHeight >
          document.documentElement.offsetHeight ||
          document.body.scrollHeight > document.body.offsetHeight;
      }
    }
    else {
      return node.scrollHeight > node.offsetHeight;
    }
  }

  handleChange = (color) => {
    this.setState({ color: color })
    this.props.ChangeColor(this.props.currentResume.guid, color);
  };
  handleClose = (color) => {
    this.setState({ color: color });
    this.props.ChangeColorOnServer(this.props.currentResume.guid, color.substr(1));

  };

  handleResize = () => {
    this.props.setInnerWidth(window.innerWidth);
    if ((window.innerWidth < 1020 && this.props.currentComponent === 'editor') || window.innerWidth > 1020) {
      this.props.setIsMobilePreview(false);
    } else if ((window.innerWidth < 1020 && this.props.currentComponent === 'templates')) {
      this.props.setIsMobilePreview(true);
    } else {
      //this.props.changeComponent('templates')
    }

  }
  getItems = () => {
    const { currentResume } = this.props;
    let items = []
    if (currentResume.blockOrder !== null) {
      const blockOrder = Object.values(currentResume.blockOrder);
      blockOrder.map(block => {
        if (block === "employmentHistory") {
          items.push({
            index: typeof blockOrder.indexOf === "function" ? blockOrder.indexOf("employmentHistory") : "",
            id: "employmentHistory",
            name: "EmploymentHistory",
            title: "Employment History",
            children: currentResume.employmentHistory
          });
        } else if (block === "internships") {
          items.push({
            index: typeof blockOrder.indexOf === "function" ? blockOrder.indexOf("internships") : "",
            id: "internships",
            name: "Internships",
            title: "Internships",
            children: currentResume.internships
          });
        } else if (block === "volunteers") {
          items.push({
            index: typeof blockOrder.indexOf === "function" ? blockOrder.indexOf("volunteers") : "",
            id: "volunteers",
            name: "Volunteers",
            title: "Volunteers",
            children: currentResume.volunteers
          });
        } else if (block === "education") {
          items.push({
            index: typeof blockOrder.indexOf === "function" ? blockOrder.indexOf("education") : "",
            id: "education",
            name: "Education",
            title: "Education",
            children: currentResume.education
          });
        } else if (block === "courses") {
          items.push({
            index: typeof blockOrder.indexOf === "function" ? blockOrder.indexOf("courses") : "",
            id: "courses",
            name: "Courses",
            title: "Courses",
            children: currentResume.courses
          });
        } else if (block === "certificates") {
          items.push({
            index: typeof blockOrder.indexOf === "function" ? blockOrder.indexOf("certificates") : "",
            id: "certificates",
            name: "Certificates",
            title: "Certificates",
            children: currentResume.certificates
          });
        } else if (block === "references") {
          items.push({
            index: typeof blockOrder.indexOf === "function" ? blockOrder.indexOf("references") : "",
            id: "references",
            name: "References",
            title: "References",
            children: currentResume.references
          });
        } else if (block === "skills") {
          items.push({
            index: typeof blockOrder.indexOf === "function" ? blockOrder.indexOf("skills") : "",
            id: "skills",
            name: "Skills",
            title: "Skills",
            children: currentResume.skills
          });
        } else if (block === "languages") {
          items.push({
            index: typeof blockOrder.indexOf === "function" ? blockOrder.indexOf("languages") : "",
            id: "languages",
            name: "Languages",
            title: "Languages",
            children: currentResume.languages
          });
        } else if (block === "hobbies") {
          items.push({
            index: typeof blockOrder.indexOf === "function" ? blockOrder.indexOf("hobbies") : "",
            id: "hobbies",
            name: "Hobbies",
            title: "Hobbies",
            children: currentResume.hobbies
          });
        } else if (block === "links") {
          items.push({
            index: typeof blockOrder.indexOf === "function" ? blockOrder.indexOf("links") : "",
            id: "links",
            name: "Links",
            title: "Links",
            children: currentResume.links
          });
        }
      });
      items.sort(function (a, b) {
        return a.index - b.index;
      });
    }
    return items;
  }

  render() {
    const { currentResume, auth, isMobilePreview, isDarkMode, innerWidth } = this.props;
    let isShowIframe = (auth.isLoggedIn === true && currentResume.guid && innerWidth >= 1020) ? true : false;


    let display = "flex";
    if (!isShowIframe) {
      if (isMobilePreview) {
        display = "flex"
      } else {
        display = "none"
      }
    }

    const editContainerClassName = this.props.currentResume.language === 'ar' ? "edit-container rtl-direction" : "edit-container";

    return (
      this.props.auth.isLoggedIn &&
      <div
        className={editContainerClassName}
        style={{
          display: 'flex',
          width: '100%',
          direction: this.props.currentResume.language === 'ar' ? 'rtl' : 'ltr'
        }}>
        <ColorPicker
          displayColorPicker={this.props.displayColorPicker}
          setDisplayColorPicker={this.props.setDisplayColorPicker}
          currentResume={this.props.currentResume}
          ChangeColor={this.props.ChangeColor}
          ChangeColorOnServer={(color) => this.props.ChangeColorOnServer(this.props.currentResume.guid, color.substr(1))}
          isShowEditor={this.props.isShowEditor}
          innerWidth={this.props.innerWidth}
        />
        <div style={{ display: this.state.isDataLoaded ? 'none' : 'block', width: '100%' }}>
          <div className="body-container">
            <PageLoading />
          </div>
        </div>
        <div className={(isDarkMode) ? "leftScreenItem leftScreenItem_dark_mode" : "leftScreenItem"}
          style={{
            display: this.state.isDataLoaded ? 'block' : 'none',
            width: '100%',
            maxWidth: (isShowIframe) ? "calc(100% - 842px)" : "100%",
          }}>


          {/* {auth.isLoggedIn && currentResume.guid && innerWidth < 1020 && isMobilePreview &&
            <EditButton />
          } */}

          {<Templates />}

          {/* {this.props.currentComponent === 'MobileTemplatesView' && <MobileTemplatesView />} */}
          {
            this.props.currentComponent === 'EditTemplate' &&
            <EditTemplate
              isDarkMode={isDarkMode}
              changeComponent={this.props.changeComponent}
              currentResume={currentResume}
              templateList={this.props.templateList}
              changeColor={this.handleChange}
              handleClose={this.handleClose}
            />
          }


          <div style={{ display: this.props.currentComponent === 'editor' && this.state.isDataLoaded ? 'flex' : 'none', marginTop: 50 }} className={(isDarkMode) ? "background-dark-mode" : ""}>
            <div className="editor">
              {this.state.isDataLoaded && <ResumeTitleAndLanguage />}
              <div className="container container-edit">
                <PersonalDetails resetIframe={this.props.resetIframe} setIsDataLoaded={() => { this.setState({ isDataLoaded: true }) }} isCoverLetter={true}  />

                {this.state.isDataLoaded &&
                  <React.Fragment>
                    <ProfessionalSummary resetIframe={this.props.resetIframe} isCoverLetter={true} />
                    <BlockButtonList />
                  </React.Fragment>
                }
              </div>
            </div>
          </div>


        </div>
        {!this.props.leftScreenItemFullWidth &&
          <div
            onScroll={this.props.UpdateScrollValue}
            className={(isDarkMode) ? "rightScreenItem rightScreenItem_dark_mode" : "rightScreenItem"}
            style={{
              height: window.innerHeight,
              display: this.props.isShowEditor ? display : 'flex',
              width: (isMobilePreview) ? "100%" : "",
              overflowX: 'hidden',
              flexDirection: 'column',
              left: this.props.currentResume.language === 'ar' ? '0' : 'unset',
            }}>

            <ResumePreview
              preview={isMobilePreview}
              handleResize={this.handleResize}
            />
          </div>
        }
        <TemplateFooterContainer
          displayColorPicker={this.props.displayColorPicker}
          innerWidth={this.props.innerWidth}
          templateList={this.props.templateList}
          currentResume={this.props.currentResume}
          isShowEditor={this.props.isShowEditor}
          isDarkMode={this.props.isDarkMode}
        />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  let guid = ownProps.match.params.resume_id;
  return {
    guid: guid,
    currentResume: state.viewModel.currentResume,
    innerWidth: state.innerWidth,
    auth: state.viewModel.auth,
    leftScreenItemFullWidth: state.leftScreenItemFullWidth,
    messages: state.messages,
    isMobilePreview: state.isMobilePreview,
    embedWidth: state.embedWidth,
    isDarkMode: state.viewModel.auth.isDarkMode,
    embedHeight: state.embedHeight,
    currentComponent: state.currentComponent,
    isZoomedIn: state.isZoomedIn,
    templateList: state.templateList,
    isShowEditor: state.isShowEditor,
    displayColorPicker: state.displayColorPicker
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    GetResume: (guid) => dispatch(GetResume(guid)),
    ClearResume: () => dispatch(ClearResume()),
    isLoggedIn: () => dispatch(isLoggedIn()),
    setLeftScreenItemFullWidth: (value) => dispatch(setLeftScreenItemFullWidth(value)),
    setIsMobilePreview: (value) => dispatch(setIsMobilePreview(value)),
    UpdateBlockOrderOnServer: (guid, blockOrder) => dispatch(UpdateBlockOrderOnServer(guid, blockOrder)),
    UpdateBlockOrder: (blockOrder) => dispatch(UpdateBlockOrder(blockOrder)),
    changeComponent: (currentComponent) => dispatch(changeComponent(currentComponent)),


    showSpinner: (isShowSpinner) =>
      dispatch(showSpinner(isShowSpinner)),
    setEmbedWidth: (embedWidth) => dispatch(setEmbedWidth(embedWidth)),
    setEmbedHeight: (embedHeight) => dispatch(setEmbedHeight(embedHeight)),
    ZoomOut: (height) => dispatch(ZoomOut(height)),
    UpdateScrollValue: (e) => dispatch(UpdateScrollValue(e)),
    setInnerWidth: (width) => dispatch(setInnerWidth(width)),
    ChangeColor: (guid, color) => dispatch(ChangeColor(guid, color)),
    ChangeColorOnServer: (guid, color) => dispatch(ChangeColorOnServer(guid, color)),
    setDisplayColorPicker: (value) => dispatch(setDisplayColorPicker(value))

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CoverLettersEditor)
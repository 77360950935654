import React, { Component } from 'react';
import { connect } from 'react-redux'
import { showSpinner } from '../actions/rootActions'
import Embed from './Embed'

class ResumePreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showOrginalCanvus: false,
            pdfFile1: "",
            pdfFile2: "",
            numPages: null,
            pageNumber: 1
        };
    }
    componentDidUpdate(prevProps) {
        if (this.props.currentResume.pdfFile !== prevProps.currentResume.pdfFile) {
            if (this.state.showOrginalCanvus === true) {
                this.setState({
                    pdfFile2: this.props.currentResume.pdfFile
                });
                this.props.showSpinner(true);
            } else {
                this.setState({
                    pdfFile1: this.props.currentResume.pdfFile
                });
                this.props.showSpinner(true);
            }
        }
    }
    onDocumentLoadSuccess1 = (numPages) => {
        this.setState({
            showOrginalCanvus: true,
            numPages: numPages
        });
        this.props.showSpinner(false);
    }
    onDocumentLoadSuccess2 = (numPages) => {
        this.setState({
            showOrginalCanvus: false,
            numPages: numPages
        });
        this.props.showSpinner(false);
    }
    prevPage = () => {
        if (this.state.pageNumber > 1) {
            this.setState({ pageNumber: this.state.pageNumber - 1 });
        }
    }
    nextPage = () => {
        if (this.state.pageNumber < this.state.numPages) {
            this.setState({ pageNumber: this.state.pageNumber + 1 });
        }
    }
    render() {
        const { showOrginalCanvus, pdfFile2, numPages, pageNumber } = this.state;
        const { preview } = this.props;
        return (
            <React.Fragment>
                <Embed 
                    isPreview={preview} 
                    pdfFile={pdfFile2} 
                    display={!showOrginalCanvus} 
                    numPages={numPages} 
                    pageNumber={pageNumber} 
                    onDocumentLoadSuccess={this.onDocumentLoadSuccess2} 
                    onNextPage={this.nextPage} 
                    onPrevPage={this.prevPage} 
                    handleResize = {this.props.handleResize}
                />
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        currentResume: state.viewModel.currentResume
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        showSpinner: (isShowSpinner) =>
            dispatch(showSpinner(isShowSpinner))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ResumePreview)
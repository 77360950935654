import axios from 'axios';
import $ from 'jquery';
import { _HostnamePre } from '../../Const'

export const ToggleCategoryDisplay = (id0, id1) => {
  return (dispatch) => {
    if (id0 === "allHeader") {
      var displayAll = $("#AngleUpAll" + id1).css("display");
      if (displayAll === "none") {
        $(".categoryContainer > :first-child").slideDown("fast");
        $(".toggle > :first-child").hide();
        $(".toggle > :first-child > svg").hide();

        $(".toggle > :last-child").show();
        $(".toggle > :last-child > svg").show();

        $(".toggle-all > :first-child").hide();
        $(".toggle-all > :first-child > svg").hide();

        $(".toggle-all > :last-child").show();
        $(".toggle-all > :last-child > svg").show();
      } else {
        $(".categoryContainer > :first-child").slideUp("fast");
        $(".toggle > :first-child").show();
        $(".toggle > :first-child > svg").show();

        $(".toggle > :last-child").hide();
        $(".toggle > :last-child > svg").hide();

        $(".toggle-all > :first-child").show();
        $(".toggle-all > :first-child > svg").show();

        $(".toggle-all > :last-child").hide();
        $(".toggle-all > :last-child > svg").hide();
      }
    } else {
      var display = $("#" + id0 + id1).css("display");
      if (display === "none") {
        $("#" + id0 + id1).slideDown("fast");
        $("#AngleDown" + id1).hide();
        $("#AngleUp" + id1).show();
        $("#AngleDown" + id1 + "> svg").hide();
        $("#AngleUp" + id1 + "> svg").show();
      } else {
        $("#" + id0 + id1).slideUp("fast");
        $("#AngleDown" + id1).show();
        $("#AngleUp" + id1).hide();
        $("#AngleDown" + id1 + "> svg").show();
        $("#AngleUp" + id1 + "> svg").hide();
      }
    }
  }
}



export const UpdateList = (parentName, newList) => {
  let type = '';
  switch (parentName) {
    case "EmploymentHistory":
      type = 'UPDATE_EMPLOYMENT_HISTORY';
      break;
    case "Education":
      type = 'UPDATE_EDUCATION';
      break;
    case "Internships":
      type = 'UPDATE_INTERNSHIP';
      break;
    case "Volunteers":
      type = 'UPDATE_VOLUNTEER';
      break;
    case "Courses":
      type = 'UPDATE_COURSE';
      break;
    case "Certificates":
      type = 'UPDATE_CERTIFICATE';
      break;
    case "References":
      type = 'UPDATE_REFERENCE';
      break;
    case "Skills":
      type = 'UPDATE_SKILL';
      break;
    case "Languages":
      type = 'UPDATE_LANGUAGE';
      break;
    case "Hobbies":
      type = 'UPDATE_HOBBY';
      break;
    case "Links":
      type = 'UPDATE_LINK';
      break;
  }
  return (dispatch) => {
    dispatch({
      type: type,
      newList: newList
    });
  }
}

export const DeleteItemFromServer = (guid, parentName, id) => {
  return function (dispatch) {
    let url = '';
    switch (parentName) {
      case "EmploymentHistory":
        url = _HostnamePre + 'index.php?controller=Resume&method=DeleteJob';
        break;
      case "Education":
        url = _HostnamePre + 'index.php?controller=Resume&method=DeleteEducation';
        break;
      case "Internships":
        url = _HostnamePre + 'index.php?controller=Resume&method=DeleteInternship';
        break;
      case "Volunteers":
        url = _HostnamePre + 'index.php?controller=Resume&method=DeleteVolunteer';
        break;
      case "Courses":
        url = _HostnamePre + 'index.php?controller=Resume&method=DeleteCourse';
        break;
      case "Certificates":
        url = _HostnamePre + 'index.php?controller=Resume&method=DeleteCertificate';
        break;
      case "References":
        url = _HostnamePre + 'index.php?controller=Resume&method=DeleteReference';
        break;
      case "Skills":
        url = _HostnamePre + 'index.php?controller=Resume&method=DeleteSkill';
        break;
      case "Languages":
        url = _HostnamePre + 'index.php?controller=Resume&method=DeleteLanguage';
        break;
      case "Hobbies":
        url = _HostnamePre + 'index.php?controller=Resume&method=DeleteHobby';
        break;
      case "Links":
        url = _HostnamePre + 'index.php?controller=Resume&method=DeleteLink';
        break;
    }
    const formData = new FormData();
    formData.append('guid', guid);
    formData.append('id', id);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    }
    axios.post(url, formData, config)
    // .then(function ({ data }) {
    //   dispatch({
    //     type: 'LOAD_RESUME',
    //     data: data
    //   });
    // })
  };

}


export const sort = (type, id0, id1) => {
  switch (type) {
    case "EmploymentHistory":

      break;
    case "Education":

      break;
    case "Internships":

      break;
    case "Volunteers":

      break;
    case "Courses":

      break;
    case "Certificates":

      break;
    case "References":

      break;
    case "Skills":

      break;
    case "Languages":

      break;
    case "Hobbies":

      break;
    case "Links":

      break;
  }
  return (dispatch) => {
    dispatch({
      type: 'SORT_ITESM',

    });
  };
}

export const sortOnServer = (guid, type, id0, id1) => {
  return function (dispatch) {
    if (id0 !== id1) {
      let url = _HostnamePre + 'index.php?controller=Resume&method=Sort';
      const formData = new FormData();
      formData.append('guid', guid);
      formData.append('type', type);
      formData.append('id0', id0);
      formData.append('id1', id1);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization': `token ${localStorage.getItem('token')}`
        }
      }

      axios.post(url, formData, config)
    }
  }
}



export function changeLanguage(language) {
  return (dispatch) => {
    dispatch({
      type: 'CHANGE_LANGUAGE',
      language: language
    });
  };
}

export function changeLanguageOnServer(guid, language) {
  return function (dispatch) {
    const formData = new FormData();
    formData.append('guid', guid);
    formData.append('language', language);
    axios.post(_HostnamePre + "index.php?controller=Resume&method=ChangeLanguage", formData, {
      headers: {
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    })
      .then(function ({ data }) {
        dispatch({
          type: 'LOAD_RESUME',
          data: data
        });
      })
  };
}
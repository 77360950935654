import React, { useState, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { ChromePicker } from 'react-color'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

const ColorPickerButton = ({ currentResume, ChangeColor, isDarkMode, setDisplayColorPicker }) => {
  const [color, setColor] = useState(currentResume.color);

  useEffect(() => {
    ChangeColor(currentResume.guid, color);
  }, [color]);

  useEffect(() => {
    setColor(currentResume.color);
  }, [currentResume.color]);

  const handleClick = () => {
    setDisplayColorPicker(true);
  };

  function calculateTextColor(color, keepOrginal = false) {
    if (color) {
      var c = color.replace('#', '');
      var rgb = [];
      rgb[0] = parseInt(c.substr(0, 2), 16);
      rgb[1] = parseInt(c.substr(2, 2), 16);
      rgb[2] = parseInt(c.substr(4, 2), 16);
      if (rgb[0] + rgb[1] + rgb[2] < 382) {
        return keepOrginal ? color : '#fff';
      } else {
        return '#444';
      }
    }
    return color;
  }


  const handleChange = (color) => {
    setColor(color.hex);
  };
  const useStyles = createUseStyles({
    container: {
      display: 'none',
      flexDirection: 'row !important',
      justifyContent: 'space-evenly',
      position: 'fixed',
      width: 'inherit',
      zIndex: 'inherit',
      marginTop: 50,
      height: 50,
      alignItems: 'center'
    },
    circleButton: {
      backgroundColor: '#324739',
      width: 28,
      height: 28,
      borderRadius: 14,
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0px 5px',
      boxShadow: 'rgb(255 255 255 / 20%) 0px 0px 0px 2px inset',
    },
    innerCircle: {
      transition: 'all .2s ease-in-out',
      borderRadius: 4,
    },
    Aa: {
      margin: '0px 20px',
      fontSize: '12pt'
    }
  })
  const classes = useStyles()
  return (
    <div
      onClick={handleClick}
      className={classes.circleButton}
    >
      <FontAwesomeIcon
        icon={faPlusCircle}
        style={{
          fontSize: 30,
          color: color,
          position: 'absolute',
          background: 'transparent',
          zIndex: 1,
          boxShadow: isDarkMode ? 'rgb(255 255 255 / 20%) 0px 0px 0px 2px inset' : 'rgb(0 0 0 / 20%) 0px 0px 0px 2px inset',
          borderRadius: 15
        }}
      />
      <div
        className={classes.circleButton}
        style={{
          background: calculateTextColor(color),
          position: 'absolute',
        }}
      ></div>
    </div>
  )
}

export default ColorPickerButton;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { createStore, applyMiddleware  } from 'redux'
import thunk from "redux-thunk";
import { Provider } from 'react-redux'
import rootReducer from './reducers/rootReducer'
import withClearCache from "./ClearCache";

const store = createStore(rootReducer, applyMiddleware(thunk));

const ClearCacheApp = withClearCache(() => <App />);

ReactDOM.render(<Provider store={store}><ClearCacheApp /></Provider>, document.getElementById('root'));
registerServiceWorker();

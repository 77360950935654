import React, { Component } from 'react';
import { connect } from 'react-redux'
import './AristotleTemplateCoverLetter.css';
import Parser from 'html-react-parser';
import { _HostnamePre } from '../../../Const';

function calculateTextColor(color, keepOrginal = false) {
  if (color) {
    var c = color.replace('#', '');
    var rgb = [];
    rgb[0] = parseInt(c.substr(0, 2), 16);
    rgb[1] = parseInt(c.substr(2, 2), 16);
    rgb[2] = parseInt(c.substr(4, 2), 16);
    if (rgb[0] + rgb[1] + rgb[2] < 382) {
      return keepOrginal ? color : '#fff';
    } else {
      return '#444';
    }
  }
  return color;
}


class AristotleTemplateCoverLetter extends Component {
  getDateObject = (startDate, endDate) => {
    const monthNames = this.props.currentResume.translation['longMonthNames'];
    var startDate0 = new Date(parseInt(startDate, 0));
    if (endDate === "0" || endDate === "NaN") {
      endDate = NaN;
    }
    var endDate0 = new Date(parseInt(endDate, 0));
    return {
      startMonthName: monthNames[startDate0.getMonth()],
      startYear: startDate0.getFullYear(),
      endMonthName: monthNames[endDate0.getMonth()],
      endYear: endDate0.getFullYear()
    }
  }
  getCompanyAddress = (address) => {
    let result = '';
    let coma = '';
    result += address && address?.companyAddress !== "" ? address?.companyAddress : '';
    coma = result !== "" ? ', ' : '';

    result += address && address?.companyPostalCode !== "" ? coma + address?.companyPostalCode : '';
    coma = result !== "" ? ', ' : '';

    result += address && address?.companyCity !== "" ? coma + address?.companyCity : '';
    return (
      <div>
        {result}
      </div>
    );
  }
  getHiringManager = (address) => {
    let result = '';
    let coma = '';
    result += address && address?.hiringManagerName !== "" ? address?.hiringManagerName : '';
    coma = result !== "" ? ', ' : '';

    result += address && address?.hiringManagerRoleName !== "" ? coma + address?.hiringManagerRoleName : '';
    return (
      <div>
        {result}
      </div>
    );
  }

  render() {

    const { currentResume } = this.props;
    const { firstName, lastName, jobTitle, croppedProfilePicture, mobile, email, address } = currentResume.personalDetails;
    const { professionalSummary } = currentResume;

    const street = currentResume.personalDetails.address.street;
    var comma = street === "" ? "" : ", ";
    const postalCode = currentResume.personalDetails.address.postalCode ? comma + currentResume.personalDetails.address.postalCode : "";
    comma = postalCode === "" && comma === "" ? "" : ", ";
    const city = currentResume.personalDetails.address.city ? comma + currentResume.personalDetails.address.city : "";
    comma = city === "" && comma === "" ? "" : ", ";
    const country = currentResume.personalDetails.address.country ? comma + currentResume.personalDetails.address.country : "";

    let mainBlocks = "";
    let sideBlocks = "";
    if (currentResume.blockOrder) {
      const blockOrder = Object.values(currentResume.blockOrder);
    }
    var aristotleCoverLetterHeaderClassName = "aristotleCoverLetter-header-empty";
    if ((croppedProfilePicture && croppedProfilePicture.profilePicture !== null && croppedProfilePicture.profilePicture !== "" && currentResume.hidePicture !== true) || firstName !== "" || lastName !== "" || jobTitle !== "") {
      aristotleCoverLetterHeaderClassName = "aristotleCoverLetter-header";
    }
    //console.log(lineHeight: currentResume.lineSpace,
          //fontSize: currentResume.fontSize+"px")
    //console.log(currentResume.color)
    return (
      this.props.currentTemplate !== "Aristotle" ? "" :
        <div className="aristotleCoverLetter A4" style={{
          marginBottom: this.props.isMobilePreview && 0,
          boxShadow: this.props.isMobilePreview && 'none',
          lineHeight: currentResume.lineSpace,
          fontSize: currentResume.fontSize+"px"
        }}>
          <div className={aristotleCoverLetterHeaderClassName} style={{ backgroundColor: currentResume.color, color: calculateTextColor(currentResume.color), minHeight: '87px' }}>
            {croppedProfilePicture && croppedProfilePicture.profilePicture !== null && croppedProfilePicture.profilePicture !== "" && currentResume.hidePicture !== true &&
              <div className="aristotleCoverLetter-profile-picture-container">
                <img className="aristotleCoverLetter-profile-picture" src={croppedProfilePicture.profilePicture.includes('data:image/') ? croppedProfilePicture.profilePicture : _HostnamePre + croppedProfilePicture.profilePicture} />
              </div>
            }
            {(firstName !== "" || lastName !== "") && <div className="archimedes-full-name" style={{ fontSize: (currentResume.fontSize * 2)}}>{firstName} {lastName}</div>}
            {jobTitle !== "" && <div className="aristotleCoverLetter-job-title" style={{ fontSize: (currentResume.fontSize * 0.7)}}>{jobTitle}</div>}

          </div>
          <div className="side" style={{ float: currentResume.language === 'ar' ? 'left' : 'right' }}>

            {(street !== "" || postalCode !== "" || city !== "" || country !== "") &&
              <div className="details-item">{street}{postalCode}{city}{country}</div>
            }
            {mobile !== "" && <div className="details-item">{mobile}</div>}
            {email !== "" && <div className="details-item">{email}</div>}
          </div>
          <div className="main-content">
            <React.Fragment>
              <div className="details-item">
                {/* {
                  new Date().getDate() + " " + currentResume.translation.shortMonthNames[new Date().getMonth()] + " " + new Date().getFullYear()
                }
                <br /><br /> */}
                {this.getCompanyAddress(address)}
                {this.getHiringManager(address)}
              </div>
              <br /><br />
              {
                professionalSummary !== "" && professionalSummary !== "<p><br></p>" &&
                Parser(professionalSummary)}
            </React.Fragment>
            {mainBlocks}
          </div>
        </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    currentResume: state.viewModel.currentResume,
    scrollTop: state.scrollTop,
    isMobilePreview: state.isMobilePreview,
  }
}

export default connect(mapStateToProps)(AristotleTemplateCoverLetter)
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faPaintBrush, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { changeComponent, download, setIsMobilePreview } from '../actions/rootActions';
import { _HostnamePre } from '../Const';

class EditButton extends Component {

    render() {
        const { currentResume } = this.props;
        const icon = {
            right: currentResume.language === 'ar' ? '5px' : "unset",
            left: currentResume.language === 'ar' ? "unset" : '5px',
        };
        const icon2 = {
            right: currentResume.language === 'ar' ? '5px' : "unset",
            left: currentResume.language === 'ar' ? "unset" : '5px',
        };
        return (
            <div>
                <div className="preview-button edit-button" onClick={() => {
                    this.props.onClick()
                    //this.props.setIsMobilePreview(false);
                    //this.props.changeComponent('editor');
                }
                }>
                    <FontAwesomeIcon
                        icon={faTimesCircle}
                        className="faTimesCircle"
                        style={icon}
                    />
                </div>
                <div className="preview-button templates-button" style={{ left: '30px' }} onClick={() => this.props.onClick2() /*download(currentResume.guid)*/}>
                    <FontAwesomeIcon
                        icon={faPaintBrush}
                        className="faPaintBrush"
                        style={icon2}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentResume: state.viewModel.currentResume
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        changeComponent: (currentComponent) => dispatch(changeComponent(currentComponent)),
        setIsMobilePreview: (value) => dispatch(setIsMobilePreview(value))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditButton))

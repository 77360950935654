import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { ToggleIsDarkMode } from '../actions/rootActions'
import { showSpinner } from '../actions/rootActions'

class DarkMode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDarkMode: props.isDarkMode,
        }

    }
    componentDidUpdate(prevProps) {
        if (this.props.isDarkMode !== prevProps.isDarkMode) {
            this.setState({
                isDarkMode: this.props.isDarkMode,
            })
        }
    }

    ToggleIconsVisibility = (e) => {
        this.setState({
            isDarkMode: e.target.checked
        });
        this.props.ToggleIsDarkMode(e.target.checked);
    }
    DivToggleIconsVisibility = (isDarkMode) => {
        this.setState({
            isDarkMode: !isDarkMode
        });
        this.props.ToggleIsDarkMode(!isDarkMode);
    }
    render() {
        return (
            this.props.mode === "basic" ?
                <div className="icons-visibility" style={{ marginRight: this.props.marginRight }}>
                    <div
                        className='iconWrapper'
                        style={{ padding: '4px 8px', cursor: 'pointer', margin: '0px 5px', }}
                        onClick={() => this.props.ToggleIsDarkMode(!this.props.isDarkMode)}
                    >
                        <FontAwesomeIcon
                            icon={(this.props.isDarkMode) ? faSun : faMoon}
                        />
                    </div>
                </div>
                :
                <div className="templatesButton toggle1 disable-select"
                    style={{
                        marginLeft: this.props.currentResume.language === 'ar' ? '10px' : 'unset',
                        marginRight: this.props.currentResume.language === 'ar' ? 'unset' : '10px',
                    }}
                    onClick={() => this.DivToggleIconsVisibility(this.state.isDarkMode)} >
                    <div style={{ justifyContent: 'center', minWidth: 'fit-content', padding: '10px', borderRadius: '10px', cursor: 'pointer', display: 'flex' }}>
                        <div className="icons-visibility" style={{ marginRight: this.props.marginRight }}>
                            <input type="checkbox" name="isHideIcons" checked={this.state.isDarkMode} onChange={this.ToggleIconsVisibility} />

                            <span style={{ fontSize: this.props.fontSize }}>
                                {this.props.innerWidth >= 375 &&
                                    this.props.currentResume.translation['night_mode']
                                }
                            </span>
                        </div>
                    </div>
                </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        isDarkMode: state.viewModel.auth.isDarkMode,
        currentResume: state.viewModel.currentResume,
        innerWidth: state.innerWidth
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        ToggleIsDarkMode: (value) => dispatch(ToggleIsDarkMode(value)),
        showSpinner: (isShowSpinner) => dispatch(showSpinner(isShowSpinner))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DarkMode)
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { showModal, showSpinner } from '../actions/rootActions'
import { ToggleSplitSection, updatePersonalDetails, updatePersonalDetailsOnServer } from '../actions/resume/personalDetailsActions'
import { debounce } from "lodash";
import { faAngleUp, faAngleDoubleDown, faAngleDoubleUp, faInfoCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'react-dropzone-uploader/dist/styles.css'
import ProfilePicture from './ProfilePicture';
import { ToggleCategoryDisplay } from '../actions/resume/generalResumeActions'
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { _LockFullName } from '../Const';

const personalDetailsResume = () => {
    return (
        <>
            <p style={{ fontSize: '18px', marginTop: '-30px', marginBottom: '39px', marginRight: '20px' }}><h5><strong>Personal Details Tips</strong></h5></p>
            <div className='help-modal'>
                <p>
                    Personal details include information about you such as name, phone number, email etc.
                </p>
                <p>
                    Personal details should be included on a resume since it makes it easier for a recruiter to contact you.
                </p>
                <br />
                <p>Recommended:</p>
                <p>
                    <ul className='ul-list'>
                        <li>
                            <b>Name:</b> Include your first and last name.
                        </li>
                        <li>
                            <b>Job title:</b> Include a job title that accurately describes your position or the position you
                            are applying for.
                        </li>
                        <li>
                            <b>Email address:</b> In a resume, a professional email address containing your first and
                            last name should be provided.
                        </li>
                        <li>
                            <b>Phone number:</b> A phone number is one of the most crucial contact details to include
                            in a resume. It is essential to add the country code when applying for a job outside
                            the country.
                        </li>
                        <li>
                            <b>Address:</b> Adding an address to your resume is recommended, but it is optional. If you
                            are applying for jobs in other cities, you might need to include your full address because some employers may only accept applicants from particular areas for certain positions.
                        </li>
                        <li>
                            <b>Photo:</b> Adding a picture to your resume is optional. Photos in resumes are disliked in the U.S. but more common in Europe.
                        </li>
                        <li>
                            <b>Driving license:</b> Optional. Add only if your career requires a specific type of driving license.
                        </li>
                        <li>
                            <b>Nationality:</b> Optional. Only provide your nationality if it is relevant to the position you are applying for.
                        </li>
                        <li>
                            <b>Date of birth:</b> Optional. Only provide your nationality if it is relevant to the position you are applying for.
                        </li>
                    </ul>
                </p>
                <br />
                <p>Not recommended:</p>
                <p>
                    <ul className='ul-list'>
                        <li>
                            Using a non-professional email address is not recommended. Avoid using email
                            addresses containing nicknames or fake names that do not sound like your real
                            name.
                        </li>
                        <li>
                            Avoid adding multiple phone numbers and include only one on your resume.
                        </li>
                    </ul>
                </p>
            </div>
        </>
    );
}

const personalDetailsCoverLetter = () => {
    return (
        <>
            <p style={{ fontSize: '18px', marginTop: '-30px', marginBottom: '39px', marginRight: '20px' }}><h5><strong>Personal Details Tips</strong></h5></p>
            <div className='help-modal'>
                <p>
                    Personal details include information about you such as name, phone number, email etc.
                </p>
                <p>
                    Personal details should be included on a cover letter since it makes it easier for an employer to contact you.
                </p>
                <br />
                <p>Recommended:</p>
                <p>
                    <ul className='ul-list'>
                        <li>
                            <b>Name:</b> Include your first and last name.
                        </li>
                        <li>
                            <b>Job title:</b> Include a job title that accurately describes your position or the position you
                            are applying for.
                        </li>
                        <li>
                            <b>Email address:</b> In a cover letter, a professional email address containing your first and
                            last name should be provided.
                        </li>
                        <li>
                            <b>Phone number:</b> A phone number is one of the most crucial contact details to include
                            in a cover letter. It is essential to add the country code when applying for a job outside
                            the country.
                        </li>
                        <li>
                            <b>Address:</b> Adding an address to your cover letter is recommended, but it is optional. If you
                            are applying for jobs in other cities, you might need to include your full address because some employers may only accept applicants from particular areas for certain positions.
                        </li>
                    </ul>
                </p>
                <br />
                <p>Not recommended:</p>
                <p>
                    <ul className='ul-list'>
                        <li>
                            Using a non-professional email address is not recommended. Avoid using email
                            addresses containing nicknames or fake names that do not sound like your real
                            name.
                        </li>
                        <li>
                            Avoid adding multiple phone numbers and include only one on your cover letter.
                        </li>
                    </ul>
                </p>
            </div>
        </>
    );
}


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
        disableFocusListener
        disableHoverListener
        disableTouchListener
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

class PersonalDetails extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            jobTitle: null,
            firstName: '',
            lastName: '',
            street: '',
            postalCode: '',
            city: '',
            country: '',
            email: '',
            mobile: '',
            nationality: '',
            dateOfBirth: '',
            drivingLicense: '',
            companyName: '',
            hiringManagerName: '',
            hiringManagerRoleName: '',
            companyPostalCode: '',
            companyAddress: '',
            companyCity: '',
            isDataLoaded: false,
            htmlTooltipOpen1: false,
            htmlTooltipOpen2: false
        }
    }
    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate(prevProps) {
        if (this._isMounted)
            if (!this.state.isDataLoaded) {
                if (this.props.currentResume.personalDetails !== prevProps.currentResume.personalDetails) {
                    this.setState({
                        jobTitle: this.props.currentResume.personalDetails.jobTitle,
                        firstName: this.props.currentResume.personalDetails.firstName,
                        lastName: this.props.currentResume.personalDetails.lastName,
                        street: this.props.currentResume.personalDetails.address.street,
                        postalCode: this.props.currentResume.personalDetails.address.postalCode,
                        city: this.props.currentResume.personalDetails.address.city,
                        country: this.props.currentResume.personalDetails.address.country,
                        email: this.props.currentResume.personalDetails.email,
                        mobile: this.props.currentResume.personalDetails.mobile,
                        nationality: this.props.currentResume.personalDetails.nationality,
                        dateOfBirth: this.props.currentResume.personalDetails.dateOfBirth,
                        drivingLicense: this.props.currentResume.personalDetails.drivingLicense,
                        companyName: this.props.currentResume.personalDetails.address.companyName,
                        hiringManagerName: this.props.currentResume.personalDetails.address.hiringManagerName,
                        hiringManagerRoleName: this.props.currentResume.personalDetails.address.hiringManagerRoleName,
                        companyPostalCode: this.props.currentResume.personalDetails.address.companyPostalCode,
                        companyAddress: this.props.currentResume.personalDetails.address.companyAddress,
                        companyCity: this.props.currentResume.personalDetails.address.companyCity,
                        isDataLoaded: true
                    });
                    this.props.setIsDataLoaded(true);
                }
            }
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
        var { jobTitle, firstName, lastName, email, mobile, nationality, dateOfBirth, drivingLicense, street, postalCode, city, country, companyName, hiringManagerName, hiringManagerRoleName, companyPostalCode, companyAddress, companyCity } = this.state;
        switch (e.target.id) {
            case 'firstName': firstName = e.target.value; break;
            case 'lastName': lastName = e.target.value; break;
            case 'jobTitle': jobTitle = e.target.value; break;
            case 'email': email = e.target.value; break;
            case 'mobile': mobile = e.target.value; break;
            case 'nationality': nationality = e.target.value; break;
            case 'dateOfBirth': dateOfBirth = e.target.value; break;
            case 'drivingLicense': drivingLicense = e.target.value; break;
            case 'street': street = e.target.value; break;
            case 'postalCode': postalCode = e.target.value; break;
            case 'city': city = e.target.value; break;
            case 'country': country = e.target.value; break;
            case 'companyName': companyName = e.target.value; break;
            case 'hiringManagerName': hiringManagerName = e.target.value; break;
            case 'hiringManagerRoleName': hiringManagerRoleName = e.target.value; break;
            case 'companyPostalCode': companyPostalCode = e.target.value; break;
            case 'companyAddress': companyAddress = e.target.value; break;
            case 'companyCity': companyCity = e.target.value; break;
        }

        this.props.updatePersonalDetails(this.props.currentResume.guid, firstName, lastName, jobTitle, email, mobile, nationality, dateOfBirth, drivingLicense, street, postalCode, city, country, companyName, hiringManagerName, hiringManagerRoleName, companyPostalCode, companyAddress, companyCity);
        this.raiseUpdatepersonalDetailsWhenUserStoppedTyping();
    }

    raiseUpdatepersonalDetailsWhenUserStoppedTyping = debounce(() => {
        const { jobTitle, firstName, lastName, email, mobile, nationality, dateOfBirth, drivingLicense, street, postalCode, city, country, companyName, hiringManagerName, hiringManagerRoleName, companyPostalCode, companyAddress, companyCity } = this.state;
        this.props.updatePersonalDetailsOnServer(this.props.currentResume.guid, firstName, lastName, jobTitle, email, mobile, nationality, dateOfBirth, drivingLicense, street, postalCode, city, country, companyName, hiringManagerName, hiringManagerRoleName, companyPostalCode, companyAddress, companyCity);
        if (!this.state.isShowSpinner) {
            this.props.showSpinner(true);
        }
    }, 1500);

    render() {
        const { jobTitle, firstName, lastName, email, mobile, nationality, dateOfBirth, drivingLicense, street, postalCode, city, country, companyName, hiringManagerName, hiringManagerRoleName, companyPostalCode, companyAddress, companyCity } = this.state;
        const { currentResume, isDarkMode } = this.props;

        const middle = {
            marginLeft: currentResume.language === 'ar' ? 'unset' : "10px",
            marginRight: currentResume.language === 'ar' ? "10px" : 'unset',
        };
        const icon = {
            marginRight: currentResume.language === 'ar' ? 'unset' : "10px",
            marginLeft: currentResume.language === 'ar' ? "10px" : 'unset',
        };
        if (this.state.isDataLoaded && this._isMounted && jobTitle === null || jobTitle === undefined) {
            window.location.reload();
        }
        const categoryStyle = {
            textAlign: this.props.currentResume.language === 'ar' ? 'right' : 'unset',
            direction: this.props.currentResume.language === 'ar' ? 'rtl' : 'unset'
        }
        return (
            this.state.isDataLoaded && this._isMounted && jobTitle !== null && jobTitle !== undefined &&
            <>

                <div className="category" style={categoryStyle} onClick={() => { this.setState({ htmlTooltipOpen1: false, htmlTooltipOpen2: false }) }}>
                    <div className="categoryHeader" style={{ cursor: this.props.innerWidth > 1019 ? 'pointer' : 'default' }} onClick={() => this.props.innerWidth > 1019 && this.props.ToggleCategoryDisplay("header", "000")}>
                        <div>
                            <span className="middle" style={middle}><div className={(isDarkMode) ? "block-title title_dark_mode" : "block-title"}>
                                <FontAwesomeIcon icon={faInfoCircle} style={icon} />{currentResume.translation['personal_details']}</div></span>
                        </div>
                        <div>
                            <button className="help" onClick={(e) => {
                                e.stopPropagation();
                                this.props.showModal(
                                    <div>
                                        <div style={{ margin: '20px 0px' }}>
                                            <label className="personal-details-title">
                                                <div className={(isDarkMode) ? "title_dark_mode" : ""}>
                                                    {
                                                        this.props.isCoverLetter ? personalDetailsCoverLetter() : personalDetailsResume()
                                                    }
                                                </div>
                                            </label>
                                        </div>
                                    </div>,
                                    null,
                                    false,
                                    false
                                );
                            }}>
                                <div style={{ display: "inline-block" }}>
                                    <FontAwesomeIcon icon={faQuestionCircle} style={{ display: "inline-block" }} />
                                </div>
                            </button>
                            <button className="toggle toggle-all" onClick={(e) => { e.stopPropagation(); this.props.ToggleCategoryDisplay("allHeader", "000") }}>
                                <div id="AngleDownAll000" style={{ display: "none" }}>
                                    <FontAwesomeIcon icon={faAngleDoubleDown} style={{ display: "none" }} />
                                </div>
                                <div id="AngleUpAll000" style={{ display: "inline-block" }}>
                                    <FontAwesomeIcon icon={faAngleDoubleUp} style={{ display: "inline-block" }} />
                                </div>
                            </button>
                            <button className="toggle" onClick={() => this.props.innerWidth <= 1019 && this.props.ToggleCategoryDisplay("header", "000")}>
                                <div id="AngleDown000" style={{ display: "none" }}>
                                    <FontAwesomeIcon icon={faAngleDown} style={{ display: "none" }} />
                                </div>
                                <div id="AngleUp000" style={{ display: "inline-block" }}>
                                    <FontAwesomeIcon icon={faAngleUp} style={{ display: "inline-block" }} />
                                </div>
                            </button>
                        </div>
                    </div>
                    {
                        <div className="categoryContainer" >
                            <div id="header000">
                                <div >

                                    <div className="personal-details-container">
                                        {
                                            !this.props.isCoverLetter &&
                                            <div className="personal-details-field-image">
                                                <ProfilePicture />
                                            </div>
                                        }
                                        <div className="personal-details-field">
                                            <div>
                                                <div>
                                                    <div>
                                                        <label className="personal-details-title">
                                                            <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['first_name']}</div>
                                                        </label>
                                                        <div>
                                                            {
                                                                _LockFullName ?
                                                                    <HtmlTooltip
                                                                        open={this.state.htmlTooltipOpen1}
                                                                        onClose={() => this.setState({ htmlTooltipOpen1: false, htmlTooltipOpen2: false })}
                                                                        title={
                                                                            <React.Fragment>
                                                                                <p>{"If you want to change your first name and last name, "}  <a href="https://resumejournal.com/account-general">{"click here"}</a></p>
                                                                            </React.Fragment>
                                                                        }
                                                                    >
                                                                        <input type="text" autoComplete="off" readonly="readonly" value={firstName} id="firstName" className='locked' /*onChange={this.handleChange}*/
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                this.setState({ htmlTooltipOpen1: true, htmlTooltipOpen2: false });
                                                                            }} />
                                                                    </HtmlTooltip>
                                                                    :
                                                                    <input type="text" autoComplete="off" value={firstName} id="firstName" className='locked' onChange={this.handleChange}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            this.setState({ htmlTooltipOpen1: true, htmlTooltipOpen2: false });
                                                                        }} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="personal-details-field">
                                            <div>
                                                <div>
                                                    <div>
                                                        <label className="personal-details-title">
                                                            <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['last_name']}</div>
                                                        </label>
                                                        <div>
                                                            {
                                                                _LockFullName ?
                                                                    <HtmlTooltip
                                                                        open={this.state.htmlTooltipOpen2}
                                                                        onClose={() => this.setState({ htmlTooltipOpen2: false, htmlTooltipOpen1: false })}
                                                                        title={
                                                                            <React.Fragment>
                                                                                <p>{"If you want to change your first name and last name, "}  <a href="https://resumejournal.com/account-general">{"click here"}</a></p>
                                                                            </React.Fragment>
                                                                        }
                                                                    >
                                                                        <input type="text" autoComplete="off" readonly="readonly" value={lastName} id="lastName" className='locked' /*onChange={this.handleChange}*/
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                this.setState({ htmlTooltipOpen2: true, htmlTooltipOpen1: false });
                                                                            }} />
                                                                    </HtmlTooltip>
                                                                    :
                                                                    <input type="text" autoComplete="off" value={lastName} id="lastName" className='locked' onChange={this.handleChange}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            this.setState({ htmlTooltipOpen2: true, htmlTooltipOpen1: false });
                                                                        }} />
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="personal-details-field">
                                            <div>
                                                <label className="personal-details-title">
                                                    <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['job_title']}</div>
                                                </label>
                                                <div>
                                                    <input type="text" value={jobTitle} id="jobTitle" onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="personal-details-field">
                                            <div>
                                                <label className="personal-details-title">
                                                    <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['email']}</div>
                                                </label>
                                                <div>
                                                    <input type="email" name="email" value={email} id="email" onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="personal-details-field">
                                            <div>
                                                <label className="personal-details-title">
                                                    <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['mobile_number']}</div>
                                                </label>
                                                <div>
                                                    <input type="tel" name="phoneNumber" value={mobile} id="mobile" onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="personal-details-field">
                                            <div>
                                                <label className="personal-details-title">
                                                    <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['address']}</div>
                                                </label>
                                                <div>
                                                    <input type="text" value={street} id="street" onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="personal-details-field">
                                            <div>
                                                <label className="personal-details-title">
                                                    <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['postal_code']}</div>
                                                </label>
                                                <div>
                                                    <input type="text" value={postalCode} id="postalCode" onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="personal-details-field">
                                            <div>
                                                <label className="personal-details-title">
                                                    <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['city']}</div>
                                                </label>
                                                <div>
                                                    <input type="text" value={city} id="city" onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="personal-details-field hideable">
                                            <div>
                                                <label className="personal-details-title">
                                                    <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['country']}</div>
                                                </label>
                                                <div>
                                                    <input type="text" value={country} id="country" onChange={this.handleChange} placeholder={currentResume.translation['for_some_careers_it_does_not_matter']} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="personal-details-field hideable">
                                            <div>
                                                <label className="personal-details-title">
                                                    <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['nationality']}</div>
                                                </label>
                                                <div>
                                                    <input type="text" value={nationality} id="nationality" onChange={this.handleChange} placeholder={currentResume.translation['for_some_careers_it_does_not_matter']} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="personal-details-field hideable">
                                            <div>
                                                <label className="personal-details-title">
                                                    <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['date_of_birth']}</div>
                                                </label>
                                                <div>
                                                    <input type="text" value={dateOfBirth} id="dateOfBirth" onChange={this.handleChange} placeholder={currentResume.translation['for_some_careers_it_does_not_matter']} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="personal-details-field hideable">
                                            <div>
                                                <label className="personal-details-title">
                                                    <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['driving_license']}</div>
                                                </label>
                                                <div>
                                                    <input type="text" value={drivingLicense} id="drivingLicense" onChange={this.handleChange} placeholder={currentResume.translation['for_some_careers_it_does_not_matter']} />
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            !this.props.isCoverLetter &&
                                            <div className="split-section">
                                                <button className="split-section-toggle" onClick={() => this.props.ToggleSplitSection()}>
                                                    <div id="SplitAngleDown" style={{ display: "flex" }}>
                                                        <FontAwesomeIcon icon={faAngleDown} style={icon} /> {currentResume.translation['show_more_details']}
                                                    </div>
                                                    <div id="SplitAngleUp" style={{ display: "none" }}>
                                                        <FontAwesomeIcon icon={faAngleUp} style={icon} /> {currentResume.translation['show_less_details']}
                                                    </div>
                                                </button>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>


                {
                    this.props.isCoverLetter &&
                    <div className="category" style={categoryStyle}>
                        <div className="categoryHeader" style={{ cursor: this.props.innerWidth > 1019 ? 'pointer' : 'default' }} onClick={() => this.props.innerWidth > 1019 && this.props.ToggleCategoryDisplay("header", "0001")}>
                            <div>
                                <span className="middle" style={middle}><div className={(isDarkMode) ? "block-title title_dark_mode" : "block-title"}>
                                    <FontAwesomeIcon icon={faInfoCircle} style={icon} />{currentResume.translation['employer_details']}</div></span>
                            </div>
                            <div>
                                <button className="toggle toggle-all" onClick={(e) => { e.stopPropagation(); this.props.ToggleCategoryDisplay("allHeader", "0001") }}>
                                    <div id="AngleDownAll0001" style={{ display: "none" }}>
                                        <FontAwesomeIcon icon={faAngleDoubleDown} style={{ display: "none" }} />
                                    </div>
                                    <div id="AngleUpAll0001" style={{ display: "inline-block" }}>
                                        <FontAwesomeIcon icon={faAngleDoubleUp} style={{ display: "inline-block" }} />
                                    </div>
                                </button>
                                <button className="toggle" onClick={() => this.props.innerWidth <= 1019 && this.props.ToggleCategoryDisplay("header", "0001")}>
                                    <div id="AngleDown0001" style={{ display: "none" }}>
                                        <FontAwesomeIcon icon={faAngleDown} style={{ display: "none" }} />
                                    </div>
                                    <div id="AngleUp0001" style={{ display: "inline-block" }}>
                                        <FontAwesomeIcon icon={faAngleUp} style={{ display: "inline-block" }} />
                                    </div>
                                </button>
                            </div>
                        </div>
                        {
                            <div className="categoryContainer" >
                                <div id="header0001">
                                    <div >
                                        <div className="personal-details-container">
                                            <div className="personal-details-field">
                                                <div>
                                                    <label className="personal-details-title">
                                                        <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['hiring_manager_name']}</div>
                                                    </label>
                                                    <div>
                                                        <input type="text" value={hiringManagerName} id="hiringManagerName" onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="personal-details-field">
                                                <div>
                                                    <label className="personal-details-title">
                                                        <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['hiring_manager_role_name']}</div>
                                                    </label>
                                                    <div>
                                                        <input type="text" value={hiringManagerRoleName} id="hiringManagerRoleName" onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="personal-details-field">
                                                <div>
                                                    <label className="personal-details-title">
                                                        <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['company_name']}</div>
                                                    </label>
                                                    <div>
                                                        <input type="text" value={companyName} id="companyName" onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="personal-details-field">
                                                <div>
                                                    <label className="personal-details-title">
                                                        <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['company_address']}</div>
                                                    </label>
                                                    <div>
                                                        <input type="text" value={companyAddress} id="companyAddress" onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="personal-details-field">
                                                <div>
                                                    <label className="personal-details-title">
                                                        <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['company_postal_code']}</div>
                                                    </label>
                                                    <div>
                                                        <input type="text" value={companyPostalCode} id="companyPostalCode" onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="personal-details-field">
                                                <div>
                                                    <label className="personal-details-title">
                                                        <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['company_city']}</div>
                                                    </label>
                                                    <div>
                                                        <input type="text" value={companyCity} id="companyCity" onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentResume: state.viewModel.currentResume,
        isDarkMode: state.viewModel.auth.isDarkMode,
        innerWidth: state.innerWidth
        //isDataLoaded: state.isDataLoaded
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        showModal: (content, onHideModal, isTransparent, isFillAvailable) => dispatch(showModal(content, onHideModal, isTransparent, isFillAvailable)),
        updatePersonalDetails: (guid, jobTitle, firstName, lastName, email, mobile, nationality, dateOfBirth, drivingLicense, street, postalCode, city, country, companyName, hiringManagerName, hiringManagerRoleName, companyPostalCode, companyAddress, companyCity) =>
            dispatch(updatePersonalDetails(guid, jobTitle, firstName, lastName, email, mobile, nationality, dateOfBirth, drivingLicense, street, postalCode, city, country, companyName, hiringManagerName, hiringManagerRoleName, companyPostalCode, companyAddress, companyCity)),
        updatePersonalDetailsOnServer: (guid, jobTitle, firstName, lastName, email, mobile, nationality, dateOfBirth, drivingLicense, street, postalCode, city, country, companyName, hiringManagerName, hiringManagerRoleName, companyPostalCode, companyAddress, companyCity) =>
            dispatch(updatePersonalDetailsOnServer(guid, jobTitle, firstName, lastName, email, mobile, nationality, dateOfBirth, drivingLicense, street, postalCode, city, country, companyName, hiringManagerName, hiringManagerRoleName, companyPostalCode, companyAddress, companyCity)),
        showSpinner: (isShowSpinner) =>
            dispatch(showSpinner(isShowSpinner)),
        ToggleCategoryDisplay: (id0, id1) => dispatch(ToggleCategoryDisplay(id0, id1)),
        ToggleSplitSection: () => dispatch(ToggleSplitSection())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails)
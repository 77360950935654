import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SortableHandle } from 'react-sortable-hoc';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons'

export class BaseHandle extends Component {  
    render(){
        const { tabIndex } = this.props;
        return (
            <div className="handle" tabIndex={tabIndex}>
                <FontAwesomeIcon icon={faGripVertical} />
            </div>   
        )
    }
}

export const Handle = SortableHandle(BaseHandle)
import React, { Component } from 'react';
import { connect } from 'react-redux'
import './FranklinTemplate.css';
import Parser from 'html-react-parser';
import { _HostnamePre } from '../../Const';

function calculateTextColor(color, keepOrginal = false, anotherColor = null) {
  if (color) {
    var c = color.replace('#', '');
    var rgb = [];
    rgb[0] = parseInt(c.substr(0, 2), 16);
    rgb[1] = parseInt(c.substr(2, 2), 16);
    rgb[2] = parseInt(c.substr(4, 2), 16);
    if (rgb[0] + rgb[1] + rgb[2] < 382) {
      return keepOrginal ? anotherColor != null ? anotherColor : color : '#fff';
    } else {
      return '#444';
    }
  }
  return color;
}

class FranklinTemplate extends Component {
  getDateObject = (startDate, endDate) => {
    const monthNames = this.props.currentResume.translation['longMonthNames'];
    var startDate0 = new Date(parseInt(startDate, 0));
    if (endDate === "0" || endDate === "NaN") {
      endDate = NaN;
    }
    var endDate0 = new Date(parseInt(endDate, 0));
    return {
      startMonthName: monthNames[startDate0.getMonth()],
      startYear: startDate0.getFullYear(),
      endMonthName: monthNames[endDate0.getMonth()],
      endYear: endDate0.getFullYear()
    }
  }

  getMainBlocks = (blockOrder) => {
    return (
      blockOrder && blockOrder.map((block, index) => {
        var mainBlock = this.getMainBlock(block)
        if (mainBlock) {
          return (
            <React.Fragment key={index}>{mainBlock}</React.Fragment>
          )
        }

      })
    )
  }
  getSideBlocks = (blockOrder) => {
    return (
      blockOrder && blockOrder.map((block, index) => {
        var sideBlock = this.getSideBlock(block)
        if (sideBlock) {
          return (
            <React.Fragment key={index}>{sideBlock}</React.Fragment>
          )
        }
      })
    )
  }
  getMainBlock = (block) => {
    const { currentResume, isMobilePreview } = this.props;
    const { employmentHistory, internships, volunteers, education, courses, certificates, references } = this.props.currentResume;
    var spacer = "";
    var span = ""
    switch (block) {
      case 'employmentHistory':
        return (
          <React.Fragment>
            {employmentHistory.length > 0 &&
              <div id="employmentHistoryTitle" className="head-title avoid-page-break-after hasIcon" style={{fontSize: (currentResume.fontSize * 1), marginTop: currentResume.fontSize*2-2 ,  color: calculateTextColor(currentResume.color, true) }}>
                <span>{currentResume.translation['employment_history']}</span>
              </div>
            }

            {employmentHistory.map((employment, index) => {
              var dateObject = this.getDateObject(employment.startDate, employment.endDate);
              var employmentHasDescription = employment.description !== "" && employment.description !== "<p><br></p>";
              return (
                <React.Fragment key={index}>
                  <div className="item-title avoid-page-break-after" style={{fontSize: (currentResume.fontSize * 0.875)}}>{employment.jobTitle || currentResume.translation['not_specified']}{(employment.employer) ? ", " + employment.employer : ""}{(employment.city) ? ", " + employment.city : ""}</div>
                  <div className={employmentHasDescription ? "item-date avoid-page-break-after" : "item-date"}>{dateObject.startMonthName && dateObject.startYear && (dateObject.startMonthName + " " + dateObject.startYear + " - ")} {(dateObject.endMonthName && dateObject.endYear) ? (dateObject.endMonthName + " " + dateObject.endYear) : currentResume.translation['present']}</div>

                  {employmentHasDescription && Parser(employment.description)}
                </React.Fragment>
              )
            })}
          </React.Fragment>
        );

      case "internships":

        return (
          <React.Fragment>
            {internships.length > 0 &&
              <div id="internshipsTitle" className="head-title avoid-page-break-after hasIcon" style={{fontSize: (currentResume.fontSize * 1), marginTop: currentResume.fontSize*2-2 ,  color: calculateTextColor(currentResume.color, true) }}>
                <span>{currentResume.translation['internships']}</span>
              </div>
            }

            {
              internships.map((internship, index) => {
                var dateObject = this.getDateObject(internship.startDate, internship.endDate);
                var internshipHasDescription = internship.description !== "" && internship.description !== "<p><br></p>";
                return (
                  <React.Fragment key={index}>
                    <div className="item-title avoid-page-break-after" style={{fontSize: (currentResume.fontSize * 0.875)}}>{internship.jobTitle || currentResume.translation['not_specified']}{(internship.employer) ? ", " + internship.employer : ""}{(internship.city) ? ", " + internship.city : ""}</div>
                    <div className={internshipHasDescription ? "item-date avoid-page-break-after" : "item-date"}>{dateObject.startMonthName && dateObject.startYear && (dateObject.startMonthName + " " + dateObject.startYear + " - ")} {(dateObject.endMonthName && dateObject.endYear) ? (dateObject.endMonthName + " " + dateObject.endYear) : currentResume.translation['present']}</div>
                    {internshipHasDescription && Parser(internship.description)}
                  </React.Fragment>
                )
              })}
          </React.Fragment>
        );


      case "volunteers":
        return (
          <React.Fragment>
            {volunteers.length > 0 &&
              <div id="volunteersTitle" className="head-title avoid-page-break-after hasIcon" style={{fontSize: (currentResume.fontSize * 1), marginTop: currentResume.fontSize*2-2 ,  color: calculateTextColor(currentResume.color, true) }}>
                <span>{currentResume.translation['volunteers']}</span>
              </div>
            }
            {volunteers.map((volunteer, index) => {
              var dateObject = this.getDateObject(volunteer.startDate, volunteer.endDate);
              var volunteerHasDescription = volunteer.description !== "" && volunteer.description !== "<p><br></p>";
              return (
                <React.Fragment key={index}>
                  <div className="item-title avoid-page-break-after" style={{fontSize: (currentResume.fontSize * 0.875)}}>{volunteer.jobTitle || currentResume.translation['not_specified']}{(volunteer.employer) ? ", " + volunteer.employer : ""}{(volunteer.city) ? ", " + volunteer.city : ""}</div>
                  <div className={volunteerHasDescription ? "item-date avoid-page-break-after" : "item-date"}>{dateObject.startMonthName && dateObject.startYear && (dateObject.startMonthName + " " + dateObject.startYear + " - ")} {(dateObject.endMonthName && dateObject.endYear) ? (dateObject.endMonthName + " " + dateObject.endYear) : currentResume.translation['present']}</div>
                  {volunteerHasDescription && Parser(volunteer.description)}
                </React.Fragment>
              )
            })
            }
          </React.Fragment>
        )

      case "education":
        return (
          <React.Fragment>
            {education.length > 0 &&
              <div id="educationTitle" className="head-title avoid-page-break-after hasIcon" style={{fontSize: (currentResume.fontSize * 1), marginTop: currentResume.fontSize*2-2 ,  color: calculateTextColor(currentResume.color, true) }}>
                <span>{currentResume.translation['education']}</span>
              </div>

            }
            {education.map((educationItem, index) => {
              var dateObject = this.getDateObject(educationItem.startDate, educationItem.endDate);
              var educationItemHasDescription = educationItem.description !== "" && educationItem.description !== "<p><br></p>";
              return (
                <React.Fragment key={index}>
                  <div className="item-title avoid-page-break-after" style={{fontSize: (currentResume.fontSize * 0.875)}}>{educationItem.degree || currentResume.translation['not_specified']}{(educationItem.school) ? ", " + educationItem.school : ""}{(educationItem.city) ? ", " + educationItem.city : ""}</div>
                  <div className={educationItemHasDescription ? "item-date avoid-page-break-after" : "item-date"}>{dateObject.startMonthName && dateObject.startYear && (dateObject.startMonthName + " " + dateObject.startYear + " - ")} {(dateObject.endMonthName && dateObject.endYear) ? (dateObject.endMonthName + " " + dateObject.endYear) : currentResume.translation['present']}</div>
                  {educationItemHasDescription && Parser(educationItem.description)}
                </React.Fragment>
              )
            })
            }
          </React.Fragment>
        )

      case "courses":
        return (
          <React.Fragment>
            {courses.length > 0 &&
              <div id="coursesTitle" className="head-title avoid-page-break-after hasIcon" style={{fontSize: (currentResume.fontSize * 1), marginTop: currentResume.fontSize*2-2 ,  color: calculateTextColor(currentResume.color, true) }}>
                <span>{currentResume.translation['courses']}</span>
              </div>

            }
            {courses.map((course, index) => {
              var dateObject = this.getDateObject(course.startDate, course.endDate);
              return (
                <React.Fragment key={index}>
                  <div className="item-title avoid-page-break-after" style={{fontSize: (currentResume.fontSize * 0.875)}}>{course.course || currentResume.translation['not_specified']}{(course.institution) ? ", " + course.institution : ""}{(course.city) ? ", " + course.city : ""}</div>
                  <div className="item-date">{dateObject.startMonthName && dateObject.startYear && (dateObject.startMonthName + " " + dateObject.startYear + " - ")} {(dateObject.endMonthName && dateObject.endYear) ? (dateObject.endMonthName + " " + dateObject.endYear) : currentResume.translation['present']}</div>
                </React.Fragment>
              )
            })
            }
          </React.Fragment>
        )

      case "certificates":
        return (
          <React.Fragment>
            {certificates.length > 0 &&
              <div id="certificatesTitle" className="head-title avoid-page-break-after hasIcon" style={{fontSize: (currentResume.fontSize * 1), marginTop: currentResume.fontSize*2-2 ,  color: calculateTextColor(currentResume.color, true) }}>
                <span>{currentResume.translation['certificates']}</span>
              </div>

            }
            {certificates.map((certificate, index) => {
              let dateObject = this.getDateObject(certificate.startDate, certificate.endDate);
              return (
                <React.Fragment key={index}>
                  <div className="item-title avoid-page-break-after" style={{fontSize: (currentResume.fontSize * 0.875)}}>{certificate.name || currentResume.translation['not_specified']}{(certificate.organization) ? ", " + certificate.organization : ""}</div>
                  <div className="item-date">{dateObject.startMonthName && dateObject.startYear && (dateObject.startMonthName + " " + dateObject.startYear + " - ")} {(dateObject.endMonthName && dateObject.endYear) ? (dateObject.endMonthName + " " + dateObject.endYear) : currentResume.translation['present']}</div>
                </React.Fragment>
              )
            })
            }
          </React.Fragment>
        )

      case "references":
        return (
          <React.Fragment>
            {references.length > 0 &&
              <div id="referencesTitle" className="head-title avoid-page-break-after hasIcon" style={{fontSize: (currentResume.fontSize * 1), marginTop: currentResume.fontSize*2-2 ,  color: calculateTextColor(currentResume.color, true) }}>
                <span>{currentResume.translation['references']}</span>
              </div>

            }
            {references.map((reference, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="item-title avoid-page-break-after" style={{fontSize: (currentResume.fontSize * 0.875)}}>{reference.name} {reference.name && reference.company && ' ' +currentResume.translation['from'] + ' '} {reference.company}</div>
                  {reference.jobTitle && <div className="text ">{reference.jobTitle}</div>}
                  <div className="text ">{reference.phone} {reference.phone && reference.email && '|'} {reference.email}</div>
                </React.Fragment>
              )
            })

            }
          </React.Fragment>
        )
    }


  }
  getSideBlock = (block) => {
    var hobbiesComma = "";
    const { currentResume } = this.props;
    const { skills, languages, hobbies, links } = this.props.currentResume;
    switch (block) {
      case 'skills':
        return (
          <React.Fragment>
            {skills.length > 0 && <div id="skillsTitle" className="head-title hasIcon" style={{fontSize: (currentResume.fontSize * 1),  color: calculateTextColor(currentResume.color, true) }} ><span >{currentResume.translation['skills']}</span></div>}
            {skills.map((skill, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="details-item">
                    {skill.skill}
                  </div>
                  <div
                    className="progress progress-space-20"
                    style={{ opacity: skill.level >= 20 ? 1 : 0.3 }} ></div>

                  <div
                    className="progress progress-space-40"
                    style={{ opacity: skill.level >= 40 ? 1 : 0.3 }} ></div>


                  <div
                    className="progress progress-space-60"
                    style={{ opacity: skill.level >= 60 ? 1 : 0.3 }} ></div>

                  <div
                    className="progress progress-space-80"
                    style={{ opacity: skill.level >= 80 ? 1 : 0.3 }} ></div>

                  <div
                    className="progress progress-space-100"
                    style={{ opacity: skill.level >= 100 ? 1 : 0.3 }} ></div>

                </React.Fragment>
              )
            })}
          </React.Fragment>
        );

      case "languages":

        return (
          <React.Fragment>
            {languages.length > 0 && <div id="languagesTitle" className="head-title hasIcon" style={{fontSize: (currentResume.fontSize * 1),  color: calculateTextColor(currentResume.color, true) }} ><span >{currentResume.translation['languages']}</span></div>}
            {languages.map((language, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="details-item">
                    {language.language}
                  </div>
                  <div
                    className="progress progress-space-20"
                    style={{ opacity: language.level >= 20 ? 1 : 0.3 }} ></div>

                  <div
                    className="progress progress-space-40"
                    style={{ opacity: language.level >= 40 ? 1 : 0.3 }} ></div>


                  <div
                    className="progress progress-space-60"
                    style={{ opacity: language.level >= 60 ? 1 : 0.3 }} ></div>

                  <div
                    className="progress progress-space-80"
                    style={{ opacity: language.level >= 80 ? 1 : 0.3 }} ></div>

                  <div
                    className="progress progress-space-100"
                    style={{ opacity: language.level >= 100 ? 1 : 0.3 }} ></div>
                </React.Fragment>
              )
            })}
          </React.Fragment>
        );


      case "hobbies":
        return (
          hobbies.length > 0 &&
          <React.Fragment>
            <div id="hobbiesTitle" className="head-title hasIcon" style={{fontSize: (currentResume.fontSize * 1),  color: calculateTextColor(currentResume.color, true) }} ><span>{currentResume.translation['hobbies']}</span></div>
            <div className="details-item" >
              {hobbies.map((hobby, index) => {
                var result = hobbiesComma + hobby.title;
                hobbiesComma = ", ";
                return (
                  <React.Fragment key={index}>{result}</React.Fragment>
                );
              })
              }
            </div>
          </React.Fragment>
        )

      case "links":
        return (
          <React.Fragment>
            {links.length > 0 && <div id="linksTitle" className="head-title hasIcon" style={{fontSize: (currentResume.fontSize * 1),  color: calculateTextColor(currentResume.color, true) }} ><span>{currentResume.translation['the_links']}</span></div>}
            {links.map((link, index) => {
              return (
                <div key={index} className="details-item">
                  <a href={link.link} target="_blank" >{link.label}</a>
                </div>
              )
            })
            }
          </React.Fragment>
        )
    }


  }

  render() {

    const { currentResume } = this.props;
    const { firstName, lastName, jobTitle, dateOfBirth, drivingLicense, nationality, croppedProfilePicture, mobile, email } = currentResume.personalDetails;
    const { skills, languages, hobbies, links, professionalSummary, employmentHistory, internships, volunteers, education, courses, certificates, references } = currentResume;

    const street = currentResume.personalDetails.address.street;
    var comma = street === "" ? "" : ", ";
    const postalCode = currentResume.personalDetails.address.postalCode ? comma + currentResume.personalDetails.address.postalCode : "";
    comma = postalCode === "" && comma === "" ? "" : ", ";
    const city = currentResume.personalDetails.address.city ? comma + currentResume.personalDetails.address.city : "";
    comma = city === "" && comma === "" ? "" : ", ";
    const country = currentResume.personalDetails.address.country ? comma + currentResume.personalDetails.address.country : "";

    let mainBlocks = "";
    let sideBlocks = "";
    if (currentResume.blockOrder) {
      const blockOrder = Object.values(currentResume.blockOrder);
      mainBlocks = this.getMainBlocks(blockOrder);
      sideBlocks = this.getSideBlocks(blockOrder);
    }
    var franklinHeaderClassName = "franklin-header-empty";
    if (croppedProfilePicture && croppedProfilePicture.profilePicture !== null && croppedProfilePicture.profilePicture !== "" && currentResume.hidePicture !== true) {
      franklinHeaderClassName = "franklin-header";
    }

    const sideStyle = {
      marginRight: currentResume.language === 'ar' ? 'unset' : '34px',
      marginLeft: currentResume.language === 'ar' ? '34px' : 'unset',
      float: currentResume.language === 'ar' ? 'right' : 'left',
    }

    const sum = firstName.length + lastName.length;
    let franklinFullNameStyle = '';
    let franklinFirstNameStyle = '';
    let franklinLastNameStyle = '';
    if (croppedProfilePicture && croppedProfilePicture.profilePicture !== null && croppedProfilePicture.profilePicture !== "" && currentResume.hidePicture !== true) {
      if (sum > 17) {
        franklinFirstNameStyle = {
          fontSize: (currentResume.fontSize * 1.7),
          lineHeight: '1.5'
        }
        franklinLastNameStyle = {
          fontSize: (currentResume.fontSize * 1.7),
          lineHeight: '1.2'
        }
      } else {
        franklinFullNameStyle = {
          marginTop: 7,
          fontSize: (currentResume.fontSize * 2),
          lineHeight: '1.2'
        }
      }
    } else {
      franklinFullNameStyle = {
        marginTop: 17,
        fontSize: (currentResume.fontSize * 1.7),
        lineHeight: '1.2'
      }
    }

    const withImage = croppedProfilePicture && croppedProfilePicture.profilePicture !== null && croppedProfilePicture.profilePicture !== "" && currentResume.hidePicture !== true;


    let marginTop = -8;
    let marginLeft = withImage ? 41 : -38;
    let jobTitleLeft = withImage ? 28 : -46;
    let style1 = '';
    let style2 = '';
    let style3 = '';

    marginTop = -8;
    marginLeft = withImage ? 60 : -38;
    jobTitleLeft = withImage ? 28 : -46;
    style1 = {
      top: '100px',
      fontSize: (currentResume.fontSize * 2),
      // left: withImage ? '330px' : '0px',
      // textAlign: withImage ? 'left' : 'center',
      // width: withImage ? '375px': '100%'
    };
    style2 = {
      top: '155px',
      fontSize: (currentResume.fontSize * 2),
      opacity: 0.7,

      // left: withImage ? '330px' : '0px',
      // textAlign: withImage ? 'left' : 'center',
      // width: withImage ? '375px': '100%'

    };
    style3 = {
      top: '240px',
      color: '#444',
      left: 330,
      fontSize: (currentResume.fontSize * 0.9)
      // left: withImage ? '330px' : '0px',
      // textAlign: withImage ? 'left' : 'center',
      // width: withImage ? '100%': '100%'
    };



    return (
      this.props.currentTemplate !== "Franklin" ? "" :
        <div className="franklin A4" style={{
          marginBottom: this.props.isMobilePreview && 0,
          boxShadow: this.props.isMobilePreview && 'none',
          lineHeight: currentResume.lineSpace,
          fontSize: currentResume.fontSize+"px"
        }}>
          <div className="side-top" style={{ backgroundColor: currentResume.color }}></div>
          <div className="side-top2" style={{ backgroundColor: currentResume.color }}></div>
          <div className="side-left" style={{ backgroundColor: currentResume.color }}></div>
          <div className="side-background" style={{ backgroundColor: currentResume.color }}></div>
          <div className="side-middle" style={{ backgroundColor: currentResume.color }}></div>
          <div className="side-right" style={{ backgroundColor: currentResume.color }}></div>
          <div className="side-bottom" style={{ backgroundColor: currentResume.color }}></div>
          <div className="side" style={sideStyle} >
            <div className="side-container">
              {
                (street !== "" || postalCode !== "" || city !== "" || country !== "" || mobile !== "" || email !== "" || dateOfBirth !== "" || drivingLicense !== "" || nationality !== "") &&
                <div id="detailsTitle" className="head-title hasIcon"  style={{fontSize: (currentResume.fontSize * 1),  color: calculateTextColor(currentResume.color, true) }}><span>{currentResume.translation['details']}</span></div>}
              {(street !== "" || postalCode !== "" || city !== "" || country !== "") &&
                <div className="details-item" >{street}{postalCode}{city}{country}</div>
              }
              {mobile !== "" && <div className="details-item">{mobile}</div>}
              {email !== "" && <div className="details-item" >{email}</div>}
              {dateOfBirth !== "" && <div className="details-item" >{currentResume.translation['date_of_birth']}: {dateOfBirth}</div>}
              {drivingLicense !== "" && <div className="details-item" >{currentResume.translation['driving_license']}: {drivingLicense}</div>}
              {nationality !== "" && <div className="details-item" >{currentResume.translation['nationality']}: {nationality}</div>}
              {sideBlocks}
            </div>
          </div>
          <div className={franklinHeaderClassName} style={{ color: calculateTextColor(currentResume.color, true) }}>

            <div style={{ marginTop: marginTop, marginLeft: marginLeft }}>
              <div className="franklin-full-name" style={style1}>{firstName}</div>
              <div className="franklin-full-name" style={style2} >{lastName}</div>
            </div>


            {jobTitle !== "" && <div className="franklin-job-title" style={style3}>{jobTitle}</div>}
          </div>
          {croppedProfilePicture && croppedProfilePicture.profilePicture !== null && croppedProfilePicture.profilePicture !== "" && currentResume.hidePicture !== true &&
            <React.Fragment>

              <div className="franklin-profile-picture-container">
                <img className="franklin-profile-picture" src={croppedProfilePicture.profilePicture.includes('data:image/') ? croppedProfilePicture.profilePicture : _HostnamePre + croppedProfilePicture.profilePicture} />
              </div>
            </React.Fragment>
          }
          <div className="main-content">
            {professionalSummary !== "" && professionalSummary !== "<p><br></p>" &&
              <React.Fragment>
                <div id="summaryTitle" className="head-title avoid-page-break-after summary hasIcon" style={{fontSize: (currentResume.fontSize * 1),  color: calculateTextColor(currentResume.color, true) }}>
                  <span>{currentResume.translation['profile']}</span>
                </div>
                {Parser(professionalSummary)}<div style={{ borderColor: calculateTextColor(currentResume.color, true) }} className="blocks-space-under-profile"></div>
              </React.Fragment>
            }
            {mainBlocks}
          </div>
        </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    currentResume: state.viewModel.currentResume,
    scrollTop: state.scrollTop,
    isMobilePreview: state.isMobilePreview,
  }
}

export default connect(mapStateToProps)(FranklinTemplate)
import React, { Component } from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import Category from './Category'

export class BaseNestedSortableList extends Component {  
    render(){
        const { items, isSorting } = this.props;
        return (
            <div className="list stylizedList">
                {items.map((item, index) => (
                <Category
                    tabbable
                    key={`category-${item.title}`}
                    index={index}
                    item={item}
                />
                ))}
            </div> 
        )
    }
}

export const NestedSortableList = SortableContainer(BaseNestedSortableList)

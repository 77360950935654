import axios from 'axios'
import {_HostnamePre} from '../../Const'

export function updateProfessionalSummaryOnServer(guid, summary) {
  return function (dispatch) {
    const formData = new FormData();
    formData.append('guid', guid);
    formData.append('summary', summary);
    const config = {
      guid: guid,
      summary: summary,
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    }
    axios.post(_HostnamePre + "index.php?controller=Resume&method=UpdateSummary", formData, config)
  };
}
export function updateProfessionalSummary(guid, summary) {
  return (dispatch) => {
    dispatch({ 
      type: 'UPDATE_SUMMARY',
      summary: summary
    });
};
}
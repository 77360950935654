import React, { Component } from 'react';
import { connect } from 'react-redux'
import { changeTemplate, changeTemplateOnServer } from '../actions/resume/resumeListActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faEdit } from '@fortawesome/free-solid-svg-icons'
import { changeComponent, hideModal, showModal, showSpinner } from '../actions/rootActions'
import ConfirmMessage from './ConfirmMessage';
import { _FreeUse } from '../Const';

class TemplateListItem extends Component {
    componentWillMount() {
        if (this.props.selected && this.props.onChangeSelectedTemplate) {
            this.props.onChangeSelectedTemplate(this.props.name);
        }
    }
    changeTemplate = () => {
        this.props.changeTemplate(this.props.currentResume.guid, this.props.name);
    }
    resetTemplate = () => {
        this.props.changeTemplate(this.props.currentResume.guid, this.props.selectedTemplate);
    }

    showLimitedAccessMessage = () => {
        const title = "Limited Access";
        const message = "You need to have a premium account to get full access to premium templates, You still be abled to see the preview temporarly of premium templates.";
        const okButtonTitle = "OK";
        this.props.showModal(<ConfirmMessage id="LimitedAccessMessage" title={title} message={message} okButtonTitle={okButtonTitle} callbackFunction={() => this.props.hideModal()} showStopShowMessage={true} />, null, false, false);
    }

    changeTemplateOnServer = () => {
        if(!_FreeUse){
            !this.props.isPremium && this.props.name !=="Newton" && localStorage.getItem('LimitedAccessMessage') !== 'true' && this.showLimitedAccessMessage();
        }        
        this.props.onChangeSelectedTemplate(this.props.name);
        this.props.changeTemplate(this.props.currentResume.guid, this.props.name);
        this.props.changeTemplateOnServer(this.props.currentResume.guid, this.props.name);
    }
    render() {
        const { name, thumbnail, isDarkMode, selectedTemplate, currentResume } = this.props;
        const thumbnailUrl = currentResume.type === 'coverLetter' ? thumbnail.replace('./static/templates/', './static/templates/coverLetters/') : thumbnail;
        return (
            <div className={selectedTemplate === name ? "template-item selected" : "template-item"}>
                <div className={(isDarkMode) ? "title title_dark_mode" : "title"} style={{ display: 'flex', justifyContent: 'center', fontSize: '12pt', alignItems: 'baseline', height: 'unset' }}>
                    <div style={{ color: selectedTemplate === name ? '#5494e2' : 'inherit' }}>{name}</div>
                </div>
                {selectedTemplate === name ?
                    <div className="thumbnail selected" style={{ backgroundImage: "url('" + thumbnailUrl + "')", backgroundSize: "cover" }}>
                        <div>
                            <FontAwesomeIcon icon={faCheckCircle} style={{ marginLeft: "5px" }} />
                        </div>
                    </div>
                    :
                    <div onClick={this.changeTemplateOnServer} className="thumbnail" style={{ backgroundImage: "url('" + thumbnailUrl + "')", backgroundSize: "cover" }}>
                    </div>
                }

            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        currentResume: state.viewModel.currentResume,
        isDarkMode: state.viewModel.auth.isDarkMode,
        isPremium: state.viewModel.auth.isPremium,
        templateList: state.templateList
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        showModal: (content, onHideModal, isTransparent, isFillAvailable) =>
            dispatch(showModal(content, onHideModal, isTransparent, isFillAvailable)),
            hideModal: () =>
            dispatch(hideModal()),
        changeComponent: (currentComponent) => dispatch(changeComponent(currentComponent)),
        changeTemplate: (guid, name, color) => dispatch(changeTemplate(guid, name, color)),
        changeTemplateOnServer: (guid, name) => dispatch(changeTemplateOnServer(guid, name)),
        showSpinner: (isShowSpinner) => dispatch(showSpinner(isShowSpinner))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateListItem)
import React, { useState, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { ChromePicker } from 'react-color'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

const ColorPicker = ({ currentResume, ChangeColor, ChangeColorOnServer, isShowEditor, innerWidth, displayColorPicker, setDisplayColorPicker }) => {
  const [color, setColor] = useState(currentResume.color);

  useEffect(() => {
    ChangeColor(currentResume.guid, color);
  }, [color]);

  useEffect(() => {
    setColor(currentResume.color);
  }, [currentResume.color]);

  const handleChange = (color) => {
    setColor(color.hex);
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setDisplayColorPicker(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const colorPopOver = useRef(null);
  useOutsideAlerter(colorPopOver);
  const colorPopOverClassName = isShowEditor ? 'color-pop-over' : 'color-pop-over color-pop-over-mobile'
  return (
    displayColorPicker &&
    <React.Fragment>
      {!isShowEditor &&
        <div style={{
          width: '100%',
          height: '232px',
          bottom: '0px',
          background: 'black',
          position: 'fixed',
          zIndex: 2000
        }}>
        </div>
      }
      <div
        className={colorPopOverClassName}
        ref={colorPopOver}
        style={{
          left: innerWidth < 1100 ? !isShowEditor ? 'calc(50% + 85px)' : 'calc(50% - 110px)' : 'calc(50% - 110px)'
        }}
      >
        <div />
        <ChromePicker
          color={color}
          onChange={handleChange}
          disableAlpha={true}
          onChangeComplete={(color) => ChangeColorOnServer(color.hex)}
        />
      </div>
    </React.Fragment>
  )
}

export default ColorPicker;
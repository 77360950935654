import axios from 'axios'
import { _HostnamePre } from '../../Const'


export const loggin = (id, fullName, firstName, lastName, imageURL, email, visitorToken) => {
  return (dispatch) => {
    var params = new URLSearchParams();
    params.append('id', id);
    params.append('fullName', fullName);
    params.append('firstName', firstName);
    params.append('lastName', lastName);
    params.append('imageURL', imageURL);
    params.append('email', email);
    axios.post(_HostnamePre + 'index.php?controller=User&method=Loggin', params, {
      headers: {
        'Authorization': `token ${visitorToken}`
      }
    })
      .then(function ({ data }) {
        dispatch({
          type: 'LOGIN',
          data: data
        });
      });
  };
}


export const login = (captchaToken, identification, password) => {
  return (dispatch) => {
    var params = new URLSearchParams();
    params.append('identification', identification);
    params.append('password', password);
    params.append('captchaToken', captchaToken);
    axios.post(_HostnamePre + 'index.php?controller=User&method=Login', params, {
      headers: {
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    })
      .then(function ({ data }) {
        dispatch({
          type: 'LOGIN',
          data: data
        });
      });
  };
}

export const logout = () => {
  return (dispatch) => {
    axios.get(_HostnamePre + 'index.php?controller=User&method=Logout', {
      headers: {
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    })
      .then(function ({ data }) {
        localStorage.setItem('StopShowLimitedAccessMessage', 'false');
        dispatch({
          type: 'LOGOUT',
          data: data
        });
      });
  };
}

export const isLoggedIn = (token = null, callback = null) => {
  if (token === null) {
    token = localStorage.getItem('token');
  }
  return (dispatch) => {
    axios.get(_HostnamePre + 'index.php?controller=User&method=IsLoggedIn', {
      headers: {
        'Authorization': `token ${token}`,
      }
    })
      .then(function ({ data }) {
        localStorage.setItem('isDarkMode', data.viewModel.auth.isDarkMode);
        dispatch({
          type: 'IS_LOGGED_IN',
          data: data
        });
        if(callback !== null){
          callback();
        }
      });
  };
}

export const loginWithGoogle = (code) => {
  return (dispatch) => {
    var params = new URLSearchParams();
    params.append('code', code);
    axios.get(_HostnamePre + 'index.php?controller=User&method=LoginWithGoogle', params, {
      headers: {
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    })
      .then(function ({ data }) {

        dispatch({
          type: 'LOGIN',
          data: data
        });
      })
      .catch(function (error) {
        // handle error
        //dispatch({ type: 'SIGNUP_ERROR', err});
      })
      .then(function () {
        //dispatch({ type: 'SIGNUP_SUCCESS' });
        // always executed
      });
  };
}
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faSearch } from '@fortawesome/free-solid-svg-icons';
import { changeComponent } from '../actions/rootActions';

class PreviewButton extends Component {
    render() {
        const { currentResume, onClick } = this.props;
        return (
            <div className="preview-button" onClick={() => onClick() /*this.props.changeComponent('MobileTemplatesView')*/}>
                <FontAwesomeIcon icon={faSearch} className="faSearch" />
                <FontAwesomeIcon icon={faFile} className="faFile" />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentResume: state.viewModel.currentResume
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        changeComponent: (currentComponent) => dispatch(changeComponent(currentComponent))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PreviewButton))

import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { logout } from '../actions/auth/authActions'
import InlineLoading from './InlineLoading'
import DarkMode from './DarkMode'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      innerWidth: window.innerWidth
    }
  }
  handleResize = () => {
    this.setState({ innerWidth: window.innerWidth })
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('orientationchange', this.handleResize);
  }

  handelLogout = () => {
    this.props.logout();
  }
  render() {
    const { auth, isDarkMode } = this.props;
    const darkMode = isDarkMode || localStorage.getItem("isDarkMode") === "true";
    return (
      <nav className={(darkMode) ? "resume_head_section nav_dark_mode" : "resume_head_section"}
        style={{
          maxWidth: (this.props.isShowIframe) ? "calc(100% - 842px)" : "100%",
          width: (this.props.leftScreenItemFullWidth) ? "100%" : "",
          display: (this.props.preview) ? "none" : "flex",
          justifyContent: 'space-between',
          alignItems: 'center'

        }}>
        <div className="brand-logo-container">
          <a className="brand-logo" href="https://resumejournal.com/">
            <img alt="Resume Journal" className="logo" src={(darkMode) ? "/logo_darkMode.svg" : "/logo.svg"} />
          </a>
        </div>
        <div className="right-nav">
          <div className="loading-container-inline">
            <InlineLoading />
          </div>
          {auth.isLoggedIn === true && <DarkMode isShowIframe={this.props.isShowIframe} mode={"basic"} innerWidth2={this.state.innerWidth} />}
          {
            auth.isLoggedIn === true &&
            <div
              className='profileButton'
              style={{
                padding: 0,
                cursor: 'pointer',
                margin: '0px 15px 0px 0px',
              }}
              onClick={() => {
                window.location.href = "https://resumejournal.com/account-general";
              }}
            >
              <div style={{
                padding: '2px 6px 2px 0px',
                border: '1px inset transparent',
              }}>
                <FontAwesomeIcon
                  icon={faUserCircle}

                />
              </div>
            </div>
          }

          <div className="navButtons">


            {auth.isLoggedIn === true &&
              <div onClick={this.handelLogout}>
                {/* {
                  this.state.innerWidth >= 400 &&
                  <span> Logout </span>
                } */}
                <FontAwesomeIcon icon={faSignOutAlt} />
              </div>
            }

          </div>
        </div>
      </nav>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.viewModel.auth,
    currentResume: state.viewModel.currentResume,
    messages: state.messages,
    isDarkMode: state.viewModel.auth.isDarkMode,
    leftScreenItemFullWidth: state.leftScreenItemFullWidth
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () =>
      dispatch(logout())
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar))
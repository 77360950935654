import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { hideModal } from '../actions/rootActions'

class Modal extends Component {
    render() {
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('orientationchange', this.handleResize);
        const { content, isShowModal, isTransparent, isFillAvailable } = this.props.modal;
        const { innerWidth } = this.props;
        return (
            <React.Fragment>
                {isShowModal &&
                    <div className="modal-container" style={{ padding: isFillAvailable && innerWidth < 600 ? '0px' : '32px' }}>
                        <div className="modal-back" style={{ height: isFillAvailable && innerWidth < 600 ? '-webkit-fill-available' : 'unset' }}>
                            <div className={isTransparent ? "modal-front-transparent" : "modal-front"} style={{ direction: this.props.currentResume.language === 'ar' ? 'rtl' : 'ltr', width: isFillAvailable && innerWidth < 600 ? '-webkit-fill-available' : 'unset' }}>
                                <div className="modal-close" style={{
                                    left: this.props.currentResume.language === 'ar' ? '0px' : 'unset',
                                    right: this.props.currentResume.language === 'ar' ? 'unset' : '0px'
                                }} onClick={this.props.hideModal}>
                                    <FontAwesomeIcon icon={faTimes} style={{ fontSize: "18pt" }} />
                                </div>
                                {content}
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        modal: state.modal,
        isMobilePreview: state.isMobilePreview,
        innerWidth: state.innerWidth,
        currentResume: state.viewModel.currentResume
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        hideModal: () =>
            dispatch(hideModal())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)

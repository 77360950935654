import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faCopy, faDownload, faShare, faTrash } from '@fortawesome/free-solid-svg-icons'
import { showModal, updateTitle, setShowLoading, download } from '../actions/rootActions'
import { DeleteResume, GetResumeList } from '../actions/resume/resumeListActions'
import CropContent from './CropContent';
import ConfirmMessage from './ConfirmMessage';
import { _FreeUse, _HostnamePre } from '../Const';
import { Document, Page } from 'react-pdf';
import PdfLoading from './PdfLoading';
import ReactTooltip from 'react-tooltip';
import { times } from 'lodash';

const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
};

const monthsOfTheYear = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];
const GetDateTimeObject = (value = 0, is24Hour = false) => {
    var date1 = new Date();
    var offset = date1.getTimezoneOffset();
    let date_ob = new Date(value);
    if (offset)
        date_ob.setMinutes(date_ob.getMinutes() - offset);
    let date = date_ob.getDate();
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    let year = date_ob.getFullYear();
    let hours = ("0" + date_ob.getHours()).slice(-2);
    let partOfDay = '';
    let day = date_ob.getDay();
    if (!is24Hour) {
        const h = date_ob.getHours()
        var hs = h > 12 ? h - 12 : h;
        hours = ("0" + hs).slice(-2);
        partOfDay = h >= 12 ? 'PM' : 'AM';
    }
    let minutes = ("0" + date_ob.getMinutes()).slice(-2);
    let seconds = ("0" + date_ob.getSeconds()).slice(-2);
    let millseconds = date_ob.getMilliseconds();

    return {
        year,
        month,
        date,
        hours,
        minutes,
        seconds,
        millseconds,
        day,
        partOfDay
    };
}
class ResumeListItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            guid: props.guid,
            title: props.title,
            pdfFileName: props.pdfFileName,
            pdfThumbnailFileName: props.pdfThumbnailFileName,
            updatedTime: props.updatedTime,
            language: props.language,
            isShowLoadingDeleteResume: false,
            isShowLoadingDuplicateResume: false,
            isShowLoadingDownload: false,
            isEditTitle: false,
            errorLoadingPdf: true
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.guid !== prevProps.guid) { this.setState({ guid: this.props.guid, isShowLoadingDeleteResume: false, isShowLoadingDuplicateResume: false }); }
        if (this.props.guid !== prevProps.guid) { this.setState({ guid: this.props.guid, isShowLoadingDownload: false, isShowLoadingDownload: false }); }
        if (this.props.title !== prevProps.title) { this.setState({ title: this.props.title }); }
        if (this.props.pdfFileName !== prevProps.pdfFileName) { this.setState({ pdfFileName: this.props.pdfFileName }); }
        if (this.props.pdfThumbnailFileName !== prevProps.pdfThumbnailFileName) { this.setState({ pdfThumbnailFileName: this.props.pdfThumbnailFileName }); }
        if (this.props.updatedTime !== prevProps.updatedTime) { this.setState({ updatedTime: this.props.updatedTime }); }
        if (this.props.language !== prevProps.language) { this.setState({ language: this.props.language }); }
    }

    share = () => {
        if (!this.props.isPremium && !_FreeUse) {
            window.location.href = 'https://resumejournal.com/upgrade-plan';
        } else {
            this.props.showModal(<CropContent guid={this.state.guid} />, null, false, false);
        }
    }
    editTitle = (isEditTitle) => {
        this.setState({
            isEditTitle: isEditTitle
        });
    }
    saveTitle = (event) => {
        if (event.key === "Enter") {
            this.editTitle(false);
            this.props.updateTitle(this.state.guid, this.state.title);
        }
    }
    saveTitleBlur = (event) => {
        this.editTitle(false);
        this.props.updateTitle(this.state.guid, this.state.title);
    }
    showConfirmDeleteMessage = () => {
        const title = "Delete Resume";
        const message = "Are you sure you want to delete this resume?";
        const okButtonTitle = "Delete";
        const cancelButtonTitle = "Cancel";
        this.props.showModal(<ConfirmMessage title={title} message={message} okButtonTitle={okButtonTitle} cancelButtonTitle={cancelButtonTitle} callbackFunction={this.delete} />, null, false, false);
    }
    delete = () => {
        this.setState({
            isShowLoadingDeleteResume: true
        });
        this.props.setShowLoading("SHOW_LOADING_DELETE_RESUME", true);
        this.props.DeleteResume(this.state.guid);
    }
    handleChangeTitle = (e) => {
        this.setState({
            title: e.target.value
        });
    }
    duplicate = () => {
        if(!this.props.isPremium && !_FreeUse){
            window.location.href = 'https://resumejournal.com/upgrade-plan';
        }else{
            this.setState({
                isShowLoadingDuplicateResume: true
            });
            this.props.setShowLoading("SHOW_LOADING_DUPLICATE_RESUME", true);
            this.props.duplicateResume(this.state.guid);
        }
    }
    render() {
        const { guid, title, pdfFileName, updatedTime, language, isEditTitle, isShowLoadingDeleteResume, isShowLoadingDuplicateResume, isShowLoadingDownload } = this.state;
        const { isDarkMode, currentResume } = this.props;
        const date = GetDateTimeObject(updatedTime, true);
        let url = "/main/resumes/" + guid + "/editor";
        if (window.location.href.indexOf("/main/cover-letters") > -1) {
            url = "/main/cover-letters/" + guid + "/editor";
        }
        const updated = date.date ?
            date.date.toString() + ' ' + monthsOfTheYear[parseInt(date.month, 0) - 1] + ', ' + date.hours.toString() + ':' + date.minutes.toString()
            : updatedTime

        return (
            <div className="resume-item">
                <ReactTooltip
                    effect={'solid'}
                    textColor={isDarkMode ? '#000' : '#fff'}
                    backgroundColor={isDarkMode ? '#b6b7b7' : '#303030'}
                    borderColor={isDarkMode ? '#222' : '#eee'}
                    border={isDarkMode ? true : false}

                />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px', height: '44px', width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: 35 }}>
                        {
                            !isEditTitle &&
                            <img src={"../static/flags/" + language} style={{ height: '18px', marginRight: "5px" }} />
                        }
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {

                            !isEditTitle ?
                                <div
                                    className={(isDarkMode) ? "title title_dark_mode" : "title"}
                                    style={{ marginLeft: "0px" }}
                                    onClick={() => this.editTitle(true)}>
                                    {title === "" ? 'Untitled' : title}
                                    <span className="edit-title-icon"></span>
                                </div>
                                :
                                <div className={(isDarkMode) ? "title title_dark_mode" : "title"}>
                                    <input
                                        maxLength="22"
                                        style={{ backgroundColor: 'transparent !important', maxWidth: 195, color: isDarkMode ? '#b6b7b7' : 'default' }}
                                        className='titleEdit'
                                        ref={input => input && input.focus()}
                                        type="text"
                                        value={title}
                                        onKeyPress={this.saveTitle}
                                        onChange={this.handleChangeTitle}
                                        onBlur={this.saveTitleBlur}
                                    />
                                </div>
                        }
                    </div>
                </div>







                <React.Fragment>
                    <div className="plus-button" style={{ display: this.state.errorLoadingPdf ? 'block' : 'none', height: '400px', margin: 0 }}><div><PdfLoading /></div></div>
                    <div className="thumbnail" style={{ display: this.state.errorLoadingPdf ? 'none' : 'block' }}>
                        {
                            isEditTitle &&
                            <div style={{
                                position: 'absolute',
                                backgroundColor: 'transparent',
                                cursor: 'default',
                                zIndex: 1,
                                width: '282px',
                                height: '400px'
                            }}
                            ></div>
                        }

                        <Link to={url}>
                            <Document file={_HostnamePre + this.props.pdfFileName.replace('./', '')} options={options}
                                onLoadError={() => {
                                    this.setState({
                                        errorLoadingPdf: true
                                    });
                                    this.props.GetResumeList()
                                }}
                                onLoadSuccess={() => {
                                    this.setState({
                                        errorLoadingPdf: false
                                    });
                                    //this.props.GetResumeList()
                                }}
                                loading={<div style={{ width: 283, height: 400 }}></div>}
                                noData={<div style={{ width: 283, height: 400 }}></div>}
                            >
                                <Page height={400} pageNumber={1}
                                    loading={<div style={{ backgroundColor: "#ffffff", width: 283, height: 400 }}></div>}
                                    noData={<div style={{ backgroundColor: "#ffffff", width: 283, height: 400 }}></div>}
                                />
                            </Document>
                        </Link>
                    </div>
                </React.Fragment>



                <div className={(isDarkMode) ? "control-buttons title_dark_mode" : "control-buttons"} >
                    {(isShowLoadingDuplicateResume && this.props.isShowLoadingDuplicateResume) ?
                        <div data-tip="Duplicate" className="button">
                            <div className="loader-css-small" ></div>
                        </div>
                        :
                        <div data-tip="Duplicate" className="button" onClick={this.duplicate}>
                            <FontAwesomeIcon icon={faCopy} style={{ marginLeft: "5px" }} />
                        </div>
                    }


                    <div className="button" data-tip="Download" onClick={() => {
                        this.setState({ isShowLoadingDownload: true });
                        this.props.setShowLoading("SHOW_LOADING_DOWNLOAD", true);
                        download(this.state.guid, () => { this.setState({ isShowLoadingDownload: false }); this.props.setShowLoading("SHOW_LOADING_DOWNLOAD", false) })
                    }}
                    >
                        {
                            (isShowLoadingDownload && this.props.isShowLoadingDownload === true) ?
                                <div className="loader-css-small" style={{ borderWidth: 11 }}></div>
                                :
                                <FontAwesomeIcon icon={faDownload} style={{ marginLeft: "5px" }} />
                        }


                    </div>
                    <div className="button" data-tip="Share" onClick={this.share}><FontAwesomeIcon icon={faShare} style={{ marginLeft: "5px" }} /></div>
                    {(isShowLoadingDeleteResume && this.props.isShowLoadingDeleteResume) ?
                        <div className="button" data-tip="Delete">
                            <div className="loader-css-small" ></div>
                        </div>
                        :
                        <div className="button" data-tip="Delete" onClick={this.showConfirmDeleteMessage}>
                            <FontAwesomeIcon icon={faTrash} style={{ marginLeft: "5px" }} />
                        </div>
                    }
                </div>
                <div className={(isDarkMode) ? "updated title_dark_mode" : "updated"}>
                    updated {updated}
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        isPremium: state.viewModel.auth.isPremium,
        isDarkMode: state.viewModel.auth.isDarkMode,
        isShowLoadingDeleteResume: state.isShowLoadingDeleteResume,
        isShowLoadingDuplicateResume: state.isShowLoadingDuplicateResume,
        isShowLoadingDownload: state.isShowLoadingDownload,
        currentResume: state.viewModel.currentResume,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        showModal: (content, onHideModal, isTransparent, isFillAvailable) =>
            dispatch(showModal(content, onHideModal, isTransparent, isFillAvailable)),
        updateTitle: (guid, title) =>
            dispatch(updateTitle(guid, title)),
        DeleteResume: (guid) =>
            dispatch(DeleteResume(guid)),
        setShowLoading: (type, value) =>
            dispatch(setShowLoading(type, value)),
        GetResumeList: () => dispatch(GetResumeList()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResumeListItem)
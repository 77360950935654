import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { GetResume } from '../actions/resume/resumeListActions'
import { isLoggedIn } from '../actions/auth/authActions'
import { GetTemplateList } from '../actions/template/templateListActions'
import TemplateListItem from './TemplateListItem'
import { setLeftScreenItemFullWidth } from '../actions/rootActions'
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

function initTemplatesSlideView() {
  setTimeout(() => {
    $('.templates.my-slider').css("width", $('.leftScreenItem').width() + 'px');
    //initMySlider()
    $('.templates.my-slider').css("min-height", $(window).height() + 'px');
    $('.templates.my-slider').css("visibility", 'visible');
  }, 1000);
}
function slideLeft(isShowEditor) {
  const direction = $('.edit-container').css("direction");
  $('.container-edit').css("display", 'block');
  initMySlider()

  //$('.templates.my-slider').css("min-height", $(window).height() + 'px');
  $('.iconWrapperTemplates > div').removeClass("selected");
  $('.iconWrapperTemplates svg').removeClass("selected");
  $('.iconWrapperTemplates svg').css("color", '#b6b7b7');
  $('#colorsSamples').slideUp(300);
  $('.templatesFooter').slideUp(300);
  $('.embed').css("marginTop", 50 + 'px');
  $('.embedInnerContainer').css("margin-bottom", "0px");
  $('.leftScreenItem').css("margin-bottom", "0px");
}
function initMySlider() {
  const direction = $('.edit-container').css("direction");
  //console.log('direction = ' + direction)
  if (direction === 'ltr') {
    //$('.templates.my-slider').removeClass('my-slider-back-ltr');
    $('.templates.my-slider').removeClass('my-slider-rtl');
    $('.templates.my-slider').addClass('my-slider-ltr');
  } else {
    //$('.templates.my-slider').removeClass('my-slider-back-rtl');
    $('.templates.my-slider').removeClass('my-slider-ltr');
    $('.templates.my-slider').addClass('my-slider-rtl');
  }
}
function resize() {
  $('.templates.my-slider').css("width", $('.leftScreenItem').width() + 'px');
  $('.templates.my-slider').css("min-height", $(window).height() + 'px');
}

class Templates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTemplate: ""
    }
  }
  componentWillMount() {
    this.props.GetTemplateList(this.props.guid);
    initTemplatesSlideView()
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('orientationchange', this.handleResize);
  }
  componentDidUpdate(prevProps) {
    if (this.props.currentResume.language !== prevProps.currentResume.language) {
      initMySlider();
    }
    if (this.props.auth.isLoggedIn !== prevProps.auth.isLoggedIn) {
      if (this.props.auth.isLoggedIn !== true) window.location.href = "/"
    }
  }
  handleResize = () => {
    resize()
    // if (this.props.innerWidth < 1020) {
    //   return <Redirect to={"/main/" + this.props.guid + "/editor"} />
    // }
  }
  onChangeSelectedTemplate = (name) => {
    this.setState({
      selectedTemplate: name
    });
  }
  render() {
    // if (this.props.innerWidth < 1020) {
    //   return <Redirect to={"/main/" + this.props.guid + "/editor"} />
    // }
    const { templateList, isDarkMode, currentResume } = this.props;
    return (
      <div className="templates my-slider" style={{ visibility: 'hidden' }}>
        {/* <ResumeTitleAndLanguage component="template" /> */}
        <div className="templatesButton" onClick={slideLeft} style={{
          margin: '50px 10px',
          position: 'absolute',
          height: 'fit-content',
          cursor: 'pointer',
          left: currentResume.language !== 'ar' ? '-2000px' : 'unset',
          right: currentResume.language === 'ar' ? '-2000px' : 'unset'
        }}>
          <div style={{ cursor: 'pointer' }} to={"/main"}>
            <div style={{ padding: '20px 10px' }} className="closeButton">
              <FontAwesomeIcon
                icon={faCircleXmark}
                style={{
                  color: '#ef4b56',
                  display: 'flex',
                  fontSize: 20
                }}
              />
              {/* <div className="closeButtonHover" style={{ justifyContent: 'center', padding: '6px', borderRadius: '10px', cursor: 'pointer', display: 'flex', alignItems: 'flex-end' }}>
              </div> */}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="template_list_container">
            {templateList && templateList.length > 0 && templateList.slice(0, 12).map((templateListItem, index) => {

              return (
                <TemplateListItem
                  key={index}
                  name={templateListItem.name}
                  thumbnail={templateListItem.thumbnail}
                  tempSelectedTemplate={this.state.tempSelectedTemplate}
                  selectedTemplate={currentResume.template}
                  selected={templateListItem.selected}
                  onChangeSelectedTemplate={this.onChangeSelectedTemplate}
                />
              )
            })}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  //let guid = ownProps.match.params.resume_id;
  return {
    guid: state.viewModel.currentResume.guid,
    auth: state.viewModel.auth,
    messages: state.messages,
    templateList: state.templateList,
    isDarkMode: state.viewModel.auth.isDarkMode,
    currentResume: state.viewModel.currentResume,
    innerWidth: state.innerWidth,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    GetResume: (guid) => dispatch(GetResume(guid)),
    isLoggedIn: () => dispatch(isLoggedIn()),
    GetTemplateList: (guid) => dispatch(GetTemplateList(guid)),
    setLeftScreenItemFullWidth: (value) => dispatch(setLeftScreenItemFullWidth(value))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Templates)
import axios from 'axios';
import {_HostnamePre} from '../../Const'

export const AddLinkToServer = (guid) => {
  return function (dispatch) {
    let url = _HostnamePre + 'index.php?controller=Resume&method=AddLink';
    const formData = new FormData();
    formData.append('guid', guid);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    }
    axios.post(url, formData, config)
    .then(function ({ data }) {
      dispatch({
        type: 'LOAD_RESUME',
        data: data
      });
    })
  };
}

export function updateLink(newList) {
  return (dispatch) => {
    dispatch({ 
      type: 'UPDATE_LINK',
      newList: newList
    });
};
}

export function updateLinkOnServer(guid, id, label, link) {
  return function (dispatch) {
    const formData = new FormData();
    formData.append('guid', guid);
    formData.append('id', id);
    formData.append('label', label);
    formData.append('link', link);
    axios.post(_HostnamePre + "index.php?controller=Resume&method=UpdateLink", formData, {
      headers: {
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    })
  };
}

import axios from 'axios'
import { _HostnamePre } from '../Const'

export const getDateObject = (startDate, endDate) => {
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  var startDate0 = new Date(parseInt(startDate, 0));
  if (endDate === "0" || endDate === "NaN") {
    endDate = NaN;
  }
  var endDate0 = new Date(parseInt(endDate, 0));
  return {
    startMonthName: monthNames[startDate0.getMonth()],
    startYear: startDate0.getFullYear(),
    endMonthName: monthNames[endDate0.getMonth()],
    endYear: endDate0.getFullYear()
  }
}

export const showSpinner = (isShowSpinner) => {
  return {
    type: 'SHOW_SPINNER',
    isShowSpinner
  }
}

export const showModal = (content, onHideModal, isTransparent, isFillAvailable) => {
  return (dispatch) => {
    dispatch({
      type: 'SHOW_MODAL',
      isShowModal: true,
      content: content,
      onHideModal: onHideModal,
      isTransparent: isTransparent,
      isFillAvailable: isFillAvailable
    });
  };
}

export const bruchShouldJumb = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'BRUCH_SHOULD_JUMB',
      value: value,
    });
  };
}

export const setShowEditor = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SWOW_EDITOR',
      value: value,
    });
  };
}

export const setShowTemplates = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SWOW_TEMPLATES',
      value: value,
    });
  };
}

export const setDisplayColorPicker = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'DISPLAY_COLOR_PICKER',
      value: value,
    });
  };
}

export const hideModal = () => {
  return {
    type: 'HIDE_MODAL'
  }
}

export const updateTitle = (guid, title) => {
  return (dispatch) => {
    var params = new URLSearchParams();
    params.append('guid', guid);
    params.append('title', title);
    axios.post(_HostnamePre + 'index.php?controller=Resume&method=UpdateTitle', params, {
      headers: {
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    })
      .then(function ({ data }) {
        dispatch({
          type: 'UPDATE_TITLE',
          data: data,
          title: title
        });
      });
  };
}

export const changeComponent = (currentComponent) => {
  return (dispatch) => {
    dispatch({
      type: 'CHANGE_COMPONENT',
      currentComponent
    });
  }
}

export const setShowLoading = (type, value) => {
  return (dispatch) => {
    dispatch({
      type: type,
      value: value
    });
  };
}

export const setLeftScreenItemFullWidth = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'LEFT_SCREEN_ITEM_ADJUST',
      leftScreenItemFullWidth: value
    });
  };
}

export const toggleTemplates = (left) => {
  const mobileViewTemplatesLeft = (left === 0) ? - window.innerWidth : 0;
  return (dispatch) => {
    dispatch({
      type: 'TOGGLE_TEMPLATES',
      mobileViewTemplatesLeft: mobileViewTemplatesLeft
    });
  };
}

export const setLTemplatesLeft = (left) => {
  return (dispatch) => {
    dispatch({
      type: 'TOGGLE_TEMPLATES',
      mobileViewTemplatesLeft: left
    });
  };
}

export const setIsMobilePreview = (isMobilePreview) => {
  return (dispatch) => {
    dispatch({
      type: 'MOBILE_PREVIW',
      isMobilePreview: isMobilePreview
    });
  };
}
export const UpdateScrollValue = (e) => {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_SCROLL_VALUE',
      scrollTop: e.currentTarget.scrollTop
    });
  };
}

export const download = (guid, callback = null) => {
  var params = new URLSearchParams();
  params.append('guid', guid);
  axios.post(_HostnamePre + 'index.php?controller=Resume&method=DownloadResume', params, {
    headers: {
      'Authorization': `token ${localStorage.getItem('token')}`
    }
  })
    .then(function ({ data }) {
      if (data === 'redirect') {
        window.location.href = 'https://resumejournal.com/upgrade-plan';
        callback();
      } else {
        axios({
          url: _HostnamePre + data.currentResume.pdfFile,
          method: 'GET',
          responseType: 'blob',
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', data.currentResume.pdfFile + '.pdf'); //or any other extension
          document.body.appendChild(link);
          link.click();
          callback();
        });
      }
    });
}

export const ToggleIsDarkMode = (isDarkMode) => {
  return (dispatch) => {
    dispatch({
      type: 'DARK_MODE',
      isDarkMode: isDarkMode
    });
    axios.get(_HostnamePre + 'index.php?controller=User&method=ToggleIsDarkMode&isDarkMode=' + isDarkMode, {
      headers: {
        'Authorization': `token ${localStorage.getItem('token')}`
      }
    });
  };
}

export const ZoomIn = (width, height, tempHeight) => {
  return (dispatch) => {
    dispatch({
      type: 'ZOOM_IN',
      embedWidth: width,
      embedHeight: height,
      tempHeight: tempHeight
    });
  };
}
export const ZoomOut = (height) => {
  return (dispatch) => {
    dispatch({
      type: 'ZOOM_OUT',
      embedWidth: "",
      embedHeight: height
    });
  };
}

export const setInnerWidth = (width) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_INNER_WIDTH',
      innerWidth: width
    });
  };
}

export const setEmbedWidth = (embedWidth) => {
  return (dispatch) => {
    dispatch({
      type: 'EMBED_WIDTH',
      embedWidth: embedWidth
    });
  };
}

export const setEmbedHeight = (embedHeight) => {
  return (dispatch) => {
    dispatch({
      type: 'EMBED_HEIGHT',
      embedHeight: embedHeight
    });
  };
}

export const CreateResume = (type, template = '', callback = null) => {
  let url = _HostnamePre + 'index.php?controller=Resume&method=CreateResume';
  const formData = new FormData();
  formData.append('type', type);
  formData.append('template', template);

  const config = {
    headers: {
      'content-type': 'multipart/form-data',
      'Authorization': `token ${localStorage.getItem('token')}`
    }
  }
  axios.post(url, formData, config)
    .then((response) => {
      if (callback !== null) {
        callback(response);
      }
    });
}
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import ResumeTitleAndLanguage from './ResumeTitleAndLanguage'
import TemplateColors from './TemplateColors'
import TemplateFonts from './TemplateFonts'

export default function EditTemplate({ isDarkMode, changeComponent, currentResume, templateList, changeColor, handleClose }) {
  const [currentTab, setCurrentTab] = useState('colors');
  return (
    <div className={(isDarkMode) ? "background-dark-mode templates EditTemplate" : "templates EditTemplate"}>
      <ResumeTitleAndLanguage component="template" />
      <div className="tab-page-container header">
        <label className="page-title">
          <div>Edit template</div>
        </label>
        <div><FontAwesomeIcon icon={faTimesCircle} className="edit-template-close-button" onClick={() => changeComponent("templates")} /></div>
      </div>
      <div>
        <div className="tab-page-container" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div className={currentTab === 'colors' ? "my-tab checked" : "my-tab"} onClick={() => setCurrentTab('colors')}>Colors</div>
          <div className={currentTab === 'fonts' ? "my-tab checked" : "my-tab"} onClick={() => setCurrentTab('fonts')}>Fonts</div>          
        </div>
        <div >
            {
              currentTab === 'colors' && <TemplateColors currentResume={currentResume} templateList={templateList} changeColor={changeColor} handleClose={handleClose} />
            }
            {
              currentTab === 'fonts' && <TemplateFonts currentResume={currentResume} templateList={templateList} changeColor={changeColor} handleClose={handleClose} />
            }
          </div>
      </div>
    </div>
  )
}
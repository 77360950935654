import React from 'react'
//import FontItemSeclector from './FontItemSeclector'

export default function TemplateFonts() {
    return (
        <div>
            {/* <div className={(isDarkMode) ? "font-seclector-show-fonts font-seclector-show-fonts_dark_mode" : "font-seclector-show-fonts"}
                style={{ display: (this.state.showFonts) ? "block" : "none" }}>
                {fonts.map((font, index) => {
                    return (
                        <FontItemSeclector key={index} font={font} hideShowFonts={this.hideShowFonts} />
                    )
                })}
            </div> */}
        </div>
    )
}


import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import ColorPicker from './ColorPicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTextHeight } from '@fortawesome/free-solid-svg-icons';
import ColorPickerButton from './ColorPickerButton';
import { MenuItem, Select } from '@mui/material';
import FormatSizeIcon from '@mui/icons-material/FormatSize';

const TemplateHeaderContainer = (props) => {
  const useStyles = createUseStyles({
    container: {
      display: 'none',
      flexDirection: 'row !important',
      justifyContent: 'space-evenly',
      position: 'fixed',
      width: 'inherit',
      zIndex: 'inherit',
      marginTop: 50,
      height: 50,
      alignItems: 'center'
    },
    circleButton: {
      backgroundColor: '#324739',
      width: 30,
      height: 30,
      borderRadius: 15,
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0px 5px',
      boxShadow: 'rgb(255 255 255 / 20%) 0px 0px 0px 2px inset',
      '&:hover > div': {
        backgroundColor: '#ddd',
        width: 8,
        height: 8,
        borderRadius: 4,
        cursor: 'pointer',
        transform: 'scale(1.1)'
      }
    },
    innerCircle: {
      transition: 'all .2s ease-in-out',
      borderRadius: 4,
    },
    Aa: {
      fontSize: '12pt'
    }
  })
  const classes = useStyles()
  //const [color, setColor] = useState(props.currentResume.color)
  const [height, setHeight] = useState(50)
  const [defaultColors, setDefaultColors] = useState(undefined)




  const handleChangeColor = (color) => {
    //console.log(color)
    //setColor(color)
    props.ChangeColor(props.currentResume.guid, color);
    props.ChangeColorOnServer(props.currentResume.guid, color.substr(1));
  };

  const handleChangeLineSpace = (lineSpace) => {
    //console.log(lineSpace)
    //setColor(color)
    props.ChangeLineSpace(props.currentResume.guid, lineSpace);
  };
  const handleChangeFontSize = (fontSize) => {
    //console.log(lineSpace)
    //setColor(color)
    props.ChangeFontSize(props.currentResume.guid, fontSize);
  };

  

  const heightMarks = {
    1: "1",
    1.15: "1.15",
    1.5: "1.5",
    2: "2"
  };
  const fontSizeMarks = {
    12: "XS",
    13: "S",
    14: "M",
    15: "L",
    16: "XL"
  };

  function calculateTextColor(color, keepOrginal = false) {
    if (color) {
      var c = color.replace('#', '');
      var rgb = [];
      rgb[0] = parseInt(c.substr(0, 2), 16);
      rgb[1] = parseInt(c.substr(2, 2), 16);
      rgb[2] = parseInt(c.substr(4, 2), 16);
      if (rgb[0] + rgb[1] + rgb[2] < 382) {
        return keepOrginal ? color : '#fff';
      } else {
        return '#444';
      }
    }
    return color;
  }
  useEffect(() => {
    props.templateList && props.currentResume &&
      setDefaultColors(props.templateList.find(template => props.currentResume.template == template.name)?.defaultColors);
  }, [props.templateList, props.currentResume])

  return (
    defaultColors &&
    <div
      id="colorsSamples"
      className={classes.container}
      style={{
        background: props.isDarkMode ? '#2f2e2e' : '#fff',
        boxShadow: props.isDarkMode ? '0 0 0.5cm rgb(0 0 0 / 50%)' : 'none',
        height: props.innerWidth <= 576 ? 150 : 50
      }}
    >
      <div className='colors-style' style={{ display: 'flex', justifyContent: 'center' }}>
        <ColorPickerButton
          setDisplayColorPicker={props.setDisplayColorPicker}
          currentResume={props.currentResume}
          ChangeColor={props.ChangeColor}
          isDarkMode={props.isDarkMode}
        />
        {
          defaultColors.map((color, index) => {
            return (
              <div
                key={index}
                onClick={() => handleChangeColor(color)}
                className={classes.circleButton}
                style={{
                  background: color,
                  boxShadow: props.isDarkMode ? 'rgb(255 255 255 / 20%) 0px 0px 0px 2px inset' : 'none',
                }}
              >
                {
                  props.currentResume.color === color ?
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{
                        fontSize: 16,
                        color: calculateTextColor(color),
                        position: 'absolute',
                        background: 'transparent',
                        zIndex: 1,
                      }}
                    />
                    :
                    <div className={classes.innerCircle}>
                    </div>
                }
              </div>
            )
          })
        }

        {/* <div
          onClick={() => handleChangeColor('#396685')}
          className={classes.circleButton}
          style={{
            background: '#396685',
            boxShadow: props.isDarkMode ? 'rgb(255 255 255 / 20%) 0px 0px 0px 2px inset' : 'none',
          }}
        >
          {
            props.currentResume.color === '#396685' ?
              <FontAwesomeIcon
                icon={faCheck}
                style={{
                  fontSize: 16,
                  color: calculateTextColor('#396685'),
                  position: 'absolute',
                  background: 'transparent',
                  zIndex: 1,
                }}
              />
              :
              <div className={classes.innerCircle}>
              </div>
          }
        </div>
        <div
          onClick={() => handleChangeColor('#c35d56')}
          className={classes.circleButton}
          style={{
            background: '#c35d56',
            boxShadow: props.isDarkMode ? 'rgb(255 255 255 / 20%) 0px 0px 0px 2px inset' : 'none',
          }}
        >
          {
            props.currentResume.color === '#c35d56' ?
              <FontAwesomeIcon
                icon={faCheck}
                style={{
                  fontSize: 16,
                  color: calculateTextColor('#c35d56'),
                  position: 'absolute',
                  background: 'transparent',
                  zIndex: 1,
                }}
              />
              :
              <div className={classes.innerCircle}>
              </div>
          }
        </div>
        <div
          onClick={() => handleChangeColor('#994c8e')}
          className={classes.circleButton}
          style={{
            background: '#994c8e',
            boxShadow: props.isDarkMode ? 'rgb(255 255 255 / 20%) 0px 0px 0px 2px inset' : 'none',
          }}
        >
          {
            props.currentResume.color === '#994c8e' ?
              <FontAwesomeIcon
                icon={faCheck}
                style={{
                  fontSize: 16,
                  color: calculateTextColor('#994c8e'),
                  position: 'absolute',
                  background: 'transparent',
                  zIndex: 1,
                }}
              />
              :
              <div className={classes.innerCircle}>
              </div>
          }
        </div>
        <div
          onClick={() => handleChangeColor('#0d3d6e')}
          className={classes.circleButton}
          style={{
            background: '#0d3d6e',
            boxShadow: props.isDarkMode ? 'rgb(255 255 255 / 20%) 0px 0px 0px 2px inset' : 'none',
          }}
        >
          {
            props.currentResume.color === '#0d3d6e' ?
              <FontAwesomeIcon
                icon={faCheck}
                style={{
                  fontSize: 16,
                  color: calculateTextColor('#0d3d6e'),
                  position: 'absolute',
                  background: 'transparent',
                  zIndex: 1,
                }}
              />
              :
              <div className={classes.innerCircle}>
              </div>
          }
        </div> */}

      </div>
      <div className='font-style' style={{
        display: 'flex', alignItems: 'center', marginTop: -16,
        flexDirection: props.currentResume.language === 'ar' ? 'row-reverse' : 'row',
      }}>
        <div
          className={classes.Aa}
          style={{
            color: props.isDarkMode ? '#b6b7b7' : '#666666',
            margin: '0px 24px 0px 0px',
          }}
        ><FontAwesomeIcon
            icon={faTextHeight}
            style={{
              fontSize: 16,
              position: 'relative',
              background: 'transparent',
              zIndex: 1,
            }}
          /></div>
        <Slider
          //defaultValue={props.lineHeight: currentResume.lineSpace,
          //fontSize: currentResume.fontSize+"px"}
          range
          //allowCross={false}
          value={props.currentResume.lineSpace}
          min={1}
          max={2}
          step={0.01}
          onChange={(lineSpace) => handleChangeLineSpace(lineSpace)}
          onAfterChange={(lineSpace) => props.ChangeLineSpaceOnServer(props.currentResume.guid, lineSpace)}
          marks={heightMarks}
          style={{ width: props.innerWidth> 1500 ||  props.innerWidth<= 576 ? "180px" : props.innerWidth> 1240 ? "120px" : "80px"  }}
        />
        </div>
        
      <div className='font-style' style={{
        display: 'flex', alignItems: 'center', marginTop: -16,
        flexDirection: props.currentResume.language === 'ar' ? 'row-reverse' : 'row',
      }}>
        <div
          className={classes.Aa}
          style={{
            color: props.isDarkMode ? '#b6b7b7' : '#666666',
            margin: '0px 10px 0px 20px',
          }}
        ><FormatSizeIcon
            style={{
              fontSize: 24,
              position: 'relative',
              background: 'transparent',
              zIndex: 1,
            }}
          /></div>
          <Slider
          //defaultValue={props.lineHeight: currentResume.lineSpace,
          //fontSize: currentResume.fontSize+"px"}
          //range
          //allowCross={false}
          value={props.currentResume.fontSize}
          min={12}
          max={16}
          step={1}
          onChange={(fontSize) => handleChangeFontSize(fontSize)}
          onAfterChange={(fontSize) => props.ChangeFontSizeOnServer(props.currentResume.guid, fontSize)}
          marks={fontSizeMarks}
          style={{ width: props.innerWidth> 1500 ||  props.innerWidth<= 576 ? "180px" : props.innerWidth> 1240 ? "120px" : "80px" }}
        />
        {/* <Select
          value={props.currentResume.fontSize}
          onChange={(fontSize) => handleChangeFontSize(fontSize)}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          style={{ height: 40, color: props.isDarkMode ? '#b6b7b7' : '#666666'}}
        >
          <MenuItem value={14}>XS</MenuItem>
          <MenuItem value={15}>S</MenuItem>
          <MenuItem value={16}>M</MenuItem>
          <MenuItem value={17}>L</MenuItem>
          <MenuItem value={18}>XL</MenuItem>
        </Select> */}
      </div>
    </div >
  )
}

export default TemplateHeaderContainer;
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { hideModal } from '../actions/rootActions'


class ConfirmMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            StopShowMessage: false
        }
    }
    ok = () => {
        this.props.callbackFunction();
        this.props.hideModal();
    }
    cancel = () => {
        this.props.hideModal();
    }

    toggle = () => {
        if (localStorage.getItem(this.props.id) == 'true') {
            this.setState({ StopShowMessage: false });
            localStorage.setItem(this.props.id, 'false')
        } else {
            this.setState({ StopShowMessage: true });
            localStorage.setItem(this.props.id, 'true');
        }
    }

    render() {
        return (
            <div className='confirm-message-container'>
                <div className="modal-title-container">
                    <div className="modal-title">{this.props.title}</div>
                </div>
                <div>
                    <p className="modal-message">{this.props.message}</p>
                    <div className='modal-footer' style={{ display: 'flex', justifyContent: this.props.showStopShowMessage ? 'space-between' : 'flex-end' }}>
                        {
                            this.props.showStopShowMessage &&
                            <label><input type="checkbox" name="isHideIcons" checked={this.state.StopShowMessage} onChange={this.toggle} /><span className="modal-message">Do not show this message again</span></label>
                        }                        
                        <div className="modal-buttons-container">
                            <button className="ok-button" onClick={this.ok}>{this.props.okButtonTitle}</button>
                            {
                                this.props.cancelButtonTitle &&
                                <button className="cancel-button" onClick={this.cancel}>{this.props.cancelButtonTitle}</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentResume: state.viewModel.currentResume,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        hideModal: () =>
            dispatch(hideModal())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmMessage)
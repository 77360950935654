import React, { Component } from 'react'
import { connect } from 'react-redux'
import { hideModal } from '../actions/rootActions'

class PdfLoading extends Component {
    render() {
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('orientationchange', this.handleResize);
        const { content, isShowModal, isTransparent, isFillAvailable } = this.props.modal;
        const { innerWidth, isDarkMode } = this.props;
        const additionalClassName = isDarkMode ? "_dark_mode" : ""
        return (
            <div style={{height: 'inherit'}}>
                <div className="pdf-loading-space"></div>
                <div className="pdf-loading-square">
                    <div className={"animated-background" + additionalClassName} style={{ height: '30px' }}>
                        <div className="btn-divide-left"></div>
                    </div>
                </div>
                <div className="pdf-loading-space"></div>
                <div className="pdf-loading-short-item">
                    <div className={"animated-background" + additionalClassName}>
                        <div className="background-masker btn-divide-left"></div>
                    </div>
                </div>
                <div className="pdf-loading-short-item">
                    <div className={"animated-background" + additionalClassName}>
                        <div className="background-masker btn-divide-left"></div>
                    </div>
                </div>
                <div className="pdf-loading-space"></div>

                <div className="pdf-loading-title">
                    <div className={"animated-background" + additionalClassName}>
                        <div className="btn-divide-left"></div>
                    </div>
                </div>
                <div className="pdf-loading-long-item">
                    <div className={"animated-background" + additionalClassName}>
                        <div className="background-masker"></div>
                    </div>
                </div>
                <div className="pdf-loading-middle-item">
                    <div className={"animated-background" + additionalClassName}>
                        <div className="background-masker"></div>
                    </div>
                </div>
                <div className="pdf-loading-space">
                </div>
                <div className="pdf-loading-title">
                    <div className={"animated-background" + additionalClassName}>
                        <div className="btn-divide-left"></div>
                    </div>
                </div>
                <div className="pdf-loading-short-item">
                    <div className={"animated-background" + additionalClassName}>
                        <div className="background-masker"></div>
                    </div>
                </div>
                <div className="pdf-loading-middle-item">
                    <div className={"animated-background" + additionalClassName}>
                        <div className="background-masker"></div>
                    </div>
                </div>
                <div className="pdf-loading-long-item">
                    <div className={"animated-background" + additionalClassName}>
                        <div className="background-masker"></div>
                    </div>
                </div>

                <div className="pdf-loading-short-item">
                    <div className={"animated-background" + additionalClassName}>
                        <div className="background-masker"></div>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        modal: state.modal,
        isMobilePreview: state.isMobilePreview,
        innerWidth: state.innerWidth,
        isDarkMode: state.viewModel.auth.isDarkMode
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        hideModal: () =>
            dispatch(hideModal())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PdfLoading)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { showSpinner, getDateObject } from '../actions/rootActions'
import { updateCertificate, updateCertificateOnServer } from '../actions/resume/certificateActions'
import { debounce } from "lodash";
import 'react-dropzone-uploader/dist/styles.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import { Handle } from './ListWrapper/Handle'


class Certificate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.item.id,
            name: props.item.name,
            organization: this.props.item.organization,
            startDate: parseInt(props.item.startDate, 0),
            endDate: parseInt(props.item.endDate, 0),
            sort: props.item.sort,
            isDataLoaded: false
        }
    }
    componentDidUpdate(prevProps) {
        if (!this.state.isDataLoaded) {
            if (this.props.item !== prevProps.item) {
                this.setState({
                    id: this.props.item.id,
                    name: this.props.item.name,
                    organization: this.props.item.organization,
                    startDate: parseInt(this.props.item.startDate, 0),
                    endDate: parseInt(this.props.item.endDate, 0),
                    sort: this.props.item.sort,
                    isDataLoaded: true
                });
            }
        }
    }
    handleChangeStartDate = date => {
        const timestamp =Date.parse(date);
        this.setState({
            startDate: timestamp
        });
        const { id, name, organization, endDate, sort } = this.state;
        this.updateCertificate(id, name, organization, timestamp, endDate, sort);
    };
    handleChangeEndDate = date => {
        const timestamp =Date.parse(date);
        this.setState({
            endDate: timestamp
        });
        const { id, name, organization, startDate, sort } = this.state;
        this.updateCertificate(id, name, organization, startDate, timestamp, sort);
    };
    handleChange = (e) => {
        const { index } = this.props;
        switch (e.target.id) {
            case "certificate_name_" + index:
                this.setState({
                    name: e.target.value
                });
                var { id, organization, startDate, endDate, sort } = this.state;
                this.updateCertificate(id, e.target.value, organization, startDate, endDate, sort);
                break;
            case "certificate_organization_" + index:
                this.setState({
                    organization: e.target.value
                });
                var { id, name, startDate, endDate, sort } = this.state;
                this.updateCertificate(id, name, e.target.value, startDate, endDate, sort);
                break;
        }
    }

    updateCertificate = (id, name, organization, startDate, endDate, sort) => {
        const elementsIndex = this.props.currentResume.certificates.findIndex(certificate => certificate.id === id);
        let newList = [...this.props.currentResume.certificates];
        newList[elementsIndex] = {id, name, organization, startDate, endDate, sort, type: "Certificate"};
        this.props.updateCertificate(newList);
        this.raiseUpdatepersonalDetailsWhenUserStoppedTyping();
    }


    raiseUpdatepersonalDetailsWhenUserStoppedTyping = debounce(() => {
        const { id, name, organization, startDate, endDate, sort } = this.state;
        var dateObject = this.props.getDateObject(startDate, endDate);
        var stringStartDate = (dateObject.startMonthName && dateObject.startYear) ? (dateObject.startMonthName + " " + dateObject.startYear) : "";
        var stringEndDate = (dateObject.endMonthName && dateObject.endYear) ? (dateObject.endMonthName + " " + dateObject.endYear) : this.props.currentResume.translation['present'];

        this.props.updateCertificateOnServer(this.props.currentResume.guid, id, name, organization, startDate, endDate, stringStartDate, stringEndDate, sort);
        if (!this.state.isShowSpinner) {
            this.props.showSpinner(true);
        }
    }, 1500);
    render() {
        const { name, organization, startDate, endDate } = this.state;
        const { currentResume, isDarkMode, index, item, parentIndex, isDisabled, shouldUseDragHandle, bodyTabIndex, parentName } = this.props;
        const monthNames = currentResume.translation['longMonthNames'];
        var startDate0 = new Date(startDate);
        const startMonthName = monthNames[startDate0.getMonth()];
        const startYear = startDate0.getFullYear();
        var endDate0 = new Date(endDate);
        const endMonthName = monthNames[endDate0.getMonth()];
        const endYear = endDate0.getFullYear();
        const itemStyle = {
            textAlign: this.props.currentResume.language === 'ar' ? 'right' : 'unset',
            direction: this.props.currentResume.language === 'ar' ? 'rtl' : 'unset'
        }
        return (
            <div className="item" style={itemStyle}>
                <div
                    className="stylizedItem"
                    tabIndex={bodyTabIndex}
                    data-index={index}
                    onClick={() => this.props.innerWidth > 1019 && this.props.ToggleItemDisplay("inner", index + "" + parentIndex)}
                    style={{cursor: this.props.innerWidth > 1019 ? 'pointer' : 'default'}}
                >
                    {shouldUseDragHandle &&
                        <React.Fragment>
                            <div className="wrapper">
                                <Handle tabIndex={bodyTabIndex} />
                                <div className="middle">
                                    <div className="item-title-edit">{name || currentResume.translation['not_specified']}{(organization) ? ", " + organization : ""}</div>
                                    <div className="item-date-edit">{startMonthName && startYear && (startMonthName + " " + startYear + " - ")} {endDate && (endMonthName + " " + endYear) || currentResume.translation['present']}</div>
                                </div>
                            </div>
                            <div className="flex-no-wrape">
                                <button className="trash-icon delete margin-r10" onClick={() => this.props.showConfirmDeleteMessage(parentName, item.id)}>
                                    <div style={{ display: "inline-block" }}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </div>
                                </button>
                                <button className="toggle margin-r10" onClick={() => this.props.innerWidth <= 1019 && this.props.ToggleItemDisplay("inner", index + "" + parentIndex)}>
                                    <div id={"AngleDown" + index + "" + parentIndex} className="ItemAngleDown" style={{ display: "inline-block" }}>
                                        <FontAwesomeIcon icon={faAngleDown} />
                                    </div>
                                    <div id={"AngleUp" + index + parentIndex} className="ItemAngleUp" style={{ display: "none" }}>
                                        <FontAwesomeIcon icon={faAngleUp} />
                                    </div>
                                </button>
                            </div>
                        </React.Fragment>
                    }
                </div>
                <div className="item-inner" id={"inner" + index + parentIndex} style={{ display: "none" }}>
                    <div className="personal-details-field">
                        <div>
                            <div>
                                <div>
                                    <label className="personal-details-title">
                                        <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['name']}</div>
                                    </label>
                                    <div>
                                        <input type="text" autoComplete="off" value={name} id={"certificate_name_" + index} onChange={this.handleChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="personal-details-field">
                        <div>
                            <div>
                                <div>
                                    <label className="personal-details-title">
                                        <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['organization']}</div>
                                    </label>
                                    <div>
                                        <input type="text" autoComplete="off" value={organization} id={"certificate_organization_" + index} onChange={this.handleChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="personal-details-field">
                        <div>
                            <div className="start-end-date">

                                <div className="date-item startDate">
                                    <label className="personal-details-title">
                                        <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['start_date']}</div>
                                    </label>
                                    <div>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={date => this.handleChangeStartDate(date)}
                                            selectsStart
                                            startDate={startDate}
                                            endDate={endDate}
                                            dateFormat="MM/yyyy"
                                            showMonthYearPicker
                                        />
                                    </div>
                                </div>

                                <div className="date-item endDate">
                                    <label className="personal-details-title">
                                        <div className={(isDarkMode) ? "title_dark_mode" : ""}>{currentResume.translation['end_date']}</div>
                                    </label>
                                    <div>
                                        <DatePicker
                                            selected={endDate}
                                            onChange={date => this.handleChangeEndDate(date)}
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}
                                            dateFormat="MM/yyyy"
                                            showMonthYearPicker
                                            placeholderText={currentResume.translation['present']}
                                            clearButtonTitle={currentResume.translation['present']}
                                            isClearable={endDate ? true : false}
                                            wrapperClassName={"date-picker-" + currentResume.language}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="personal-details-field"><div><div></div></div></div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentResume: state.viewModel.currentResume,
        isDarkMode: state.viewModel.auth.isDarkMode,
        innerWidth: state.innerWidth
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateCertificate: (newList) =>
            dispatch(updateCertificate(newList)),
            updateCertificateOnServer: (guid, id, name, organization, startDate, endDate, stringStartDate, stringEndDate) =>
            dispatch(updateCertificateOnServer(guid, id, name, organization, startDate, endDate, stringStartDate, stringEndDate)),
        showSpinner: (isShowSpinner) =>
            dispatch(showSpinner(isShowSpinner)),
        getDateObject: (startDate, endDate) => getDateObject(startDate, endDate)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Certificate)
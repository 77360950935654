import React, { Component } from 'react'
import { connect } from 'react-redux'
import { HidePicture, onLoadSuccess, HidePictureOnServer } from '../actions/resume/personalDetailsActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserTie, faPen } from '@fortawesome/free-solid-svg-icons'
import 'react-dropzone-uploader/dist/styles.css'
import { hideModal, showModal } from '../actions/rootActions'
import UploadProfilePictureContent from './UploadProfilePictureContent'
import { _HostnamePre, _ShowProfilePictureWarning } from '../Const'
import ConfirmMessage from './ConfirmMessage'

class ProfilePicture extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profilePicture: props.currentResume.personalDetails.croppedProfilePicture ? props.currentResume.personalDetails.croppedProfilePicture.profilePicture : '',
            isDataLoaded: false
        }
    }
    componentDidUpdate(prevProps) {
        if (!this.props.isDataLoaded) {
            if (this.props.currentResume.personalDetails !== prevProps.currentResume.personalDetails) {
                if (this.props.currentResume.personalDetails.croppedProfilePicture) {
                    this.setState({
                        profilePicture: this.props.currentResume.personalDetails.croppedProfilePicture.profilePicture
                    });
                }
            }
        }
    }

    setHidePicture = (e) => {
        this.props.HidePicture(!e.target.checked)
        this.props.HidePictureOnServer(this.props.currentResume.guid, !e.target.checked);
    }

    showModal = () => {
        this.props.showModal(<UploadProfilePictureContent profilePicture={this.state.profilePicture} />, null, false, true);
        //console.log(_HostnamePre + this.state.profilePicture)        
    }

    showWorning = () => {
        const title = "Warning";
        const message = "Adding a picture to your resume is optional. Photos provided in resumes are disliked in the U.S. but more common in Europe.";
        const okButtonTitle = "I understand";
        this.props.showModal(<ConfirmMessage id="WorningProfilePicture" title={title} message={message} okButtonTitle={okButtonTitle}
            callbackFunction={() => {
                this.props.hideModal();
                setTimeout(() => {
                    this.showModal(<UploadProfilePictureContent profilePicture={this.state.profilePicture} />, null, false, true);
                }, 100);
            }
            }
            showStopShowMessage={true} />, null, false, false);
    }

    render() {
        const { profilePicture } = this.state;
        const { isDarkMode, currentResume } = this.props;
        return (
            <React.Fragment>
                <div className="profile-picture-container">
                    <div style={{
                        marginLeft: 30
                    }}>
                        {
                            profilePicture !== "" &&
                            <div style={{ justifyContent: 'center', minWidth: 'fit-content', padding: '10px', borderRadius: '10px', cursor: 'pointer', display: 'flex' }}>
                                <div className="icons-visibility" style={{ minWidth: 123 }}>
                                    {

                                        <label>
                                            <input type="checkbox" name="isHideIcons" checked={!this.props.currentResume.hidePicture} onChange={this.setHidePicture} /><span className='hide-picture'>
                                                {
                                                    this.props.currentResume.translation['show_picture']
                                                }
                                            </span>
                                        </label>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    {profilePicture ?
                        <div className="profile-picture-wrapper" style={{
                            marginLeft: currentResume.language === 'ar' ? 'unset' : profilePicture !== "" ? '-25px' : '0px',
                            marginRight: currentResume.language === 'ar' ? profilePicture !== "" ? '-25px' : '0px' : 'unset',
                        }}>
                            <div
                                className="profilePicture-edit"
                                style={{
                                    left: currentResume.language === 'ar' ? 'unset' : '99px',
                                    right: currentResume.language === 'ar' ? '99px' : 'unset',
                                }}
                                onClick={this.showModal}>
                                <FontAwesomeIcon icon={faPen} />
                            </div>
                            <div className={(isDarkMode) ? "profilePicture profilePicture_dark_mode" : "profilePicture"} >
                                <img id="profilePicture" alt='profilePicture' src={profilePicture.includes('data:image/') ? profilePicture : _HostnamePre + profilePicture} onLoad={() => this.props.onLoadSuccess(false)} onClick={this.showModal} />
                            </div>
                        </div>
                        :
                        <div className="profile-picture-wrapper" style={{
                            marginLeft: currentResume.language === 'ar' ? 'unset' : profilePicture !== "" ? '-25px' : '0px',
                            marginRight: currentResume.language === 'ar' ? profilePicture !== "" ? '-25px' : '0px' : 'unset',
                        }}>
                            <div
                                className="profilePicture-edit"
                                style={{
                                    left: currentResume.language === 'ar' ? 'unset' : '99px',
                                    right: currentResume.language === 'ar' ? '99px' : 'unset',
                                }}
                                onClick={this.showModal}>
                                <FontAwesomeIcon icon={faPen} />
                            </div>
                            <div className="profilePicture" onClick={() => { localStorage.getItem('WorningProfilePicture') !== 'true' && _ShowProfilePictureWarning ? this.showWorning() : this.showModal() }}>
                                <FontAwesomeIcon icon={faUserTie} style={{ fontSize: "36pt" }} />
                            </div>
                        </div>
                    }
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentResume: state.viewModel.currentResume,
        isDataLoaded: state.isDataLoaded,
        isDarkMode: state.viewModel.auth.isDarkMode,
        isShowLoadingCropPicture: state.isShowLoadingCropPicture
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        onLoadSuccess: (value) =>
            dispatch(onLoadSuccess(value)),
        showModal: (content, onHideModal, isTransparent, isFillAvailable) =>
            dispatch(showModal(content, onHideModal, isTransparent, isFillAvailable)),
        hideModal: (content, onHideModal, isTransparent, isFillAvailable) =>
            dispatch(hideModal(content, onHideModal, isTransparent, isFillAvailable)),
        HidePicture: (value) => dispatch(HidePicture(value)),
        HidePictureOnServer: (guid, value) => dispatch(HidePictureOnServer(guid, value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePicture)
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dropzone from 'react-dropzone-uploader'
import { GetResume } from '../actions/resume/resumeListActions'
import { onLoadSuccess } from '../actions/resume/personalDetailsActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserTie } from '@fortawesome/free-solid-svg-icons'

class ProfilePictureDragZone extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    handleChangeStatus = ({ meta, remove }, status, file) => {
        this.props.setTempImageSrc(file[0].file);
        if (status === 'headers_received') {
            remove();
            this.props.onLoadSuccess(true);
            this.props.GetResume(this.props.currentResume.guid);
        }
    }

    render() {
        const { currentResume } = this.props;
        return (

            <React.Fragment>
                <div>
                    <div className="modal-title-container">
                        <div className="modal-title">{currentResume.translation['upload_your_picture']}</div>
                    </div>
                    <div id="toast">Upload</div>
                    <Dropzone
                        //getUploadParams={this.getUploadParams}
                        onChangeStatus={this.handleChangeStatus}
                        maxFiles={1}
                        multiple={false}
                        canCancel={false}
                        inputContent={
                            <div className="upload-area">
                                <div className="upload-area-icon"><FontAwesomeIcon icon={faUserTie} style={{ fontSize: "36pt" }} /></div>
                                <div className="upload-area-text">{currentResume.translation['drop_or_select_your_picture_from_your_computer']}<br />{currentResume.translation['accepted_formats']}</div>
                            </div>
                        }
                        styles={{
                            dropzone: { width: 400 },
                            dropzoneActive: { borderColor: 'green' },
                        }}
                    />
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentResume: state.viewModel.currentResume
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        GetResume: (guid) =>
        dispatch(GetResume(guid)),
        onLoadSuccess: (guid) =>
        dispatch(onLoadSuccess(guid))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePictureDragZone)